import { useCallback, useEffect, useState } from 'react';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import Card, { CardProps } from '@mui/material/Card';
import ListItemText from '@mui/material/ListItemText';
import TableContainer from '@mui/material/TableContainer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
// utils
import isEqual from 'lodash/isEqual';
// components
import { DateTime } from 'luxon';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/components/table';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ClazzDto, Direction, EmployeeDto } from '../../api';
import { fDateTime } from '../../utils/format-time';
import { useLocales } from '../../locales';
import { useSettingsContext } from '../../components/settings';
import { useRouter } from '../../routes/hook';
import { useBoolean } from '../../hooks/use-boolean';
import { useDispatch, useSelector } from '../../redux/store';
import { paths } from '../../routes/paths';
import ClazzRegistrationTableToolbar from './booking/clazz-registration-table-toolbar';
import ClazzRegistrationTableFiltersResult from './booking/clazz-registration-table-filters-result';
import { getClasses } from '../../redux/slices/classes';
import {
  IClazzRegistrationTableFilters,
  IClazzRegistrationTableFilterValue,
} from '../../types/employee';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  member: EmployeeDto;
}

const defaultFilters: IClazzRegistrationTableFilters = {
  status: 'all',
  name: '',
  fromDate: DateTime.now().startOf('month'),
  toDate: DateTime.now().endOf('month'),
};

export default function ClazzDetails({ title, subheader, member, ...other }: Props) {
  const theme = useTheme();

  const { t } = useLocales();

  const TABLE_HEAD = [
    { id: 'title', label: t('Class') },
    { id: 'begins', label: t('Begins') },
    { id: 'ends', label: t('Ends') },
    { id: '' },
  ];

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onResetPage,
    //
    selected,
    onSelectAllRows,
    onSelectRow,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: TABLE_HEAD[0].id,
    defaultOrder: 'desc',
  });

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  const dispatch = useDispatch();

  const tableData = useSelector((state) => state.clazz.classes);

  const totalNumberOfSignUps = useSelector((state) => state.clazz.totalNumberOfClasses);

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    dispatch(
      getClasses({
        pageSize: rowsPerPage,
        pageNumber: page,
        sortBy: [orderBy],
        direction: order === 'asc' ? Direction.Asc : Direction.Desc,
        employeeId: member.id!,
        from: filters.fromDate!,
        to: filters.toDate!,
      })
    );
  }, [dispatch, rowsPerPage, page, orderBy, order, member, filters]);

  const STATUS_OPTIONS = [
    { value: 'all', label: t('All'), color: 'info', count: totalNumberOfSignUps },
  ] as const;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = !tableData.length && !!filters.status;

  const handleFilters = useCallback(
    (name: string, value: IClazzRegistrationTableFilterValue) => {
      onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [onResetPage]
  );

  const handleDeleteRow = useCallback(
    (id: number) => {
      const deleteRow = tableData.filter((row) => row.id !== id);
    },
    [tableData]
  );

  const handleViewRow = useCallback(
    (id: number) => {
      router.push(paths.classTemplates.view(id));
    },
    [router]
  );

  const handleEditRow = useCallback(
    (id: number) => {
      router.push(paths.classTemplates.edit(id));
    },
    [router]
  );

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const denseHeight = dense ? 52 : 72;

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} sx={{ mb: 3 }} />

      <Tabs
        value={filters.status}
        onChange={handleFilterStatus}
        sx={{
          px: 2.5,
          boxShadow: (bTheme) => `inset 0 -2px 0 0 ${alpha(bTheme.palette.grey[500], 0.08)}`,
        }}
      >
        {STATUS_OPTIONS.map((tab) => (
          <Tab
            key={tab.value}
            iconPosition="end"
            value={tab.value}
            label={tab.label}
            icon={
              <Label
                variant={
                  ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                }
                color={tab.color}
              >
                {tab.count}
              </Label>
            }
          />
        ))}
      </Tabs>

      <ClazzRegistrationTableToolbar filters={filters} onFilters={handleFilters} />

      {canReset && (
        <ClazzRegistrationTableFiltersResult
          filters={filters}
          onFilters={handleFilters}
          //
          canReset={canReset}
          onResetFilters={handleResetFilters}
          //
          results={totalNumberOfSignUps}
          sx={{ p: 2.5, pt: 0 }}
        />
      )}

      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <TableSelectedAction
          dense={dense}
          numSelected={selected.length}
          rowCount={tableData.length}
          onSelectAllRows={(checked) => {}}
          action={
            <Tooltip title={t('Delete')}>
              <IconButton color="primary" onClick={confirm.onTrue}>
                <Iconify icon="solar:trash-bin-trash-bold" />
              </IconButton>
            </Tooltip>
          }
        />

        <Scrollbar>
          <Table size={dense ? 'small' : 'medium'}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData.length}
              numSelected={selected.length}
              onSort={onSort}
            />

            <TableBody>
              {tableData?.map((row) => <BookingDetailsRow key={row.id} row={row} />)}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(page, rowsPerPage, totalNumberOfSignUps)}
              />

              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={totalNumberOfSignUps}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        //
        dense={dense}
        onChangeDense={onChangeDense}
      />
    </Card>
  );
}

// ----------------------------------------------------------------------

type BookingDetailsRowProps = {
  row: ClazzDto;
};

function BookingDetailsRow({ row }: BookingDetailsRowProps) {
  const theme = useTheme();

  const router = useRouter();

  const { t } = useLocales();

  const isLight = theme.palette.mode === 'light';

  const popover = usePopover();

  const handleDownload = () => {
    popover.onClose();
    router.push(paths.classes.view(row.id!));
  };

  return (
    <>
      <TableRow>
        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <ListItemText
            primary={row.title}
            secondary={row.subtitle}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>

        <TableCell>{fDateTime(row.begins)}</TableCell>

        <TableCell>{fDateTime(row.ends)}</TableCell>

        <TableCell align="right" sx={{ pr: 1 }}>
          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem onClick={handleDownload}>
          <Iconify icon="solar:eye-bold" />
          {t('View')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
