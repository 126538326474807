import React from 'react';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Stack, { StackProps } from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
// components
import Iconify from 'src/components/iconify';
import { RouterLink } from 'src/routes/components';
import { useLocales } from '../../locales';
import { paths } from '../../routes/paths';
import { EventDto } from '../../api';
import EventParticipantQuickForm from './event-participant-quick-form';
import { getParticipants } from '../../redux/slices/event';
import { useBoolean } from '../../hooks/use-boolean';
import { useDispatch } from '../../redux/store';

// ----------------------------------------------------------------------

type Props = StackProps & {
  backLink: string;
  editLink: string;
  liveLink: string;
  publishOptions: {
    value: string;
    label: string;
  }[];
  event: EventDto;
  onExport: VoidFunction;
};

export default function EventDetailsToolbar({
                                              backLink,
                                              editLink,
                                              liveLink,
                                              publishOptions,
                                              sx,
                                              event,
                                              onExport,
                                              ...other
                                            }: Props) {
  const theme = useTheme();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const openForm = useBoolean();

  return (
    <>
      <Stack
        spacing={1.5}
        direction="row"
        sx={{
          mb: { xs: 3, md: 5 },
          ...sx,
        }}
        {...other}
      >
        <Button
          component={RouterLink}
          href={backLink}
          startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
        >
          {t('Back')}
        </Button>

        <Box sx={{ flexGrow: 1 }} />
        <Tooltip title={t('Export tickets')}>
          <IconButton onClick={onExport}>
            <Iconify icon="solar:export-bold" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('Add participant')}>
          <IconButton onClick={openForm.onTrue}>
            <Iconify icon="mdi:user-add" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('Send newsletter')}>
          <IconButton
            component={RouterLink}
            href={`${paths.newsletter.new}?eventId=${event.id}&eventName=${event.title}`}
          >
            <Iconify icon="mdi:email-newsletter" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('Edit')}>
          <IconButton component={RouterLink} href={editLink}>
            <Iconify icon="solar:pen-bold" />
          </IconButton>
        </Tooltip>
      </Stack>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openForm.value}
        onClose={openForm.onFalse}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: theme.transitions.duration.shortest - 80,
        }}
      >
        <DialogTitle sx={{ minHeight: 76 }}>{t('Add participant')}</DialogTitle>

        <EventParticipantQuickForm
          currentEvent={event}
          onClose={() => {
            dispatch(
              getParticipants({
                id: event.id!,
              }),
            );
            openForm.onFalse();
          }}
        />
      </Dialog>
    </>
  );
}
