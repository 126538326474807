import { useCallback, useEffect, useState } from 'react';
// @mui
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import AvatarGroup, { avatarGroupClasses } from '@mui/material/AvatarGroup';
// routes
import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hook';
import { RouterLink } from 'src/routes/components';
// utils
import { fShortenNumber } from 'src/utils/format-number';
// _mock
import { POST_PUBLISH_OPTIONS } from 'src/_mock';
// api
// components
import Iconify from 'src/components/iconify';
import Markdown from 'src/components/markdown';
import EmptyContent from 'src/components/empty-content';
//
import ArticleDetailsHero from '../article-details-hero';
import ArticleCommentList from '../article-comment-list';
import ArticleCommentForm from '../article-comment-form';
import { PostDetailsSkeleton } from '../article-skeleton';
import ArticleDetailsToolbar from '../article-details-toolbar';
import { useDispatch, useSelector } from '../../../redux/store';
import { getSingleNews } from '../../../redux/slices/news';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function NotificationDetailsView() {
  const params = useParams();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const { id } = params;

  const [publish, setPublish] = useState('');

  const article = useSelector((state) => state.news.currentArticle);

  const isLoading = useSelector((state) => state.news.isLoading);

  const errorMessage = useSelector((state) => state.news.error);

  useEffect(() => {
    dispatch(getSingleNews({ id: parseInt(id!, 10) }));
  }, [dispatch, id]);

  const handleChangePublish = useCallback((newValue: string) => {
    setPublish(newValue);
  }, []);

  useEffect(() => {
    if (article) {
      // setPublish(article?.publish);
    }
  }, [article]);

  const renderSkeleton = <PostDetailsSkeleton />;

  const renderError = (
    <EmptyContent
      filled
      title={`${errorMessage}`}
      action={
        <Button
          component={RouterLink}
          href={paths.dashboard.post.root}
          startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
          sx={{ mt: 3 }}
        >
          {t('Back to List')}
        </Button>
      }
      sx={{
        py: 20,
      }}
    />
  );

  const renderPost = article && (
    <>
      <ArticleDetailsToolbar
        backLink={paths.news.root}
        editLink={paths.news.edit(article.id!)}
        liveLink={paths.news.view(article.id!)}
        publish={publish || ''}
        onChangePublish={handleChangePublish}
        publishOptions={POST_PUBLISH_OPTIONS}
      />

      <ArticleDetailsHero title={article.title ?? ''} coverUrl={article.imageUrl ?? ''} />

      <Stack
        sx={{
          maxWidth: 720,
          mx: 'auto',
          mt: { xs: 5, md: 10 },
        }}
      >
        <Typography variant="subtitle1" sx={{ mb: 5 }}>
          {article.teaser}
        </Typography>

        <Markdown children={article.body ?? ''} />

        <Stack
          spacing={3}
          sx={{
            py: 3,
            borderTop: (theme) => `dashed 1px ${theme.palette.divider}`,
            borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          <Stack direction="row" flexWrap="wrap" spacing={1}>
            {article.tags?.map((tag) => <Chip key={tag} label={tag} variant="soft" />)}
          </Stack>

          <Stack direction="row" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  size="small"
                  color="error"
                  icon={<Iconify icon="solar:heart-bold" />}
                  checkedIcon={<Iconify icon="solar:heart-bold" />}
                />
              }
              label={fShortenNumber(article.totalFavorites ?? 0)}
              sx={{ mr: 1 }}
            />

            <AvatarGroup
              sx={{
                [`& .${avatarGroupClasses.avatar}`]: {
                  width: 32,
                  height: 32,
                },
              }}
            >
              {article.favoritePersons?.map((person) => (
                <Avatar key={person.id} alt={person.fullName} src={person.profileImageUrl} />
              ))}
            </AvatarGroup>
          </Stack>
        </Stack>

        <Stack direction="row" sx={{ mb: 3, mt: 5 }}>
          <Typography variant="h4">Comments</Typography>

          <Typography variant="subtitle2" sx={{ color: 'text.disabled' }}>
            ({article.comments?.length})
          </Typography>
        </Stack>

        <ArticleCommentForm />

        <Divider sx={{ mt: 5, mb: 2 }} />

        <ArticleCommentList comments={[]} />
      </Stack>
    </>
  );

  return (
    <Container maxWidth={false}>
      {isLoading && renderSkeleton}

      {errorMessage && renderError}

      {article && renderPost}
    </Container>
  );
}
