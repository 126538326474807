import { useEffect } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
// routes
import { paths } from 'src/routes/paths';
// components
import { useParams } from 'src/routes/hook';
import { useSettingsContext } from 'src/components/settings';
//
import OrderDetailsInfo from '../order-details-info';
import OrderDetailsItems from '../order-details-item';
import OrderDetailsToolbar from '../order-details-toolbar';
import OrderDetailsHistory from '../order-details-history';
import { useDispatch, useSelector } from '../../../redux/store';
import { getOrder } from '../../../redux/slices/order';
import { getInvoiceTransactions } from '../../../redux/slices/invoice';

// ----------------------------------------------------------------------

export default function OrderDetailsView() {
  const settings = useSettingsContext();

  const dispatch = useDispatch();

  const params = useParams();

  const { id = '' } = params;

  const currentOrder = useSelector((state) => state.order.currentOrder);

  useEffect(() => {
    dispatch(getOrder(parseInt(id, 10)));
  }, [dispatch, id]);

  useEffect(() => {
    if (currentOrder.invoiceId) {
      dispatch(getInvoiceTransactions(currentOrder.invoiceId!));
    }
  }, [dispatch, currentOrder]);

  const subTotal =
    currentOrder.items?.map((item) => item.priceAmount ?? 0).reduce((a, b) => a + b, 0) ?? 0;

  const taxes = currentOrder.totalVat ?? 0;

  const discount = 
    currentOrder.items?.map((item) => item.discount ?? 0).reduce((a, b) => a + b, 0) ?? 0;

  const refunds = currentOrder.totalRefund ?? 0;

  const total = currentOrder.totalAmount ?? 0;

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'} id="printarea">
      <OrderDetailsToolbar
        backLink={paths.order.root}
        orderNumber={`#${currentOrder.id}`}
        createdAt={currentOrder.createdDate!}
        status={currentOrder.status!}
        order={currentOrder}
      />

      <Grid container spacing={3}>
        <Grid xs={12} md={8}>
          <Stack spacing={3} direction={{ xs: 'column-reverse', md: 'column' }}>
            <OrderDetailsItems
              items={currentOrder.items ?? []}
              taxes={taxes}
              discount={discount}
              subTotal={subTotal}
              totalAmount={total}
              refunds={refunds}
            />

            <OrderDetailsHistory order={currentOrder} history={currentOrder.events ?? []} />
          </Stack>
        </Grid>

        <Grid xs={12} md={4}>
          <OrderDetailsInfo
            customer={{
              id: currentOrder.customerId!,
              name: currentOrder.customerName!,
              avatarUrl: currentOrder.customerProfileImageUrl!,
              email: currentOrder.customerEmailAddress!,
              ipAddress: '',
              phoneNumber: currentOrder.customerPhoneNumber!,
            }}
            payment={currentOrder.paymentMethod}
            shippingAddress={currentOrder.shippingAddress ?? {}}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
