import React, { useCallback, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
// @mui
import Container from '@mui/material/Container';
import TableContainer from '@mui/material/TableContainer';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
//
import { DateTime } from 'luxon';
// components
import { useDispatch, useSelector } from '../../../redux/store';
//
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../../components/settings';
import MemberChangeReportToolbar from './member-change-report-toolbar';
import { getConfigs, serviceOptions } from '../../../api';

import { IReportFilterValue, IReportTableFilters } from '../../../types/report';
import { IMembershipTableFilterValue } from '../../../types/membership';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  useTable,
} from '../../../components/table';
import { useLocales } from '../../../locales';
import { paths } from '../../../routes/paths';
import Scrollbar from '../../../components/scrollbar';
import { getMemberChangeReport } from '../../../redux/slices/report';
import MemberChangeTableRow from './member-change-table-row';
import { useRouter } from '../../../routes/hook';
import MemberChangeLostTableRow from './member-change-lost-table-row';
import { convertToCSV } from '../../../utils/array-utils';

// ----------------------------------------------------------------------

const defaultFilters: IReportTableFilters = {
  fromDate: DateTime.now().startOf('month'),
  toDate: DateTime.now().endOf('month'),
};

// ----------------------------------------------------------------------

export default function MemberChangeReportView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const router = useRouter();

  const tableHeadNewMembers = [
    { id: 'ownerName', label: t('Member'), align: 'left' },
    { id: 'name', label: t('Membership'), align: 'left' },
    { id: 'ownerEmail', label: t('Email'), align: 'left' },
    { id: 'ownerPhoneNumber', label: t('Phone number'), align: 'left' },
    { id: 'validFrom', label: t('Valid from'), align: 'left' },
    { id: '' },
  ];

  const tableHeadLostMembers = [
    { id: 'ownerName', label: t('Member'), align: 'left' },
    { id: 'name', label: t('Membership'), align: 'left' },
    { id: 'ownerEmail', label: t('Email'), align: 'left' },
    { id: 'ownerPhoneNumber', label: t('Phone number'), align: 'left' },
    { id: 'wasMemberForInDays', label: t('Member for'), align: 'left' },
    { id: '' },
  ];

  const tableNewMembers = useTable({
    defaultOrderBy: tableHeadNewMembers[0].id,
  });

  const tableLostMembers = useTable({
    defaultOrderBy: tableHeadLostMembers[0].id,
  });

  const report = useSelector((state) => state.report.memberChangeReport);
  const newTableData = report.newMembers ?? [];
  const newNotFound = !newTableData.length;
  const lostTableData = report.lostMembers ?? [];
  const lostNotFound = !lostTableData.length;

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    dispatch(getMemberChangeReport(filters));
  }, [dispatch, filters]);

  const handleFilters = useCallback(
    (name: string, value: IReportFilterValue) => {
      tableNewMembers.onResetPage();
      tableLostMembers.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [tableNewMembers, tableLostMembers]
  );

  const handleViewRow = useCallback(
    (id: number) => {
      router.push(paths.members.view(id));
    },
    [router]
  );

  const exportCsv = useCallback(() => {
    const lostMembers = convertToCSV(report.lostMembers ?? []);

    const lostBlob = new Blob([lostMembers as any], {
      type: 'text/csv',
    });
    saveAs(lostBlob, `lost-members.csv`);

    const newMembers = convertToCSV(report.newMembers ?? []);

    // Ignore type, because API can return multiple types
    const newBlob = new Blob([newMembers as any], {
      type: 'text/csv',
    });
    saveAs(newBlob, `new-members.csv`);
  }, [report]);

  const handlePrintPage: VoidFunction = () => window.print();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={`${t('Member change report')}`}
        links={[
          {
            name: `${t('Dashboard')}`,
            href: paths.dashboard.root,
          },
          {
            name: `${t('Reports')}`,
            href: paths.report.root,
          },
          { name: `${t('Member change report')}` },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Stack spacing={3} id="printarea">
        <Card>
          <MemberChangeReportToolbar
            filters={filters}
            onFilters={handleFilters}
            onPrint={handlePrintPage}
            onExport={exportCsv}
          />
        </Card>
        <Card>
          <CardHeader title={t('Overview')} />
          <CardContent>
            <table>
              <tbody>
                <tr>
                  <th align="left">{t('Aktive medlemmer ved start')}</th>
                  <td align="right">{report.numberOfActiveMembersAtStart}</td>
                </tr>
                <tr>
                  <th align="left">{t('Antal nystartede medlemmer')}</th>
                  <td align="right">{report.numberOfNewMembers}</td>
                </tr>
                <tr>
                  <th align="left">{t('Antal stoppede medlemmer')}</th>
                  <td align="right">{report.numberOfLostMembers}</td>
                </tr>
                <tr>
                  <th align="left">{t('Aktive medlemmer ved slutning')}</th>
                  <td align="right" width={100}>
                    {report.numberOfActiveMembersAtEnd}
                  </td>
                </tr>
              </tbody>
            </table>
          </CardContent>
        </Card>

        <Card>
          <CardHeader title={t('New members')} />
          <TableContainer
            sx={{
              position: 'relative',
              overflow: 'unset',
              pt: 2.5,
            }}
          >
            <Scrollbar>
              <Table size={tableNewMembers.dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={tableNewMembers.order}
                  orderBy={tableNewMembers.orderBy}
                  headLabel={tableHeadNewMembers}
                  rowCount={newTableData.length}
                  numSelected={tableNewMembers.selected.length}
                  onSort={tableNewMembers.onSort}
                />

                <TableBody>
                  {newTableData?.map((row) => (
                    <MemberChangeTableRow
                      key={row.id}
                      row={row}
                      onViewRow={() => handleViewRow(row.ownerId!)}
                      onSelectRow={() => handleViewRow(row.ownerId!)}
                    />
                  ))}

                  <TableEmptyRows
                    emptyRows={emptyRows(
                      tableNewMembers.page,
                      tableNewMembers.rowsPerPage,
                      newTableData.length
                    )}
                  />

                  <TableNoData notFound={newNotFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={newTableData.length}
            page={tableNewMembers.page}
            rowsPerPage={25}
            onPageChange={tableNewMembers.onChangePage}
            onRowsPerPageChange={tableNewMembers.onChangeRowsPerPage}
          />
        </Card>
        <Card>
          <CardHeader title={t('Lost members')} />
          <TableContainer
            sx={{
              position: 'relative',
              overflow: 'unset',
              pt: 2.5,
            }}
          >
            <Scrollbar>
              <Table size={tableLostMembers.dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={tableLostMembers.order}
                  orderBy={tableLostMembers.orderBy}
                  headLabel={tableHeadLostMembers}
                  rowCount={lostTableData.length}
                  numSelected={tableLostMembers.selected.length}
                  onSort={tableLostMembers.onSort}
                />

                <TableBody>
                  {lostTableData?.map((row) => (
                    <MemberChangeLostTableRow
                      key={row.id}
                      row={row}
                      onViewRow={() => handleViewRow(row.ownerId!)}
                      onSelectRow={() => handleViewRow(row.ownerId!)}
                    />
                  ))}

                  <TableEmptyRows
                    emptyRows={emptyRows(
                      tableLostMembers.page,
                      tableLostMembers.rowsPerPage,
                      lostTableData.length
                    )}
                  />

                  <TableNoData notFound={lostNotFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={lostTableData.length}
            page={tableLostMembers.page}
            rowsPerPage={lostTableData.length}
            onPageChange={tableLostMembers.onChangePage}
            onRowsPerPageChange={tableLostMembers.onChangeRowsPerPage}
          />
        </Card>
      </Stack>
    </Container>
  );
}
