import React, { useCallback, useState } from 'react';
import { DateTime } from 'luxon';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { useLocales } from '../../../../locales';
import { useSettingsContext } from '../../../../components/settings';
import { paths } from '../../../../routes/paths';
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
import CustomPopover, { usePopover } from '../../../../components/custom-popover';
import Iconify from '../../../../components/iconify';
import ActiveMemberView from '../active-member-view';
import MemberLifetimeView from '../member-lifetime-view';
import LengthOfEngagementView from '../length-of-engagement-view';
import MembershipDistributionView from '../membership-distribution';
import MemberAgeDistributionView from '../member-age-distribution';
import MemberGenderDistributionView from '../member-gender-distribution';
import ContingentDistributionView from '../contingent-distribution';
import CampaignDistributionView from '../campaign-distribution';
import PunchCardDistributionView from '../punch-card-distribution';
import PersonalTrainingDistributionView from '../personal-training-distribution';
import DropInDistributionView from '../drop-in-distribution';
import { IInsightTableFilters } from '../../../../types/insight';
import ActiveMembershipView from '../active-membership-view';
import MemberChurnReportView from '../member-churn-report-view';

const defaultFilters: IInsightTableFilters = {
  fromDate: DateTime.now().startOf('year'),
  toDate: DateTime.now().endOf('year'),
};

export default function MembershipInsightsView() {
  const { t } = useLocales();

  const settings = useSettingsContext();

  const popover = usePopover();

  const [filters, setFilters] = useState(defaultFilters);

  const [startYear] = useState(DateTime.now().year - 5);
  const [selectableYears] = useState([1, 2, 3, 4, 5, 6].map((i) => startYear + i));

  const handleYearChange = useCallback(
    (newValue: number) => {
      popover.onClose();
      const now = DateTime.now().set({
        year: newValue,
      });
      setFilters({
        fromDate: now.startOf('year'),
        toDate: now.endOf('year'),
      });
    },
    [popover],
  );

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading={t('Insights')}
          links={[
            { name: t('Dashboard'), href: paths.dashboard.root },
            {
              name: t('Insights'),
            },
            { name: t('Memberships') },
          ]}
          action={
            <ButtonBase
              onClick={popover.onOpen}
              sx={{
                pl: 1,
                py: 0.5,
                pr: 0.5,
                borderRadius: 1,
                typography: 'subtitle2',
                bgcolor: 'background.neutral',
              }}
            >
              {filters.fromDate?.year}

              <Iconify
                width={16}
                icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                sx={{ ml: 0.5 }}
              />
            </ButtonBase>
          }
          sx={{ mb: 3 }}
        />
        <Typography variant="body2" sx={{ mb: 3 }}>
          {t("Here you can get insights about memberships. You can see stats about active members, active memberships, member lifetime, length of engagement, member churn report, member age distribution, and more.")}
        </Typography>
        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid xs={12} md={12} lg={6}>
            <ActiveMemberView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
          <Grid xs={12} md={12} lg={6}>
            <ActiveMembershipView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
          <Grid xs={12} md={12} lg={6} xl={4}>
            <MemberLifetimeView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
          <Grid xs={12} md={12} lg={6} xl={4}>
            <LengthOfEngagementView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
          <Grid xs={12} md={12} lg={6} xl={4}>
            <MemberChurnReportView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
          <Grid xs={12} md={12} lg={6} xl={4}>
            <MemberAgeDistributionView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
          <Grid xs={12} md={12} lg={6} xl={4}>
            <MemberGenderDistributionView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
          <Grid xs={12} md={12} lg={6} xl={4}>
            <MembershipDistributionView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={12} md={12}>
            <ContingentDistributionView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
          <Grid xs={12} md={12}>
            <CampaignDistributionView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
          <Grid xs={12} md={12}>
            <PunchCardDistributionView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
          <Grid xs={12} md={12}>
            <PersonalTrainingDistributionView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
          <Grid xs={12} md={12}>
            <DropInDistributionView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
        </Grid>
      </Container>
      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 140 }}>
        {selectableYears.map((year) => (
          <MenuItem
            key={year}
            selected={year === filters.fromDate?.year}
            onClick={() => handleYearChange(year)}
          >
            {year}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}