import { useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { setError } from 'src/redux/slices/error';

// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
// components
import { enqueueSnackbar } from 'notistack';
import FormProvider from 'src/components/hook-form';
import { useLocales } from '../../locales';
import { useDispatch, useSelector } from '../../redux/store';
import { IFileManager } from '../../types/file';
import { DayOfWeek, ProgramTemplatesService } from '../../api';
import { getClassDescriptions } from '../../redux/slices/class-description';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  file: IFileManager;
};

export default function TemplatePlanningForm({ open, onClose, file }: Props) {
  const { t } = useLocales();

  const settings = useSelector((state) => state.chain.currentSettings);

  const clazzDescriptions = useSelector((state) => state.classDescription.descriptions);

  const weekDays = [
    {
      value: DayOfWeek.Monday,
      label: t(DayOfWeek.Monday),
    },
    {
      value: DayOfWeek.Tuesday,
      label: t(DayOfWeek.Tuesday),
    },
    {
      value: DayOfWeek.Wednesday,
      label: t(DayOfWeek.Wednesday),
    },
    {
      value: DayOfWeek.Thursday,
      label: t(DayOfWeek.Thursday),
    },
    {
      value: DayOfWeek.Friday,
      label: t(DayOfWeek.Friday),
    },
    {
      value: DayOfWeek.Saturday,
      label: t(DayOfWeek.Saturday),
    },
    {
      value: DayOfWeek.Sunday,
      label: t(DayOfWeek.Sunday),
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getClassDescriptions({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
      })
    );
  }, [dispatch]);

  const newSchema = Yup.object().shape({
    classDescriptionIds: Yup.array().min(1, t('Select at least one class')),
    daysOfWeek: Yup.array().min(1, t('Select at least one day of the week')),
    fromDate: Yup.lazy((value) =>
      value === ''
        ? Yup.string().required(t('Start date is required'))
        : Yup.date().required(t('Start date is required'))
    ),
  });

  const defaultValues = useMemo(
    () =>
      ({
        fromDate: null,
        classDescriptionIds: [] as number[],
        daysOfWeek: [] as DayOfWeek[],
      }) as any,
    []
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting },
    control,
    setValue,
    reset,
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      await ProgramTemplatesService.createProgramFromTemplate({
        programId: parseInt(file.id, 10),
        body: {
          ...data,
          programTemplateId: parseInt(file.id, 10),
        } as any,
      });
      enqueueSnackbar(t('Program is now scheduled!'));
      reset();
      onClose();
    } catch (error) {
      dispatch(setError(error));
    }
  });

  const handleDescriptionChange = (event: SelectChangeEvent<any[]>) => {
    const result =
      typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
    setValue('classDescriptionIds', result, { shouldValidate: true });
  };

  const handleWeekDayChange = (event: SelectChangeEvent<any[]>) => {
    const result =
      typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
    setValue('daysOfWeek', result, { shouldValidate: true });
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{t('Schedule program')}</DialogTitle>
        <DialogContent dividers>
          <Typography
            variant="body2"
            sx={{
              mb: 3,
            }}
          >
            {t(
              'Here you select on which date and classes that the program should be scheduled on.'
            )}
          </Typography>

          <Stack spacing={3}>
            <Stack spacing={1.5}>
              <Controller
                name="fromDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    label={t('Start date')}
                    value={field.value}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Days of week')}</Typography>
              <Controller
                name="daysOfWeek"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <Select
                      multiple
                      value={values.daysOfWeek}
                      renderValue={(selected) => selected.map((value) => t(value)).join(', ')}
                      onChange={handleWeekDayChange}
                      autoWidth={false}
                    >
                      {weekDays.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <Checkbox
                            disableRipple
                            size="small"
                            checked={values.daysOfWeek!.includes(option.value)}
                          />
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />

              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Classes')}</Typography>
                <Controller
                  name="classDescriptionIds"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth error={!!error}>
                      <Select
                        multiple
                        value={values.classDescriptionIds}
                        onChange={handleDescriptionChange}
                        renderValue={(selected) =>
                          clazzDescriptions
                            .filter((x) => selected.includes(x.id))
                            .map((x) => x.name)
                            .join(', ')
                        }
                        autoWidth={false}
                      >
                        {clazzDescriptions.map((option) => (
                          <MenuItem key={`desc-${option.id}`} value={option.id}>
                            <Checkbox
                              disableRipple
                              size="small"
                              checked={values.classDescriptionIds!.includes(option.id)}
                            />
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button color="inherit" variant="outlined" onClick={onClose}>
            {t('Cancel')}
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {t('Plan')}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
