import { Helmet } from 'react-helmet-async';
import MembershipInsightsView from '../../../sections/insights/membership/view/view';
import { useLocales } from '../../../locales';

export default function MembershipInsightsPage() {

  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title> {t('Insights: Membership')}</title>
      </Helmet>

      <MembershipInsightsView />
    </>
  );
}