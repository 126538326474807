import React, { useCallback, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
// @mui
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { blue, orange } from '@mui/material/colors';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
//
import { DateTime } from 'luxon';
// components
import { useDispatch, useSelector } from '../../../redux/store';
//
import { useLocales } from '../../../locales';
import { getActiveMembers, getActiveMemberships } from '../../../redux/slices/insight';
import Chart from '../../../components/chart/chart';
import { useChart } from '../../../components/chart';
import { fNumber } from '../../../utils/format-number';
import { IInsightTableFilters } from '../../../types/insight';


// ----------------------------------------------------------------------

export default function ActiveMembershipView({
                                           fromDate,
                                           toDate,
                                         }: IInsightTableFilters) {
  const { t } = useLocales();

  const dispatch = useDispatch();

  const report = useSelector((state) => state.insight.activeMembershipReport);

  useEffect(() => {
    dispatch(getActiveMemberships({
      fromDate,
      toDate,
    }));
  }, [dispatch, fromDate, toDate]);

  const chart = {
    series: [{
      data: report.series?.map(p => (
        {
          x: p.x,
          y: [p.open, p.high, p.low, p.close],
        }
      )) ?? [],
    }],
  };

  const chartOptions = useChart({
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    xaxis: {
      labels: {
        formatter: (value) =>
          DateTime.fromISO(value).toFormat('MMMM yyyy'),
      },
    },
    dataLabels: {
      formatter: (val, opts) => fNumber(val as number),
      enabled: false,
    },
    yaxis: {
      labels: {
        formatter: (value) => `${fNumber(value)}`,
      },
    },
    annotations: {
      yaxis: [
        {
          y: report.median ?? 0,
          borderColor: blue['400'],
          label: {
            borderColor: blue['400'],
            style: {
              color: '#fff',
              background: blue['400'],
            },
            text: t('Median'),
          },
        },
        {
          y: report.average ?? 0,
          borderColor: orange['400'],
          label: {
            borderColor: orange['400'],
            style: {
              color: '#fff',
              background: orange['400'],
            },
            text: t('Average'),
          },
        },
      ],
    },
  });

  return (
    <>
      <Card>
        <CardHeader
          title={t('Active memberships')}
          subheader={t('Shows the number of active memberships over time and the high/low for each month. Included membership types are contingent, campaign, punch cards, and personal training.')}
          action={<> </>}
        />
        <CardContent>
          <Chart dir="ltr" type="candlestick" series={chart.series} options={chartOptions} />
        </CardContent>
      </Card>
    </>
  );
}
