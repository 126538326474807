import React, { useCallback, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
// @mui
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { blue, orange } from '@mui/material/colors';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
//
import { DateTime } from 'luxon';
// components
import { useDispatch, useSelector } from '../../../redux/store';
//
import { IReportTableFilters } from '../../../types/report';
import { useLocales } from '../../../locales';
import { getMemberChurnReport } from '../../../redux/slices/report';
import Chart from '../../../components/chart/chart';
import { useChart } from '../../../components/chart';
import { fNumber, fShortenNumber } from '../../../utils/format-number';
import Iconify from '../../../components/iconify';
import CustomPopover, { usePopover } from '../../../components/custom-popover';
import { ChartSegments } from '../../../api';
import { IInsightTableFilters } from '../../../types/insight';
import {
  getAgeDistribution,
  getGenderDistribution,
  getMemberLifetime,
  getMembershipDistribution,
} from '../../../redux/slices/insight';


// ----------------------------------------------------------------------

export default function MemberAgeDistributionView({
                                                    fromDate,
                                                    toDate,
                                                  }: IInsightTableFilters) {
  const { t } = useLocales();

  const dispatch = useDispatch();

  const popover = usePopover();

  const report = useSelector((state) => state.insight.genderDistribution);

  const [segment, setSegment] = useState(ChartSegments.All);

  useEffect(() => {
    dispatch(getGenderDistribution({
      fromDate,
      toDate,
    }));
  }, [dispatch, fromDate, toDate]);


  const chart = {
    name: report.name ?? 'All',
    series: report.series ?? [],
    labels: report.labels ?? [],
  };

  const theme = useTheme();

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    labels: report.labels ?? [],
    stroke: {
      colors: [theme.palette.background.paper],
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (value: number) => fNumber(value),
        title: {
          formatter: (seriesName: string) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    title: {
      text: chart.name,
    },
    legend: {
      position: 'bottom',
    },
  });

  return (
    <>
      <Card>
        <CardHeader
          title={t('Gender distribution')}
          subheader={t('Shows gender distribution for the selected period')}
          action={<></>}
        />
        <CardContent>
          <Chart type="polarArea" series={chart.series} options={chartOptions} />
        </CardContent>
      </Card>
      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 140 }}>
        <MenuItem
          key={ChartSegments.All}
          selected={ChartSegments.All === segment}
          onClick={() => {
            popover.onClose();
            setSegment(ChartSegments.All);
          }}
        >
          {t(ChartSegments.All)}
        </MenuItem>
        <MenuItem
          key={ChartSegments.Gender}
          selected={ChartSegments.Gender === segment}
          onClick={() => {
            popover.onClose();
            setSegment(ChartSegments.Gender);
          }}
        >
          {t(ChartSegments.Gender)}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
