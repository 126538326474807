/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { DateTime } from 'luxon';

export interface IRequestOptions extends AxiosRequestConfig {
  /** only in axios interceptor config*/
  loading?: boolean;
  showError?: boolean;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
  /** only in axios interceptor config*/
  loading: boolean;
  showError: boolean;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = {
    loading: serviceOptions.loading,
    showError: serviceOptions.showError,
    ...options,
    method,
    url
  };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AccountService {
  /**
   *
   */
  static createBusinessAccount(
    params: {
      /** requestBody */
      body?: CreateBusinessAccountDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAccount(options: IRequestOptions = {}): Promise<AccountDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAccount1(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updatePaymentMethod(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: CreateStripeSessionDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CheckoutSessionDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/{id}/payment-method';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ActivitiesService {
  /**
   *
   */
  static index(options: IRequestOptions = {}): Promise<ActivityDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/activities';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class AddressesService {
  /**
   *
   */
  static search(
    params: {
      /**  */
      query?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AddressDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/addresses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { query: params['query'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class AuthenticationService {
  /**
   *
   */
  static authenticate(
    params: {
      /**  */
      tenantId?: number;
      /** requestBody */
      body?: AuthenticationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuthorizationDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { tenantId: params['tenantId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class BillableTypesService {
  /**
   *
   */
  static index(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/billable-types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class BookkeepingService {
  /**
   *
   */
  static export(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/bookkeeping';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class CalendarService {
  /**
   *
   */
  static getById(
    params: {
      /**  */
      calendarId: string;
      /**  */
      accessKey?: string;
      /**  */
      tenantId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/calendars/{calendarId}';
      url = url.replace('{calendarId}', params['calendarId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { accessKey: params['accessKey'], tenantId: params['tenantId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class CampaignService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      membershipTypes?: any | null[];
      /**  */
      validTo?: string;
      /**  */
      validFrom?: string;
      /**  */
      statuses?: MembershipStatuses[];
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembershipDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/campaigns';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        membershipTypes: params['membershipTypes'],
        validTo: params['validTo'],
        validFrom: params['validFrom'],
        statuses: params['statuses'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateCampaignMembershipDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembershipDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/campaigns';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateMembershipDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembershipDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/campaigns/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancel(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: CancelMembershipDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/campaigns/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendReminder(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: SendReminderDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/campaigns/{id}/reminder';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static putOnHold(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: PutMembershipOnHoldDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembershipDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/campaigns/{id}/on-hold-period';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static editOnHoldPeriod(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: PutMembershipOnHoldDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembershipDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/campaigns/{id}/on-hold-period';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CampaignTypesService {
  /**
   *
   */
  static index(
    params: {
      /**  */
      validFrom?: string;
      /**  */
      validTo?: string;
      /**  */
      validOn?: string;
      /**  */
      availableFrom?: string;
      /**  */
      isActive?: boolean;
      /**  */
      isPublic?: boolean;
      /**  */
      search?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CampaignMembershipTypeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/campaign-types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        validFrom: params['validFrom'],
        validTo: params['validTo'],
        validOn: params['validOn'],
        availableFrom: params['availableFrom'],
        isActive: params['isActive'],
        isPublic: params['isPublic'],
        search: params['search'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateCampaignMembershipTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CampaignMembershipTypeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/campaign-types';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/campaign-types';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { id: params['id'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static details(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CampaignMembershipTypeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/campaign-types/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditCampaignMembershipTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CampaignMembershipTypeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/campaign-types/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createPurchaseLink(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PurchaseLinkDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/campaign-types/{id}/purchase-link';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ChainsService {
  /**
   *
   */
  static getChains(options: IRequestOptions = {}): Promise<ChainDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chains';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uploadLogo(
    params: {
      /**  */
      id: number;
      /**  */
      files: any[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chains/{id}/logo';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('files', item as any);
          }
        } else {
          data.append('files', params['files'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addPaymentProvider(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: AddPaymentProvider;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CredentialDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chains/{id}/payment-providers';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPaymentProviders(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CredentialDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chains/{id}/payment-providers';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ChainSearchesService {
  /**
   *
   */
  static search(
    params: {
      /**  */
      query?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChainDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chain-searches';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { query: params['query'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ChainSettingsService {
  /**
   *
   */
  static get(options: IRequestOptions = {}): Promise<ChainSettingsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chain-settings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: ChainSettingsDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChainSettingsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chain-settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ChatsService {
  /**
   *
   */
  static index(
    params: {
      /**  */
      search?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChatDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chats';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        search: params['search'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateChatDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChatDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chats';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static details(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChatDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chats/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditChatDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChatDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chats/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chats/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addMessage(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: ChatMessageDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChatMessageDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chats/{id}/messages';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addParticipant(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: ChatParticipantDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChatMessageDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chats/{id}/participants';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ClassDescriptionsService {
  /**
   *
   */
  static index(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
      /**  */
      statuses?: EntityStatuses[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassDescriptionDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/class-descriptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction'],
        statuses: params['statuses']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateClassDescriptionDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassDescriptionDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/class-descriptions';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static details(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassDescriptionDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/class-descriptions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: CreateClassDescriptionDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassDescriptionDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/class-descriptions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/class-descriptions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setBackgroundImage(
    params: {
      /**  */
      id: number;
      /**  */
      files: any[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/class-descriptions/{id}/images';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('files', item as any);
          }
        } else {
          data.append('files', params['files'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeImage(
    params: {
      /**  */
      descriptionId: number;
      /**  */
      imageId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/class-descriptions/{descriptionId}/images/{imageId}';
      url = url.replace('{descriptionId}', params['descriptionId'] + '');
      url = url.replace('{imageId}', params['imageId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ClassesService {
  /**
   *
   */
  static index(
    params: {
      /**  */
      from?: string;
      /**  */
      to?: string;
      /**  */
      searchString?: string;
      /**  */
      sortBy?: any | null[];
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      direction?: Direction;
      /**  */
      selectedLocationId?: number;
      /**  */
      employeeId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClazzDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Classes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        from: params['from'],
        to: params['to'],
        searchString: params['searchString'],
        sortBy: params['sortBy'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        direction: params['direction'],
        selectedLocationId: params['selectedLocationId'],
        employeeId: params['employeeId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateClassDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClazzDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Classes';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static details(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClazzDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Classes/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditClassDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClazzDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Classes/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Classes/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRegistrations(
    params: {
      /**  */
      classId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SignUpDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Classes/{classId}/sign-ups';
      url = url.replace('{classId}', params['classId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendMessage(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: MessageDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MessageDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Classes/{id}/messages';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMessages(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MessageDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Classes/{id}/messages';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ClassFeedbackService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      classId?: number;
      /**  */
      employeeId?: number;
      /**  */
      from?: string;
      /**  */
      to?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassFeedbackDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/class-feedback';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        classId: params['classId'],
        employeeId: params['employeeId'],
        from: params['from'],
        to: params['to'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ClassTemplatesService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
      /**  */
      search?: string;
      /**  */
      isActive?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassTemplateDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/class-templates';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction'],
        search: params['search'],
        isActive: params['isActive']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateClassTemplateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateClassTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/class-templates';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTemplateById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/class-templates/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditClassTemplateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/class-templates/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteTemplate(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/class-templates/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setBackgroundImage(
    params: {
      /**  */
      id: number;
      /**  */
      files: any[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/class-templates/{id}/images';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('files', item as any);
          }
        } else {
          data.append('files', params['files'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CommentService {
  /**
   *
   */
  static remove(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/comments/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ContingentService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      membershipTypes?: any | null[];
      /**  */
      validTo?: string;
      /**  */
      validFrom?: string;
      /**  */
      statuses?: MembershipStatuses[];
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembershipDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contingents';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        membershipTypes: params['membershipTypes'],
        validTo: params['validTo'],
        validFrom: params['validFrom'],
        statuses: params['statuses'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateContingentMembershipDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembershipDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contingents';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateMembershipDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembershipDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contingents/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancel(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: CancelMembershipDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contingents/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendReminder(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: SendReminderDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contingents/{id}/reminder';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static putOnHold(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: PutMembershipOnHoldDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembershipDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contingents/{id}/on-hold-period';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static editOnHoldPeriod(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: PutMembershipOnHoldDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembershipDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contingents/{id}/on-hold-period';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ContingentTypesService {
  /**
   *
   */
  static index(
    params: {
      /**  */
      validFrom?: string;
      /**  */
      validTo?: string;
      /**  */
      validOn?: string;
      /**  */
      availableFrom?: string;
      /**  */
      isActive?: boolean;
      /**  */
      isPublic?: boolean;
      /**  */
      search?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContingentTypeDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contingent-types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        validFrom: params['validFrom'],
        validTo: params['validTo'],
        validOn: params['validOn'],
        availableFrom: params['availableFrom'],
        isActive: params['isActive'],
        isPublic: params['isPublic'],
        search: params['search'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateContingentTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContingentTypeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contingent-types';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static details(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContingentTypeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contingent-types/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditContingentTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContingentTypeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contingent-types/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contingent-types/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createPurchaseLink(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PurchaseLinkDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contingent-types/{id}/purchase-link';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ContractsService {
  /**
   *
   */
  static getContractTemplates(options: IRequestOptions = {}): Promise<ContractDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contracts';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postContractTemplate(
    params: {
      /** requestBody */
      body?: ContractDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContractDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contracts';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getContractTemplate(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContractDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contracts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static putContractTemplate(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: ContractDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContractDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contracts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteContractTemplate(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contracts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CurrenciesService {
  /**
   *
   */
  static index(options: IRequestOptions = {}): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/currencies';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class CustomersService {
  /**
   *
   */
  static index(
    params: {
      /**  */
      name?: string;
      /**  */
      companyName?: string;
      /**  */
      vatNo?: string;
      /**  */
      email?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        name: params['name'],
        companyName: params['companyName'],
        vatNo: params['vatNo'],
        email: params['email']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class DiscountVoucherService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
      /**  */
      search?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DiscountVoucherDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/discount-vouchers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction'],
        search: params['search']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateDiscountVoucherDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DiscountVoucherDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/discount-vouchers';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DiscountVoucherDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/discount-vouchers/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditDiscountVoucherDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DiscountVoucherDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/discount-vouchers/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static remove(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DiscountVoucherDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/discount-vouchers/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAppliedTo(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembershipDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/discount-vouchers/{id}/memberships';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class DoorSystemIntegrationService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateDoorSystemIntegrationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DoorSystemIntegrationDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/door-system-integration';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class DoorSystemLockService {
  /**
   *
   */
  static findAll(options: IRequestOptions = {}): Promise<ExternalLockDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/door-system-locks';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class DropInsService {
  /**
   *
   */
  static details(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DropInDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/drop-ins/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateMembershipDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DropInDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/drop-ins/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateDropInMembershipDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DropInDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/drop-ins';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class DropInTypesService {
  /**
   *
   */
  static index(
    params: {
      /**  */
      validFrom?: string;
      /**  */
      validTo?: string;
      /**  */
      validOn?: string;
      /**  */
      availableFrom?: string;
      /**  */
      isActive?: boolean;
      /**  */
      isPublic?: boolean;
      /**  */
      search?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DropInTypeDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/drop-in-types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        validFrom: params['validFrom'],
        validTo: params['validTo'],
        validOn: params['validOn'],
        availableFrom: params['availableFrom'],
        isActive: params['isActive'],
        isPublic: params['isPublic'],
        search: params['search'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateDropInTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DropInTypeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/drop-in-types';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static details(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DropInTypeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/drop-in-types/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditDropInTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DropInTypeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/drop-in-types/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/drop-in-types/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createPurchaseLink(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PurchaseLinkDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/drop-in-types/{id}/purchase-link';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class EconomicsService {
  /**
   *
   */
  static getOverview(
    params: {
      /**  */
      month?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EconomicsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/economics';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { month: params['month'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class EmailAddressesService {
  /**
   *
   */
  static getEmailList(options: IRequestOptions = {}): Promise<EmailDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/email-addresses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class EmailTemplatesService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
      /**  */
      search?: string;
      /**  */
      type?: EmailTemplateType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmailTemplateDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/email-templates';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction'],
        search: params['search'],
        type: params['type']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: EmailTemplateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmailTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/email-templates';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmailTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/email-templates/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EmailTemplateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmailTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/email-templates/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static remove(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/email-templates/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uploadAttachment(
    params: {
      /**  */
      id: number;
      /**  */
      files: any[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/email-templates/{id}/attachments';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('files', item as any);
          }
        } else {
          data.append('files', params['files'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class EmployeesService {
  /**
   *
   */
  static index(
    params: {
      /**  */
      searchString?: string;
      /**  */
      roles?: any | null[];
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
      /**  */
      isActive?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmployeeDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/employees';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        searchString: params['searchString'],
        roles: params['roles'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction'],
        isActive: params['isActive']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateEmployeeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmployeeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/employees';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static details(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmployeeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/employees/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditEmployeeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmployeeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/employees/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/employees/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static profileImage(
    params: {
      /**  */
      id: number;
      /**  */
      files: any[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/employees/{id}/profile-image';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('files', item as any);
          }
        } else {
          data.append('files', params['files'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static editWage(
    params: {
      /**  */
      id: number;
      /**  */
      wageRateId: number;
      /** requestBody */
      body?: UpdateWageDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmployeeWageRateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/employees/{id}/wages/{wageRateId}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{wageRateId}', params['wageRateId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteWageRate(
    params: {
      /**  */
      id: number;
      /**  */
      wageRateId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HourlyWageRateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/employees/{id}/wages/{wageRateId}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{wageRateId}', params['wageRateId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addWage(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: CreateWageDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmployeeWageRateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/employees/{id}/wages';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getWages(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmployeeWageRateDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/employees/{id}/wages';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFeedbackStats(
    params: {
      /**  */
      employeeId: number;
      /**  */
      numberOfMonths?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmployeeFeedbackStatsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/employees/{employeeId}/feedback-stats';
      url = url.replace('{employeeId}', params['employeeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { numberOfMonths: params['numberOfMonths'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getShifts(
    params: {
      /**  */
      employeeId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ShiftDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/employees/{employeeId}/shifts';
      url = url.replace('{employeeId}', params['employeeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateShifts(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EmployeeShiftUpdateDto[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ShiftDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/employees/{id}/shifts';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getClients(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/employees/{id}/clients';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class EventService {
  /**
   *
   */
  static index(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
      /**  */
      from?: string;
      /**  */
      to?: string;
      /**  */
      activeDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/events';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction'],
        from: params['from'],
        to: params['to'],
        activeDate: params['activeDate']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateEventDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/events';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditEventDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/events/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancel(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/events/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static details(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventDetailsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/events/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTickets(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TicketDetailsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/events/{id}/tickets';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTicketTypes(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventDetailsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/events/{id}/ticket-types';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createTicketType(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: CreateTicketTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventDetailsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/events/{id}/ticket-types';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setBackgroundImage(
    params: {
      /**  */
      id: number;
      /**  */
      files: any[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/events/{id}/images';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('files', item as any);
          }
        } else {
          data.append('files', params['files'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeImage(
    params: {
      /**  */
      eventId: number;
      /**  */
      imageId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/events/{eventId}/images/{imageId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{imageId}', params['imageId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createTicketOption(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: CreateOptionTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventDetailsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/events/{id}/ticket-options';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteTicketType(
    params: {
      /**  */
      eventId: number;
      /**  */
      ticketTypeId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventDetailsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/events/{eventId}/ticket-types/{ticketTypeId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{ticketTypeId}', params['ticketTypeId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteTicketOption(
    params: {
      /**  */
      eventId: number;
      /**  */
      ticketOptionTypeId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventDetailsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/events/{eventId}/ticket-options/{ticketOptionTypeId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{ticketOptionTypeId}', params['ticketOptionTypeId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createRegistration(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: CreateRegistrationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TicketDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/events/{id}/registrations';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ExerciseTypesService {
  /**
   *
   */
  static findAll(options: IRequestOptions = {}): Promise<ExerciseTypeDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/exercise-types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class FineService {
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FineDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/fines/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class FineCollectionService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: FineCollectionDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FineDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/fine-collections';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class GuestService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateGuestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuestDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/guest';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static index(
    params: {
      /**  */
      from?: string;
      /**  */
      to?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuestDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/guest';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { from: params['from'], to: params['to'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class GymsService {
  /**
   *
   */
  static index(options: IRequestOptions = {}): Promise<GymDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/gyms';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateGymDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GymDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/gyms';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static details(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GymDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/gyms/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditGymDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GymDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/gyms/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/gyms/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static logo(
    params: {
      /**  */
      id: number;
      /**  */
      files: any[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/gyms/{id}/images';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('files', item as any);
          }
        } else {
          data.append('files', params['files'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeImage(
    params: {
      /**  */
      gymId: number;
      /**  */
      imageId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/gyms/{gymId}/images/{imageId}';
      url = url.replace('{gymId}', params['gymId'] + '');
      url = url.replace('{imageId}', params['imageId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class HourBankService {
  /**
   *
   */
  static getHours(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HoursDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/hour-bank';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ImageService {
  /**
   *
   */
  static create(
    params: {
      /**  */
      files: any[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FileDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/images';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('files', item as any);
          }
        } else {
          data.append('files', params['files'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ImportService {
  /**
   *
   */
  static importMembers(
    params: {
      /**  */
      gymId: number;
      /**  */
      memberFiles: any[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/import/{gymId}/members';
      url = url.replace('{gymId}', params['gymId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['memberFiles']) {
        if (Object.prototype.toString.call(params['memberFiles']) === '[object Array]') {
          for (const item of params['memberFiles']) {
            data.append('memberFiles', item as any);
          }
        } else {
          data.append('memberFiles', params['memberFiles'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static importMemberships(
    params: {
      /**  */
      gymId: number;
      /**  */
      membershipFiles: any[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/import/{gymId}/memberships';
      url = url.replace('{gymId}', params['gymId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['membershipFiles']) {
        if (Object.prototype.toString.call(params['membershipFiles']) === '[object Array]') {
          for (const item of params['membershipFiles']) {
            data.append('membershipFiles', item as any);
          }
        } else {
          data.append('membershipFiles', params['membershipFiles'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static importPaymentMethods(
    params: {
      /**  */
      gymId: number;
      /**  */
      paymentMethodFiles: any[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/import/{gymId}/payment-methods';
      url = url.replace('{gymId}', params['gymId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['paymentMethodFiles']) {
        if (Object.prototype.toString.call(params['paymentMethodFiles']) === '[object Array]') {
          for (const item of params['paymentMethodFiles']) {
            data.append('paymentMethodFiles', item as any);
          }
        } else {
          data.append('paymentMethodFiles', params['paymentMethodFiles'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class InsightsService {
  /**
   *
   */
  static activeMembers(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandlestickDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/insights/members/active-members';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberLifetime(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      segment?: ChartSegments;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DateTimeAreaDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/insights/members/member-lifetime';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], segment: params['segment'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberLifetimeValue(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      segment?: ChartSegments;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DateTimeAreaDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/insights/members/member-lifetime-value';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], segment: params['segment'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberMonthlyRecurringRevenue(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      segment?: ChartSegments;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DateTimeAreaDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/insights/members/member-monthly-recurring-revenue';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], segment: params['segment'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberMonthlyRevenue(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      segment?: ChartSegments;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DateTimeAreaDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/insights/members/member-monthly-recurring-revenue';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], segment: params['segment'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static monthlyRevenue(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      segment?: ChartSegments;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DateTimeAreaDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/insights/revenue/monthly-revenue';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], segment: params['segment'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static monthlyRecurringRevenue(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      segment?: ChartSegments;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DateTimeAreaDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/insights/revenue/monthly-recurring-revenue';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], segment: params['segment'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static membershipMonthlyRevenue(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      segment?: ChartSegments;
      membershipType?: string;
      membershipTypeId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DateTimeAreaDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/insights/revenue/membership-monthly-revenue';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], segment: params['segment'], membershipType: params['membershipType'], membershipTypeId: params['membershipTypeId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static finesMonthlyRevenue(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      segment?: ChartSegments;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DateTimeAreaDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/insights/revenue/fines-monthly-revenue';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], segment: params['segment'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static productMonthlyRevenue(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      segment?: ChartSegments;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DateTimeAreaDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/insights/revenue/monthly-product-revenue';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], segment: params['segment'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static productMonthlyRevenuePerCategory(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      segment?: ChartSegments;
      productCategoryId?: number | null;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DateTimeAreaDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/insights/revenue/monthly-product-revenue-by-category';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], segment: params['segment'], productCategoryId: params['productCategoryId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberMonthlyAverageRevenue(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      segment?: ChartSegments;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DateTimeAreaDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/insights/members/member-monthly-average-revenue';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], segment: params['segment'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leg(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DateOnlyAreaDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/insights/members/length-of-engagement';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static ageDistribution(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      segment?: ChartSegments;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PieChartDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/insights/members/age-distribution';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], segment: params['segment'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static genderDistribution(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      segment?: ChartSegments;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PieChartDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/insights/members/gender-distribution';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], segment: params['segment'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static activeMemberships(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandlestickDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/insights/memberships/active-memberships';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static membershipDistribution(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      segment?: ChartSegments;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PieChartDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/insights/memberships/membership-distribution';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], segment: params['segment'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static contingentDistribution(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      segment?: ChartSegments;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PieChartDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/insights/memberships/contingent-distribution';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], segment: params['segment'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static campaignDistribution(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      segment?: ChartSegments;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PieChartDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/insights/memberships/campaign-distribution';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], segment: params['segment'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static punchCardDistribution(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      segment?: ChartSegments;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PieChartDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/insights/memberships/punch-card-distribution';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], segment: params['segment'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static personalTrainingDistribution(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      segment?: ChartSegments;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PieChartDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/insights/memberships/personal-training-distribution';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], segment: params['segment'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static dropInDistribution(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      segment?: ChartSegments;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PieChartDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/insights/memberships/drop-in-distribution';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], segment: params['segment'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class InvoicesService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
      /**  */
      startDate?: string;
      /**  */
      endDate?: string;
      /**  */
      search?: string;
      /**  */
      statuses?: InvoiceStatuses[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InvoiceDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/invoices';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction'],
        startDate: params['startDate'],
        endDate: params['endDate'],
        search: params['search'],
        statuses: params['statuses']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateInvoiceDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InvoiceDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/invoices';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InvoiceDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/invoices/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTransactions(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/invoices/{id}/transactions';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRefunds(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RefundDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/invoices/{id}/refunds';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static checkout(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: CreateStripeSessionDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CheckoutSessionDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/invoices/{id}/checkout';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendToDebtCollection(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InvoiceDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/invoices/{id}/debt-collection';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static retryPayment(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: PaymentDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InvoiceDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/invoices/{id}/payment';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendReceipt(
    params: {
      /**  */
      invoiceId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/invoices/{invoiceId}/receipts';
      url = url.replace('{invoiceId}', params['invoiceId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LeadService {
  /**
   *
   */
  static index(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
      /**  */
      search?: string;
      /**  */
      status?: LeadStatuses;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LeadDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/leads';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction'],
        search: params['search'],
        status: params['status']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class LedgerService {
  /**
   *
   */
  static index(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LedgerEntryDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ledgers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class LocationsService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/locations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateLocationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/locations';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/locations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditLocationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/locations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static remove(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/locations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LocksService {
  /**
   *
   */
  static details(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LockDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/locks/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditLockDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LockDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/locks/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class MailsService {
  /**
   *
   */
  static sendEmail(
    params: {
      /** requestBody */
      body?: EmailDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmailDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/mails';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class MembersService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
      /**  */
      search?: string;
      /**  */
      isActive?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MemberDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction'],
        search: params['search'],
        isActive: params['isActive']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateMemberDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MemberDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static details(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{memberId}';
      url = url.replace('{memberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInvoices(
    params: {
      /**  */
      memberId: number;
      /**  */
      onlyMemberships?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InvoiceDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{memberId}/invoices';
      url = url.replace('{memberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { onlyMemberships: params['onlyMemberships'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStats(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MemberStatsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{memberId}/status';
      url = url.replace('{memberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditMemberDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static profileImage(
    params: {
      /**  */
      id: number;
      /**  */
      files: any[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{id}/profile_image';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('files', item as any);
          }
        } else {
          data.append('files', params['files'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMemberships(
    params: {
      /**  */
      id: number;
      /**  */
      statuses?: MembershipStatuses[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembershipDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{id}/memberships';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { statuses: params['statuses'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPersonalTrainers(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmployeeDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{memberId}/personal-trainers';
      url = url.replace('{memberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addPersonalTrainer(
    params: {
      /**  */
      memberId: number;
      /** requestBody */
      body?: AddPersonalTrainerToMembershipDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{memberId}/personal-trainers';
      url = url.replace('{memberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPersonalPrograms(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgramTemplateDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{memberId}/personal-programs';
      url = url.replace('{memberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addPersonalProgram(
    params: {
      /**  */
      memberId: number;
      /** requestBody */
      body?: CreateProgramTemplateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgramTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{memberId}/personal-programs';
      url = url.replace('{memberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static activatePersonalProgram(
    params: {
      /**  */
      memberId: number;
      /**  */
      programTemplateId: number;
      /** requestBody */
      body?: CreateProgramFromTemplateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgramTemplateDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{memberId}/personal-programs/{programTemplateId}';
      url = url.replace('{memberId}', params['memberId'] + '');
      url = url.replace('{programTemplateId}', params['programTemplateId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addDocument(
    params: {
      /**  */
      memberId: number;
      /**  */
      files: any[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{memberId}/documents';
      url = url.replace('{memberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('files', item as any);
          }
        } else {
          data.append('files', params['files'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDocuments(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{memberId}/documents';
      url = url.replace('{memberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getExerciseResults(
    params: {
      /**  */
      memberId: number;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExerciseResultDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{memberId}/exercise-results';
      url = url.replace('{memberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getWorkoutResults(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkoutResultDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{memberId}/workout-results';
      url = url.replace('{memberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeDocument(
    params: {
      /**  */
      memberId: number;
      /**  */
      documentId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{memberId}/documents/{documentId}';
      url = url.replace('{memberId}', params['memberId'] + '');
      url = url.replace('{documentId}', params['documentId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPaymentMethods(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentMethodDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{memberId}/payment-methods';
      url = url.replace('{memberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updatePaymentMethod(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: CreateStripeSessionDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CheckoutSessionDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{id}/payment-method';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removePaymentMethod(
    params: {
      /**  */
      memberId: number;
      /**  */
      paymentMethodId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{memberId}/payment-methods/{paymentMethodId}';
      url = url.replace('{memberId}', params['memberId'] + '');
      url = url.replace('{paymentMethodId}', params['paymentMethodId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getVouchers(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VoucherDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{id}/vouchers';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFines(
    params: {
      /**  */
      id: number;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FineDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{id}/fines';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getComments(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommentDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{id}/comments';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createComment(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: CreateCommentDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommentDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{id}/comments';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEvents(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MemberEventDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{id}/events';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updatePassword(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PasswordDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{id}/password';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static adjustCreditBalance(
    params: {
      /**  */
      memberId: number;
      /** requestBody */
      body?: AdjustCreditBalanceDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountCreditBalanceEntryDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{memberId}/credit-balance';
      url = url.replace('{memberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCreditBalance(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountCreditBalanceEntryDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{memberId}/credit-balance';
      url = url.replace('{memberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LogsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{memberId}/logs';
      url = url.replace('{memberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateDoorAccess(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{memberId}/door-system-users';
      url = url.replace('{memberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class MembershipService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      membershipTypes?: any | null[];
      /**  */
      validTo?: string;
      /**  */
      validFrom?: string;
      /**  */
      validOn?: string;
      /**  */
      search?: string;
      /**  */
      statuses?: MembershipStatuses[];
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembershipDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/memberships';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        membershipTypes: params['membershipTypes'],
        validTo: params['validTo'],
        validFrom: params['validFrom'],
        validOn: params['validOn'],
        search: params['search'],
        statuses: params['statuses'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateMembershipDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembershipDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/memberships/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancel(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: CancelMembershipDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/memberships/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendReminder(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: SendReminderDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/memberships/{id}/reminder';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static putOnHold(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: PutMembershipOnHoldDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembershipDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/memberships/{id}/on-hold-period';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static editOnHoldPeriod(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: PutMembershipOnHoldDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembershipDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/memberships/{id}/on-hold-period';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class NewsService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
      /**  */
      search?: string;
      /**  */
      status?: NewsStatus;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NewsDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/news';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction'],
        search: params['search'],
        status: params['status']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateNewsDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NewsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/news';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NewsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/news/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditNewsDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NewsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/news/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static remove(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NewsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/news/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addImage(
    params: {
      /**  */
      id: number;
      /**  */
      files: any[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/news/{id}/image';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('files', item as any);
          }
        } else {
          data.append('files', params['files'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class NewslettersService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
      /**  */
      search?: string;
      /**  */
      status?: NewsletterStatus;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NewsletterDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/newsletters';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction'],
        search: params['search'],
        status: params['status']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateNewsletterDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NewsletterDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/newsletters';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NewsletterDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/newsletters/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditNewsletterDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NewsletterDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/newsletters/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static remove(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/newsletters/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uploadAttachment(
    params: {
      /**  */
      id: number;
      /**  */
      files: any[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/newsletters/{id}/attachments';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('files', item as any);
          }
        } else {
          data.append('files', params['files'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class OrdersService {
  /**
   *
   */
  static details(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrderDetailsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/orders/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static index(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
      /**  */
      startDate?: string;
      /**  */
      endDate?: string;
      /**  */
      search?: string;
      /**  */
      statuses?: OrderStatuses[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrderDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/orders';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction'],
        startDate: params['startDate'],
        endDate: params['endDate'],
        search: params['search'],
        statuses: params['statuses']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateOrderDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrderDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/orders';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static remove(
    params: {
      /**  */
      orderId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/orders/{orderId}';
      url = url.replace('{orderId}', params['orderId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendReminder(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: SendReminderDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/orders/{id}/reminder';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendInvoice(
    params: {
      /**  */
      orderId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/orders/{orderId}/mails';
      url = url.replace('{orderId}', params['orderId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendReceipt(
    params: {
      /**  */
      orderId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/orders/{orderId}/receipts';
      url = url.replace('{orderId}', params['orderId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static refund(
    params: {
      /**  */
      orderId: number;
      /**  */
      orderItemId: number;
      /** requestBody */
      body?: RefundDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InvoiceDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/orders/{orderId}/items/{orderItemId}/refund';
      url = url.replace('{orderId}', params['orderId'] + '');
      url = url.replace('{orderItemId}', params['orderItemId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class PersonalProgramsService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateProgramTemplateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgramTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/personal-programs';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static index(
    params: {
      /**  */
      from?: string;
      /**  */
      to?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgramTemplateDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/personal-programs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { from: params['from'], to: params['to'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      programId: number;
      /** requestBody */
      body?: EditProgramTemplateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgramTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/personal-programs/{programId}';
      url = url.replace('{programId}', params['programId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      programId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgramTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/personal-programs/{programId}';
      url = url.replace('{programId}', params['programId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createProgramFromTemplate(
    params: {
      /**  */
      programId: number;
      /** requestBody */
      body?: CreateProgramFromTemplateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgramTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/personal-programs/{programId}/programs';
      url = url.replace('{programId}', params['programId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class PersonalTrainingService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      membershipTypes?: any | null[];
      /**  */
      validTo?: string;
      /**  */
      validFrom?: string;
      /**  */
      validOn?: string;
      /**  */
      statuses?: MembershipStatuses[];
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembershipDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/personal-trainings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        membershipTypes: params['membershipTypes'],
        validTo: params['validTo'],
        validFrom: params['validFrom'],
        validOn: params['validOn'],
        statuses: params['statuses'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreatePersonalTrainingMembershipDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembershipDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/personal-trainings';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateMembershipDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembershipDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/personal-trainings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancel(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: CancelMembershipDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/personal-trainings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendReminder(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: SendReminderDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/personal-trainings/{id}/reminder';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addPersonalTrainer(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: AddPersonalTrainerToMembershipDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/personal-trainings/{id}/personal-trainers';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class PersonalTrainingTypesService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      validFrom?: string;
      /**  */
      validTo?: string;
      /**  */
      validOn?: string;
      /**  */
      availableFrom?: string;
      /**  */
      isActive?: boolean;
      /**  */
      isPublic?: boolean;
      /**  */
      search?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PersonalTrainingTypeDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/personal-training-types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        validFrom: params['validFrom'],
        validTo: params['validTo'],
        validOn: params['validOn'],
        availableFrom: params['availableFrom'],
        isActive: params['isActive'],
        isPublic: params['isPublic'],
        search: params['search'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreatePersonalTrainingTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PersonalTrainingTypeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/personal-training-types';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static details(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PersonalTrainingTypeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/personal-training-types/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditPersonalTrainingTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PersonalTrainingTypeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/personal-training-types/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/personal-training-types/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createPurchaseLink(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PurchaseLinkDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/personal-training-types/{id}/purchase-link';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class PlaceService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PlaceDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/places';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class PlanningService {
  /**
   *
   */
  static get(
    params: {
      /**  */
      start?: string;
      /**  */
      end?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassHourCalendarDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/planning';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { start: params['start'], end: params['end'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ProductCategoriesService {
  /**
   *
   */
  static index(options: IRequestOptions = {}): Promise<ProductCategoryDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/product-categories';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ProductCategoryDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductCategoryDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/product-categories';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static details(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductCategoryDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/product-categories/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: ProductCategoryDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductCategoryDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/product-categories/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/product-categories/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ProductsService {
  /**
   *
   */
  static getProducts(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      searchString?: string;
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/products';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        searchString: params['searchString'],
        direction: params['direction']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateProductDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/products';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProduct(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/products/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditProductDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/products/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteProduct(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/products/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPurchases(
    params: {
      /**  */
      id: number;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrderItemDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/products/{id}/purchases';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addImage(
    params: {
      /**  */
      id: number;
      /**  */
      files: any[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/products/{id}/images';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('files', item as any);
          }
        } else {
          data.append('files', params['files'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeImage(
    params: {
      /**  */
      productId: number;
      /**  */
      imageId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/products/{productId}/images/{imageId}';
      url = url.replace('{productId}', params['productId'] + '');
      url = url.replace('{imageId}', params['imageId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ProgramsService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateProgramDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgramDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/programs';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static index(
    params: {
      /**  */
      from?: string;
      /**  */
      to?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgramDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/programs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { from: params['from'], to: params['to'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      programId: number;
      /** requestBody */
      body?: EditProgramDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgramDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/programs/{programId}';
      url = url.replace('{programId}', params['programId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      programId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgramDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/programs/{programId}';
      url = url.replace('{programId}', params['programId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeById(
    params: {
      /**  */
      programId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgramDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/programs/{programId}';
      url = url.replace('{programId}', params['programId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ProgramTemplatesService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateProgramTemplateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgramTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/program-templates';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static index(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
      /**  */
      search?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgramTemplateDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/program-templates';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction'],
        search: params['search']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      programId: number;
      /** requestBody */
      body?: EditProgramTemplateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgramTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/program-templates/{programId}';
      url = url.replace('{programId}', params['programId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      programId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgramTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/program-templates/{programId}';
      url = url.replace('{programId}', params['programId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static remove(
    params: {
      /**  */
      templateId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgramTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/program-templates/{templateId}';
      url = url.replace('{templateId}', params['templateId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createProgramFromTemplate(
    params: {
      /**  */
      programId: number;
      /** requestBody */
      body?: CreateProgramFromTemplateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgramTemplateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/program-templates/{programId}/programs';
      url = url.replace('{programId}', params['programId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class PushNotificationService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
      /**  */
      search?: string;
      /**  */
      status?: PushNotificationStatus;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PushNotificationDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/push-notifications';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction'],
        search: params['search'],
        status: params['status']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreatePushNotificationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PushNotificationDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/push-notifications';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditPushNotificationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PushNotificationDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/push-notifications/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PushNotificationDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/push-notifications/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class QuickLinksService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      searchString?: string;
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<QuickLinkDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quick-links';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        searchString: params['searchString'],
        direction: params['direction']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateQuickLinkDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<QuickLinkDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quick-links';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProduct(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<QuickLinkDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quick-links/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditQuickLinkDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<QuickLinkDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quick-links/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quick-links/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ReportsService {
  /**
   *
   */
  static memberChangeReport(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MemberChangeReportDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reports/member-change-report';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberChurnReport(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MemberChurnReportDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reports/member-churn-report';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static activeMemberReport(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActiveMemberReportDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reports/active-member-report';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static activeMembershipReport(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActiveMembershipReportDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reports/active-membership-report';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberLengthOfEngagementReport(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MemberLengthOfEngagementReportDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reports/member-length-of-engagement-report';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static averageRevenuePerMemberReport(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AverageRevenuePerMemberReportDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reports/average-revenue-per-member-report';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class RetentionIssuesService {
  /**
   *
   */
  static findMembersWithRetentionIssues(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
      /**  */
      search?: string;
      /**  */
      periodFrom?: string;
      /**  */
      periodTo?: string;
      /**  */
      maxNumberOfAttendances?: number;
      /**  */
      minNumberOfAttendances?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembersWithRetentionIssuesDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/retention-issues';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction'],
        search: params['search'],
        periodFrom: params['periodFrom'],
        periodTo: params['periodTo'],
        maxNumberOfAttendances: params['maxNumberOfAttendances'],
        minNumberOfAttendances: params['minNumberOfAttendances']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class RolesService {
  /**
   *
   */
  static index(options: IRequestOptions = {}): Promise<RoleDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/roles';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class SearchService {
  /**
   *
   */
  static search(
    params: {
      /**  */
      searchString?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SearchResultDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/search';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { searchString: params['searchString'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class SetupService {
  /**
   *
   */
  static index(
    params: {
      /**  */
      paymentProvider?: ExternalSystemProviders;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/setup';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { paymentProvider: params['paymentProvider'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ShiftsService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ShiftDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/shifts';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateShiftDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ShiftDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/shifts';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ShiftDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/shifts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditShiftDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ShiftDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/shifts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static remove(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ShiftDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/shifts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class SignUpsService {
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateSignUpDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SignUpDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sign-ups/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateSignUpDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sign-ups';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static index(
    params: {
      /**  */
      classId?: number;
      /**  */
      accountId?: number;
      /**  */
      openGymOnly?: boolean;
      /**  */
      from?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
      /**  */
      search?: string;
      /**  */
      selectedGymId?: number;
      /**  */
      status?: ParticipationStatus;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SignUpDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sign-ups';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        classId: params['classId'],
        accountId: params['accountId'],
        openGymOnly: params['openGymOnly'],
        from: params['from'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction'],
        search: params['search'],
        selectedGymId: params['selectedGymId'],
        status: params['status']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class StatisticsService {
  /**
   *
   */
  static memberRetention(
    params: {
      /**  */
      numberOfMonths?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChartDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/members/retention';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { numberOfMonths: params['numberOfMonths'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static citiesStatistics(
    params: {
      /**  */
      count?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityStatsDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/members/cities';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { count: params['count'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberStatistics(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MemberStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/members/total';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accountBalance(options: IRequestOptions = {}): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/members/balance';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leg(options: IRequestOptions = {}): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/members/leg';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static lifeTime(options: IRequestOptions = {}): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/members/life-time';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static membershipCategories(
    params: {
      /**  */
      numberOfMonths?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/memberships/categories';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { numberOfMonths: params['numberOfMonths'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static membershipTypeDistributionStatistics(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChartDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/memberships/type-distribution';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static membershipRetention(
    params: {
      /**  */
      numberOfMonths?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChartDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/memberships/retention';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { numberOfMonths: params['numberOfMonths'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static membershipGenders(options: IRequestOptions = {}): Promise<ChartDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/memberships/genders';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static membershipDistributionStatistics(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      validOn?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PieChartDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/memberships/distribution';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], validOn: params['validOn'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static membershipDistributionMonthlyStatistics(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PieChartDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/memberships/distribution-monthly';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static membershipCountStatistics(
    params: {
      /**  */
      numberOfMonths?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChartDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/memberships/count';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { numberOfMonths: params['numberOfMonths'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static membershipStatistics(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MemberStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/memberships/total';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static grossExpense(options: IRequestOptions = {}): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/expenses/gross';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static expenseCategories(
    params: {
      /**  */
      numberOfMonths?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/expenses/categories';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { numberOfMonths: params['numberOfMonths'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static revenueMembershipCategories(
    params: {
      /**  */
      numberOfMonths?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/revenue/membership-categories';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { numberOfMonths: params['numberOfMonths'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static revenueMembershipTypes(
    params: {
      /**  */
      membershipTypeIds?: any | null[];
      /**  */
      numberOfMonths?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChartStatisticsDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/revenue/membership-types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { membershipTypeIds: params['membershipTypeIds'], numberOfMonths: params['numberOfMonths'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static revenueProducts(
    params: {
      /**  */
      productCategoryIds?: any | null[];
      /**  */
      numberOfMonths?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChartStatisticsDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/revenue/products';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { productCategoryIds: params['productCategoryIds'], numberOfMonths: params['numberOfMonths'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static gross(options: IRequestOptions = {}): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/revenue/gross';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static mrr(
    params: {
      /**  */
      monthsBackInTime?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/revenue/mrr';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { monthsBackInTime: params['monthsBackInTime'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static arm(options: IRequestOptions = {}): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/revenue/arm';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rps(
    params: {
      /**  */
      monthsBackInTime?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/revenue/rps';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { monthsBackInTime: params['monthsBackInTime'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rms(options: IRequestOptions = {}): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/revenue/rms';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rcs(options: IRequestOptions = {}): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/revenue/rcs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rpcs(options: IRequestOptions = {}): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/revenue/rpcs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rpts(options: IRequestOptions = {}): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/revenue/rpts';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static lrmp(options: IRequestOptions = {}): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/revenue/lrmp';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rf(options: IRequestOptions = {}): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/revenue/rf';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static clv(options: IRequestOptions = {}): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/revenue/clv';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static trainingTotalStatistics(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/classes/total';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static currentRegistrationsStatistics(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/classes/current-registrations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static classAvailabilityStatistics(
    params: {
      /**  */
      numberOfMonths?: number;
      /**  */
      classDescriptionId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/classes/availability';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { numberOfMonths: params['numberOfMonths'], classDescriptionId: params['classDescriptionId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static signUpStatistics(
    params: {
      /**  */
      status?: ParticipationStatus;
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MemberStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/classes/registrations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { status: params['status'], fromDate: params['fromDate'], toDate: params['toDate'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static fillRateStatistics(options: IRequestOptions = {}): Promise<ClassSignUpsDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/classes/fill-rates';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static checkinTimesStatistics(options: IRequestOptions = {}): Promise<OptOutTimesDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/classes/checkin-times';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancellationTimesStatistics(options: IRequestOptions = {}): Promise<OptOutTimesDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/classes/opt-out-times';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static classParticipationStatistics(
    params: {
      /**  */
      numberOfMonths?: number;
      /**  */
      groupBy?: ChartPeriodGroups;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChartDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/classes/participations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { numberOfMonths: params['numberOfMonths'], groupBy: params['groupBy'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static classPopularityStatistics(
    params: {
      /**  */
      numberOfMonths?: number;
      /**  */
      groupBy?: ChartPeriodGroups;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChartDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/classes/popularity';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { numberOfMonths: params['numberOfMonths'], groupBy: params['groupBy'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static salesTotalBalanceStatistics(options: IRequestOptions = {}): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/sales/total-balance';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static productSoldStatistics(options: IRequestOptions = {}): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/sales/product-sold';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static bestSalesManStatistics(options: IRequestOptions = {}): Promise<TopSalesmanDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/sales/best-salesman';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static saleByGenderStatistics(options: IRequestOptions = {}): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/sales/by-gender';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static salesProfitStatistics(options: IRequestOptions = {}): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/sales/profit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static salesYearlyStatistics(
    params: {
      /**  */
      year?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<YearlySalesDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/sales/yearly';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { year: params['year'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static clvComparison(options: IRequestOptions = {}): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/comparison/clv';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static armComparison(options: IRequestOptions = {}): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/comparison/arm';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static membershipPriceComparison(options: IRequestOptions = {}): Promise<WidgetStatisticsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/statistics/comparison/membership-prices';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class StripeService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: StripeWebhookEventDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/stripe';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static webhook(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/stripe/webhook';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static webhookConnect(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/stripe/webhook-connect';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class StripeConnectService {
  /**
   *
   */
  static create(options: IRequestOptions = {}): Promise<StripeConnectLinkDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/stripe-connect';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reAuth(options: IRequestOptions = {}): Promise<StripeConnectLinkDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/stripe-connect';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class TicketsService {
  /**
   *
   */
  static cancelTicket(
    params: {
      /**  */
      ticketId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/tickets/{ticketId}';
      url = url.replace('{ticketId}', params['ticketId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class VouchersService {
  /**
   *
   */
  static details(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VoucherDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vouchers/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateMembershipDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VoucherDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vouchers/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateVoucherMembershipDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VoucherDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vouchers';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class VoucherTypesService {
  /**
   *
   */
  static index(
    params: {
      /**  */
      validFrom?: string;
      /**  */
      validTo?: string;
      /**  */
      validOn?: string;
      /**  */
      availableFrom?: string;
      /**  */
      isActive?: boolean;
      /**  */
      isPublic?: boolean;
      /**  */
      search?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VoucherTypeDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/voucher-types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        validFrom: params['validFrom'],
        validTo: params['validTo'],
        validOn: params['validOn'],
        availableFrom: params['availableFrom'],
        isActive: params['isActive'],
        isPublic: params['isPublic'],
        search: params['search'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateVoucherTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VoucherTypeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/voucher-types';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static details(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VoucherTypeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/voucher-types/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditVoucherTypeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VoucherTypeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/voucher-types/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/voucher-types/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createPurchaseLink(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PurchaseLinkDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/voucher-types/{id}/purchase-link';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class WageRatesService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HourlyWageRateDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/wage-rates';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateHourlyWageRateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HourlyWageRateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/wage-rates';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HourlyWageRateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/wage-rates/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditHourlyWageRateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HourlyWageRateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/wage-rates/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HourlyWageRateDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/wage-rates/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class WagesService {
  /**
   *
   */
  static get(
    params: {
      /**  */
      fromDate?: DateTime;
      /**  */
      toDate?: DateTime;
      /**  */
      employeeIds?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/wages';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { fromDate: params['fromDate'], toDate: params['toDate'], employeeIds: params['employeeIds'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class WebhookService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: StripeWebhookEventDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/webhooks';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static mailgunEvent(
    params: {
      /** requestBody */
      body?: MailgunWebhookEventDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/webhooks/mailgun';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reepayWebhookConnect(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/webhooks/reepay-connect';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class WorkoutsService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateWorkoutDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkoutDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/workouts';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditWorkoutDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkoutDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/workouts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static findById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkoutDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/workouts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/workouts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class WorkoutTypesService {
  /**
   *
   */
  static findAll(options: IRequestOptions = {}): Promise<WorkoutTypeDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/workout-types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export interface AccountCreditBalanceEntryDto {
  /**  */
  id?: number;

  /**  */
  accountId?: number;

  /**  */
  adjustmentType?: AdjustmentType;

  /**  */
  amount?: number;

  /**  */
  currency?: string;

  /**  */
  note?: string;

  /**  */
  createdDate?: DateTime;
}

export interface AccountDto {
  /**  */
  id?: number;

  /**  */
  displayName?: string;

  /**  */
  fullName?: string;

  /**  */
  photoURL?: string;

  /**  */
  profileImageUrl?: string;

  /**  */
  userRole?: string;

  /**  */
  email?: string;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  dateOfBirth?: DateTime;

  /**  */
  phoneNumber?: string;

  /**  */
  gender?: Gender;

  /**  */
  address?: AddressDto;

  /**  */
  comment?: string;

  /**  */
  lockoutEnd?: DateTime;

  /**  */
  subAccounts?: AccountDto[];

  /**  */
  primaryAccount?: AccountDto;

  /**  */
  payerEmail?: string;

  /**  */
  externalLink?: string;

  /**  */
  roles?: string[];

  /**  */
  hasDoorSystemUser?: boolean;

  /**  */
  tenantId?: number;

  /**  */
  vatNo?: string;

  /**  */
  calendarId?: string;

  /**  */
  calendarAccessKey?: string;

  /**  */
  calendarLink?: string;
}

export interface ActiveMemberDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  profileImageUrl?: string;

  /**  */
  numberOfMemberships?: number;

  /**  */
  sumOfMemberships?: number;

  /**  */
  currency?: string;
}

export interface ActiveMemberReportDto {
  /**  */
  averageNumberOfMembershipsPerMember?: number;

  /**  */
  averageSumOfMembershipsPerMember?: number;

  /**  */
  currency?: string;

  /**  */
  members?: ActiveMemberDto[];
}

export interface ActiveMembershipDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  ownerName?: string;

  /**  */
  ownerEmail?: string;

  /**  */
  ownerPhoneNumber?: string;

  /**  */
  ownerProfileImageUrl?: string;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;
}

export interface ActiveMembershipReportDto {
  /**  */
  averagePriceOfMembershipsPerMember?: number;

  /**  */
  currency?: string;

  /**  */
  memberships?: ActiveMembershipDto[];
}

export interface ActivityDto {
  /**  */
  id?: number;

  /**  */
  title?: string;

  /**  */
  description?: string;

  /**  */
  starts?: DateTime;

  /**  */
  link?: string;
}

export interface AddPaymentProvider {
  /**  */
  paymentProvider?: ExternalSystemProviders;

  /**  */
  apiKey?: string;
}

export interface AddPersonalTrainerToMembershipDto {
  /**  */
  membershipId?: number;

  /**  */
  personalTrainerId?: number;
}

export interface AddressDto {
  /**  */
  id?: number;

  /**  */
  addressLine?: string;

  /**  */
  city?: string;

  /**  */
  postalNumber?: string;

  /**  */
  country?: string;

  /**  */
  latitude?: number;

  /**  */
  longitude?: number;

  /**  */
  countryCode?: string;
}

export interface AdjustCreditBalanceDto {
  /**  */
  accountId?: number;

  /**  */
  adjustmentType?: AdjustmentType;

  /**  */
  amount?: number;

  /**  */
  currency?: string;

  /**  */
  note?: string;

  /**  */
  gymId?: number;
}

export interface AlternatingPlanningType {
  /**  */
  weeks?: WeekType[];
}

export interface ApiResponse {
  /**  */
  version?: string;

  /**  */
  statusCode?: number;

  /**  */
  message?: string;

  /**  */
  isError?: boolean;

  /**  */
  responseException?: any | null;

  /**  */
  result?: any | null;
}

export interface AuditLogEntryDto {
  /**  */
  id?: number;

  /**  */
  userId?: string;

  /**  */
  type?: string;

  /**  */
  tableName?: string;

  /**  */
  dateTime?: DateTime;

  /**  */
  oldValues?: string;

  /**  */
  newValues?: string;

  /**  */
  affectedColumns?: string;

  /**  */
  primaryKey?: string;
}

export interface AuthenticationDto {
  /**  */
  email?: string;

  /**  */
  password?: string;
}

export interface AuthorizationDto {
  /**  */
  accessToken?: string;
}

export interface AverageRevenuePerMemberDto {
  /**  */
  ownerId?: number;

  /**  */
  ownerName?: string;

  /**  */
  ownerEmail?: string;

  /**  */
  ownerPhoneNumber?: string;

  /**  */
  ownerProfileImageUrl?: string;

  /**  */
  sum?: number;

  /**  */
  currency?: string;

  /**  */
  numberOfInvoices?: number;
}

export interface AverageRevenuePerMemberReportDto {
  /**  */
  averageSumPerMember?: number;

  /**  */
  currency?: string;

  /**  */
  averageNumberOfInvoicesPerMember?: number;

  /**  */
  members?: AverageRevenuePerMemberDto[];
}

export interface CampaignMembershipTypeDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  shortDescription?: string;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  contractId?: number;

  /**  */
  taxRateId?: number;

  /**  */
  taxRate?: number;

  /**  */
  taxRateInclusive?: boolean;

  /**  */
  setupFee?: number;

  /**  */
  billable?: BillableType;

  /**  */
  chainId?: number;

  /**  */
  hasSetupFee?: boolean;

  /**  */
  numberOfSeats?: number;

  /**  */
  hasSeatsLeft?: boolean;

  /**  */
  membershipExpiresAfterNumberOfDays?: number;

  /**  */
  membershipExpiresAfterNumberOfMonths?: number;

  /**  */
  openingHours?: OpeningHour[];

  /**  */
  gymId?: number;

  /**  */
  membershipExpiresAt?: DateTime;

  /**  */
  expiresAt?: DateTime;

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  convertsToMembershipTypeId?: number;

  /**  */
  welcomeEmailTemplateId?: number;

  /**  */
  membershipUsableFrom?: DateTime;

  /**  */
  hasAccessToClassIds?: number[];

  /**  */
  automaticClassRegistrationForClassIds?: number[];

  /**  */
  prices?: MembershipTypePriceDto[];

  /**  */
  isPublic?: boolean;

  /**  */
  price?: Money;

  /**  */
  accountNumber?: number;

  /**  */
  maximumNumberOfActiveClassRegistrations?: number;

  /**  */
  bookingInAdvanceAllowedForDays?: number;

  /**  */
  createdDate?: DateTime;

  /**  */
  numberOfAllowedTrainingsPerPeriod?: number;

  /**  */
  hasAccessToDoorSystem?: boolean;

  /**  */
  updatePriceOnExistingMemberships?: boolean;
}

export interface CancelMembershipDto {
  /**  */
  id?: number;

  /**  */
  validTo?: DateTime;

  /**  */
  enableEmailSending?: boolean;

  /**  */
  saveExcessPayment?: boolean;
}

export interface CandlestickDataPointDto {
  /**  */
  open?: number;

  /**  */
  close?: number;

  /**  */
  high?: number;

  /**  */
  low?: number;

  /**  */
  x?: any | null;
}

export interface CandlestickDto {
  /**  */
  average?: number;

  /**  */
  median?: number;

  /**  */
  series?: CandlestickDataPointDto[];
}

export interface ChainDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  vatNo?: string;

  /**  */
  billingAddressId?: number;

  /**  */
  billingAddress?: AddressDto;

  /**  */
  logoUrl?: string;

  /**  */
  chainSettingsId?: number;

  /**  */
  domain?: string;

  /**  */
  domainOfPresentation?: string;

  /**  */
  subscriptionType?: SubscriptionType;
}

export interface ChainSettingsDto {
  /**  */
  id?: number;

  /**  */
  chainId?: number;

  /**  */
  chainName?: string;

  /**  */
  vatNo?: string;

  /**  */
  staffMembershipId?: number;

  /**  */
  isFineEnabledWhenTooLate?: boolean;

  /**  */
  fineWhenTooLateAmount?: number;

  /**  */
  isFineEnabledWhenNeverMet?: boolean;

  /**  */
  fineWhenNeverMetAmount?: number;

  /**  */
  setupFeeAmount?: number;

  /**  */
  defaultTaxRate?: number;

  /**  */
  isCheckInTooLateIntervalEnabled?: boolean;

  /**  */
  deleteShiftsWhenCancellingClasses?: boolean;

  /**  */
  checkInTooLateInterval?: DateTime;

  /**  */
  isCheckInIntervalEnabled?: boolean;

  /**  */
  checkInInterval?: DateTime;

  /**  */
  isOptOutTooLateIntervalEnabled?: boolean;

  /**  */
  optOutTooLateInvalidInterval?: DateTime;

  /**  */
  optOutCutoffInterval?: DateTime;

  /**  */
  isReservationInvalidIntervalEnabled?: boolean;

  /**  */
  reservationInvalidInterval?: DateTime;

  /**  */
  isLateCheckInAllowed?: boolean;

  /**  */
  lateCheckInInterval?: DateTime;

  /**  */
  defaultCurrency?: string;

  /**  */
  noReplyEmailAddress?: string;

  /**  */
  maximumNumberOfActiveClassRegistrations?: number;

  /**  */
  limitClassesPerDay?: boolean;

  /**  */
  maximumNumberOfClassesPerDay?: number;

  /**  */
  isClassAdditionToEnabled?: boolean;

  /**  */
  checkinMessage?: string;

  /**  */
  hasOpenGym?: boolean;

  /**  */
  personalTrainingDescriptionId?: number;

  /**  */
  putOnHoldFeeAmount?: number;

  /**  */
  defaultPayrollSystem?: PayrollSystem;

  /**  */
  defaultPaymentProvider?: ExternalSystemProviders;

  /**  */
  useVoucherClipsForFines?: boolean;

  /**  */
  retentionEmailTemplateId?: number;

  /**  */
  needsSignatureOnContracts?: boolean;

  /**  */
  salesWithVat?: number;

  /**  */
  salesWithoutVat?: number;

  /**  */
  paymentProvider?: number;

  /**  */
  bank?: number;

  /**  */
  fees?: number;

  /**  */
  cash?: number;

  /**  */
  check?: number;

  /**  */
  mobilePay?: number;

  /**  */
  isFineEnabledWhenOptOutTooLate?: boolean;

  /**  */
  fineWhenOptOutTooLateAmount?: number;

  /**  */
  automaticCancellingOfClasses?: boolean;

  /**  */
  minimumNumberOfRegistrationsPerClass?: number;

  /**  */
  timeBeforeAutomaticCancellationCheck?: DateTime;

  /**  */
  bookingInAdvanceAllowedForDays?: number;

  /**  */
  maximumNumberOfActiveMemberships?: number;

  /**  */
  allowedNumberOfVisibleCalendarDaysForMembers?: number;

  /**  */
  isTrailTrainingFollowUpEnabled?: boolean;

  /**  */
  trailTrainingFollowUpEmailId?: number;

  /**  */
  isTrailTrainingWelcomeEnabled?: boolean;

  /**  */
  trailTrainingWelcomeEmailId?: number;

  /**  */
  defaultBookkeepingProvider?: BookkeepingProviders;

  /**  */
  externalProgramProvider?: ExternalProgramProviders;

  /**  */
  externalProgramProviderApiKey?: string;

  /**  */
  programAccessKey?: string;

  /**  */
  providesWorkoutPrograms?: boolean;

  /**  */
  isShowingEmployeePhoneNumberAllowed?: boolean;

  /**  */
  colorPrimary?: string;

  /**  */
  colorSecondary?: string;

  /**  */
  trackingCode?: string;

  /**  */
  trackingProvider?: TrackingProviders;

  /**  */
  accessSystemProvider?: AccessSystemProviders;

  /**  */
  accessSystemProviderEndpoint?: string;

  /**  */
  accessSystemProviderUsername?: string;

  /**  */
  accessSystemProviderPassword?: string;

  /**  */
  accessSystemAccessGroupId?: string;

  /**  */
  timeZone?: string;

  /**  */
  showAllClassesInApp?: boolean;

  /**  */
  hasReferralBonus?: boolean;

  /**  */
  referralBonus?: number;

  /**  */
  referralCandidateEmailId?: number;

  /**  */
  referralRefererEmailId?: number;

  /**  */
  transferPaymentProviderFeesToCustomers?: boolean;

  /**  */
  defaultPaymentGateway?: ExternalSystemProviders;

  /**  */
  logo?: FileDto;

  /**  */
  images?: string[];

  /**  */
  paymentProviderApiKey?: string;

  /**  */
  bankAccount?: string;

  /**  */
  bankAccountIban?: string;

  /**  */
  bankAccountSwift?: string;

  /**  */
  maximumNumberOfWaitListPerDay?: number;

  /**  */
  appStoreLink?: string;

  /**  */
  playStoreLink?: string;

  /**  */
  oneSignalAppId?: string;

  /**  */
  oneSignalApiKey?: string;

  /**  */
  domain?: string;

  /**  */
  notifyAboutNewMembers?: boolean;

  /**  */
  notifyAboutFines?: boolean;

  /**  */
  disableLeaderboard?: boolean;

  /**  */
  paymentFeeAmount?: number;

  /**  */
  mailProviderDomain?: string;

  /**  */
  mailProviderApiKey?: string;

  /**  */
  isFeedbackAllowed?: boolean;

  /**  */
  googleTagManagerTrackingCode?: string;

  /**  */
  requirePaymentMethodOnAccounts?: boolean;
  sendSmsWhenCancellingClasses?: boolean;
}

export interface ChartDto {
  /**  */
  labels?: string[];

  /**  */
  datasets?: DatasetDto[];
}

export interface ChartEntryDto {
  /**  */
  pointInTime?: DateTime;

  /**  */
  value?: number;

  /**  */
  unit?: string;

  /**  */
  key?: string;
}

export interface ChartStatisticsDto {
  /**  */
  entries?: ChartEntryDto[];

  /**  */
  value?: number;

  /**  */
  unit?: string;

  /**  */
  key?: string;
}

export interface ChatDto {
  /**  */
  id?: number;

  /**  */
  type?: string;

  /**  */
  name?: string;

  /**  */
  messages?: ChatMessageDto[];

  /**  */
  participants?: ChatParticipantDto[];

  /**  */
  createdDate?: DateTime;
}

export interface ChatMessageDto {
  /**  */
  id?: string;

  /**  */
  body?: string;

  /**  */
  createdDate?: DateTime;

  /**  */
  senderId?: number;

  /**  */
  contentType?: string;

  /**  */
  avatarUrl?: string;

  /**  */
  fullName?: string;

  /**  */
  chatId?: number;

  /**  */
  type?: MessageType;

  /**  */
  fileId?: number;

  /**  */
  file?: FileDto;
}

export interface ChatParticipantDto {
  /**  */
  id?: number;

  /**  */
  fullName?: string;

  /**  */
  role?: string;

  /**  */
  email?: string;

  /**  */
  avatarUrl?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  lastActivity?: DateTime;

  /**  */
  status?: string;
}

export interface CheckoutSessionDto {
  /**  */
  stripeCredential?: string;

  /**  */
  sessionId?: string;

  /**  */
  stripePubKey?: string;

  /**  */
  checkoutUrl?: string;

  /**  */
  paymentProvider?: ExternalSystemProviders;
}

export interface CityStatsDto {
  /**  */
  city?: string;

  /**  */
  countryCode?: string;

  /**  */
  count?: number;
}

export interface ClassDescriptionDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  tint?: string;

  /**  */
  chainId?: number;

  /**  */
  isOpenGym?: boolean;

  /**  */
  isPersonalTraining?: boolean;

  /**  */
  subtitle?: string;

  /**  */
  backgroundImageUrl?: string;

  /**  */
  externalProgrammingTrack?: string;

  /**  */
  images?: FileDto[];
}

export interface ClassFeedbackDto {
  /**  */
  id?: number;

  /**  */
  body?: string;

  /**  */
  classId?: number;

  /**  */
  classTitle?: string;

  /**  */
  rating?: number;

  /**  */
  overallRating?: number;

  /**  */
  coachPreparationRating?: number;

  /**  */
  coachObservationRating?: number;

  /**  */
  clarityOfInstructions?: number;

  /**  */
  logisticalRating?: number;

  /**  */
  feedbackHelpfulnessRating?: number;

  /**  */
  warmUpEffectivenessRating?: number;

  /**  */
  memberId?: number;

  /**  */
  memberName?: string;

  /**  */
  memberProfileImageUrl?: string;

  /**  */
  createdDate?: DateTime;
}

export interface ClassHourCalendarDto {
  /**  */
  title?: string;

  /**  */
  start?: DateTime;

  /**  */
  end?: DateTime;

  /**  */
  className?: string;

  /**  */
  color?: string;

  /**  */
  textColor?: string;

  /**  */
  extendedProps?: object;

  /**  */
  url?: string;
}

export interface ClassSignUpsDto {
  /**  */
  name?: string;

  /**  */
  signUps?: number;

  /**  */
  capacity?: number;
}

export interface ClassTemplateDto {
  /**  */
  id?: number;

  /**  */
  descriptionId?: number;

  /**  */
  weekDays?: DayOfWeek[];

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  begins?: DateTime;

  /**  */
  gymId?: number;

  /**  */
  locationId?: number;

  /**  */
  gymName?: string;

  /**  */
  subtitle?: string;

  /**  */
  title?: string;

  /**  */
  optOutTimeHours?: number;

  /**  */
  optOutTimeMinutes?: number;

  /**  */
  classDurationHours?: number;

  /**  */
  classDurationMinutes?: number;

  /**  */
  shiftDurationHours?: number;

  /**  */
  shiftDurationMinutes?: number;

  /**  */
  finesEnabled?: boolean;

  /**  */
  specificDays?: SpecificDayPlanningType;

  /**  */
  normal?: NormalPlanningType;

  /**  */
  alternating?: AlternatingPlanningType;

  /**  */
  type?: ClassTemplateTypes;

  /**  */
  maximumNumberOfParticipants?: number;

  /**  */
  minimumNumberOfRegistrationsPerClass?: number;

  /**  */
  closedClass?: boolean;

  /**  */
  isGuestBookingPossible?: boolean;

  /**  */
  images?: FileDto[];

  /**  */
  releaseProgram?: boolean;

  /**  */
  isOpenGym?: boolean;

  /**  */
  automaticCancellingOfClasses?: boolean;

  /**  */
  isPersonalTrainingOnly?: boolean;

  /**  */
  hoursBeforeProgramRelease?: number;

  /**  */
  minutesBeforeProgramRelease?: number;

  /**  */
  rating?: number;

  /**  */
  fillRate?: number;

  /**  */
  frequency?: Frequency;

  /**  */
  autoSignUpParticipantIds?: number[];
}

export interface ClazzDto {
  /**  */
  id?: number;

  /**  */
  descriptionId?: number;

  /**  */
  description?: ClassDescriptionDto;

  /**  */
  gym?: GymDto;

  /**  */
  gymName?: string;

  /**  */
  gymId?: number;

  /**  */
  instructors?: InstructorDto[];

  /**  */
  participants?: SignUpDto[];

  /**  */
  programId?: number;

  /**  */
  program?: ProgramDto;

  /**  */
  begins?: DateTime;

  /**  */
  ends?: DateTime;

  /**  */
  title?: string;

  /**  */
  subtitle?: string;

  /**  */
  capacity?: number;

  /**  */
  isOpenGym?: boolean;

  /**  */
  tint?: string;

  /**  */
  finesEnabled?: boolean;

  /**  */
  closedClass?: boolean;

  /**  */
  releaseProgram?: boolean;

  /**  */
  timeBeforeProgramRelease?: DateTime;

  /**  */
  hoursBeforeProgramRelease?: number;

  /**  */
  minutesBeforeProgramRelease?: number;

  /**  */
  optOutTimeHours?: number;

  /**  */
  optOutTimeMinutes?: number;

  /**  */
  classDurationHours?: number;

  /**  */
  classDurationMinutes?: number;

  /**  */
  shiftDurationHours?: number;

  /**  */
  shiftDurationMinutes?: number;

  /**  */
  backgroundImageUrl?: string;

  /**  */
  isGuestBookingPossible?: boolean;
}

export interface CommentDto {
  /**  */
  id?: number;

  /**  */
  title?: string;

  /**  */
  body?: string;

  /**  */
  createdDate?: DateTime;

  /**  */
  creatorId?: number;

  /**  */
  creatorName?: string;

  /**  */
  creatorProfileImage?: string;
}

export interface ContingentTypeDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  shortDescription?: string;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  contractId?: number;

  /**  */
  taxRateId?: number;

  /**  */
  taxRate?: number;

  /**  */
  taxRateInclusive?: boolean;

  /**  */
  setupFee?: number;

  /**  */
  price?: Money;

  /**  */
  billable?: BillableType;

  /**  */
  chainId?: number;

  /**  */
  hasSetupFee?: boolean;

  /**  */
  numberOfSeats?: number;

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  welcomeEmailTemplateId?: number;

  /**  */
  cancellationEmailTemplateId?: number;

  /**  */
  onHoldEmailTemplateId?: number;

  /**  */
  hasAccessToClassIds?: number[];

  /**  */
  automaticClassRegistrationForClassIds?: number[];

  /**  */
  prices?: MembershipTypePriceDto[];

  /**  */
  openingHours?: OpeningHour[];

  /**  */
  gymId?: number;

  /**  */
  isPublic?: boolean;

  /**  */
  numberOfAllowedTrainingsPerPeriod?: number;

  /**  */
  automaticRenewalDisabled?: boolean;

  /**  */
  accountNumber?: number;

  /**  */
  maximumNumberOfActiveClassRegistrations?: number;

  /**  */
  bookingInAdvanceAllowedForDays?: number;

  /**  */
  hasAccessToDoorSystem?: boolean;

  /**  */
  updatePriceOnExistingMemberships?: boolean;

  /**  */
  membershipExpiresAfterNumberOfDays?: number;

  /**  */
  createdDate?: DateTime;
}

export interface ContractDto {
  /**  */
  id?: number;

  /**  */
  createdById?: string;

  /**  */
  createdDate?: DateTime;

  /**  */
  lastModifiedDate?: DateTime;

  /**  */
  modifiedById?: string;

  /**  */
  chainId?: number;

  /**  */
  body?: string;

  /**  */
  name?: string;
}

export interface CreateContractDto {
  /**  */
  id?: number;

  /**  */
  createdById?: string;

  /**  */
  createdDate?: DateTime;

  /**  */
  lastModifiedDate?: DateTime;

  /**  */
  modifiedById?: string;

  /**  */
  chainId?: number;

  /**  */
  body?: string;

  /**  */
  name?: string;
}

export interface EditContractDto {
  /**  */
  id?: number;

  /**  */
  createdById?: string;

  /**  */
  createdDate?: DateTime;

  /**  */
  lastModifiedDate?: DateTime;

  /**  */
  modifiedById?: string;

  /**  */
  chainId?: number;

  /**  */
  body?: string;

  /**  */
  name?: string;
  requireNewSignature?: boolean;
}

export interface CreateBusinessAccountDto {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  companyName?: string;

  /**  */
  vatNo?: string;

  /**  */
  isTermsConditionAccepted?: boolean;

  /**  */
  plan?: SubscriptionType;

  /**  */
  homePage?: string;
}

export interface CreateCampaignMembershipDto {
  /**  */
  validFrom?: DateTime;

  /**  */
  membershipTypeId?: number;

  /**  */
  memberId?: number;

  /**  */
  withFee?: boolean;

  /**  */
  isOneTimeDiscount?: boolean;

  /**  */
  discountAmount?: number;

  /**  */
  discountPercentage?: number;

  /**  */
  sendWelcomeMail?: boolean;

  /**  */
  discountCode?: string;

  /**  */
  useFirstOfMonthAsAnchor?: boolean;

  /**  */
  priceAmount?: number;
}

export interface CreateCampaignMembershipTypeDto {
  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  billable?: BillableType;

  /**  */
  hasAccessToDoorSystem?: boolean;

  /**  */
  maximumNumberOfActiveClassRegistrations?: number;

  /**  */
  bookingInAdvanceAllowedForDays?: number;

  /**  */
  taxRate?: number;

  /**  */
  taxRateInclusive?: boolean;

  /**  */
  numberOfSeats?: number;

  /**  */
  setupFee?: number;

  /**  */
  openingHours?: OpeningHour[];

  /**  */
  availableInGyms?: number[];

  /**  */
  membershipExpiresAfterNumberOfDays?: number;

  /**  */
  membershipExpiresAfterNumberOfMonths?: number;

  /**  */
  membershipExpiresAt?: DateTime;

  /**  */
  membershipUsableFrom?: DateTime;

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  convertsToMembershipTypeId?: number;

  /**  */
  contractId?: number;

  /**  */
  welcomeEmailTemplateId?: number;

  /**  */
  hasSetupFee?: boolean;

  /**  */
  hasAccessToClassIds?: number[];

  /**  */
  automaticClassRegistrationForClassIds?: number[];

  /**  */
  isPublic?: boolean;

  /**  */
  accountNumber?: number;

  /**  */
  shortDescription?: string;

  /**  */
  numberOfAllowedTrainingsPerPeriod?: number;
}

export interface CreateChatDto {
  /**  */
  type?: string;

  /**  */
  name?: string;

  /**  */
  messages?: ChatMessageDto[];

  /**  */
  participants?: ChatParticipantDto[];
}

export interface CreateClassDescriptionDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  tint?: string;

  /**  */
  additionToIds?: number[];

  /**  */
  externalProgrammingTrack?: string;
}

export interface CreateClassDto {
  /**  */
  descriptionId?: number;

  /**  */
  begins?: DateTime;

  /**  */
  ends?: DateTime;

  /**  */
  coaches?: number[];

  /**  */
  participantIds?: number[];

  /**  */
  gymId?: number;

  /**  */
  finesEnabled?: boolean;

  /**  */
  closedClass?: boolean;

  /**  */
  subtitle?: string;

  /**  */
  title?: string;

  /**  */
  isGuestBookingPossible?: boolean;

  /**  */
  maximumNumberOfParticipants?: number;

  /**  */
  releaseProgram?: boolean;

  /**  */
  hoursBeforeProgramRelease?: number;

  /**  */
  minutesBeforeProgramRelease?: number;

  /**  */
  isPersonalTrainingOnly?: boolean;

  /**  */
  numberOfRepetitions?: number;

  /**  */
  shiftDurationHours?: number;

  /**  */
  shiftDurationMinutes?: number;

  /**  */
  isOpenGym?: boolean;
}

export interface CreateClassTemplateDto {
  /**  */
  id?: number;

  /**  */
  descriptionId?: number;

  /**  */
  weekDays?: DayOfWeek[];

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  begins?: DateTime;

  /**  */
  gymId?: number;

  /**  */
  locationId?: number;

  /**  */
  gymName?: string;

  /**  */
  subtitle?: string;

  /**  */
  title?: string;

  /**  */
  optOutTimeHours?: number;

  /**  */
  optOutTimeMinutes?: number;

  /**  */
  classDurationHours?: number;

  /**  */
  classDurationMinutes?: number;

  /**  */
  shiftDurationHours?: number;

  /**  */
  shiftDurationMinutes?: number;

  /**  */
  finesEnabled?: boolean;

  /**  */
  specificDays?: SpecificDayPlanningType;

  /**  */
  normal?: NormalPlanningType;

  /**  */
  alternating?: AlternatingPlanningType;

  /**  */
  type?: ClassTemplateTypes;

  /**  */
  maximumNumberOfParticipants?: number;

  /**  */
  closedClass?: boolean;

  /**  */
  isGuestBookingPossible?: boolean;

  /**  */
  images?: FileDto[];

  /**  */
  releaseProgram?: boolean;

  /**  */
  isOpenGym?: boolean;

  /**  */
  automaticCancellingOfClasses?: boolean;

  /**  */
  isPersonalTrainingOnly?: boolean;

  /**  */
  hoursBeforeProgramRelease?: number;

  /**  */
  minutesBeforeProgramRelease?: number;

  /**  */
  frequency?: Frequency;

  /**  */
  autoSignUpParticipantIds?: number[];

  /**  */
  minimumNumberOfRegistrationsPerClass?: number;
}

export interface CreateCommentDto {
  /**  */
  title?: string;

  /**  */
  body?: string;

  /**  */
  fileData?: string;
}

export interface CreateContingentMembershipDto {
  /**  */
  validFrom?: DateTime;

  /**  */
  membershipTypeId?: number;

  /**  */
  memberId?: number;

  /**  */
  withFee?: boolean;

  /**  */
  isOneTimeDiscount?: boolean;

  /**  */
  discountAmount?: number;

  /**  */
  discountPercentage?: number;

  /**  */
  sendWelcomeMail?: boolean;

  /**  */
  discountCode?: string;

  /**  */
  useFirstOfMonthAsAnchor?: boolean;

  /**  */
  priceAmount?: number;
}

export interface CreateContingentTypeDto {
  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  billable?: BillableType;

  /**  */
  taxRate?: number;

  /**  */
  taxRateInclusive?: boolean;

  /**  */
  numberOfSeats?: number;

  /**  */
  contractId?: number;

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  onlySoldInPerson?: boolean;

  /**  */
  welcomeEmailTemplateId?: number;

  /**  */
  cancellationEmailTemplateId?: number;

  /**  */
  onHoldEmailTemplateId?: number;

  /**  */
  hasSetupFee?: boolean;

  /**  */
  hasAccessToClassIds?: number[];

  /**  */
  automaticClassRegistrationForClassIds?: number[];

  /**  */
  isPublic?: boolean;

  /**  */
  numberOfAllowedTrainingsPerPeriod?: number;

  /**  */
  automaticRenewalDisabled?: boolean;

  /**  */
  accountNumber?: number;

  /**  */
  maximumNumberOfActiveClassRegistrations?: number;

  /**  */
  bookingInAdvanceAllowedForDays?: number;

  /**  */
  hasAccessToDoorSystem?: boolean;

  /**  */
  setupFee?: number;

  /**  */
  openingHours?: OpeningHour[];

  /**  */
  availableInGyms?: number[];

  /**  */
  membershipExpiresAfterNumberOfDays?: number;

  /**  */
  shortDescription?: string;
}

export interface CreateDiscountVoucherDto {
  /**  */
  code?: string;

  /**  */
  clips?: number;

  /**  */
  name?: string;

  /**  */
  discountAmount?: number;

  /**  */
  discountCurrency?: string;

  /**  */
  discountPercentage?: number;

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  isOneTimeUse?: boolean;

  /**  */
  availableInGyms?: number[];

  /**  */
  availableForMembershipTypes?: number[];
}

export interface CreateDoorSystemIntegrationDto {
  /**  */
  system?: ExternalSystemProviders;
}

export interface CreateDropInMembershipDto {
  /**  */
  validFrom?: DateTime;

  /**  */
  membershipTypeId?: number;

  /**  */
  memberId?: number;

  /**  */
  withFee?: boolean;

  /**  */
  isOneTimeDiscount?: boolean;

  /**  */
  discountAmount?: number;

  /**  */
  discountPercentage?: number;

  /**  */
  sendWelcomeMail?: boolean;

  /**  */
  discountCode?: string;

  /**  */
  useFirstOfMonthAsAnchor?: boolean;

  /**  */
  priceAmount?: number;

  /**  */
  hasAutomaticRenewal?: boolean;

  /**  */
  classId?: number;
}

export interface CreateDropInTypeDto {
  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  numberOfClips?: number;

  /**  */
  validityInDays?: number;

  /**  */
  taxRate?: number;

  /**  */
  taxRateInclusive?: boolean;

  /**  */
  welcomeEmailTemplateId?: number;

  /**  */
  contractId?: number;

  /**  */
  hasSetupFee?: boolean;

  /**  */
  isPublic?: boolean;

  /**  */
  hasAccessToClassIds?: number[];

  /**  */
  automaticClassRegistrationForClassIds?: number[];

  /**  */
  accountNumber?: number;

  /**  */
  maximumNumberOfActiveClassRegistrations?: number;

  /**  */
  bookingInAdvanceAllowedForDays?: number;

  /**  */
  shortDescription?: string;

  /**  */
  hasAccessToDoorSystem?: boolean;

  /**  */
  setupFee?: number;

  /**  */
  openingHours?: OpeningHour[];

  /**  */
  availableInGyms?: number[];
}

export interface CreateEmployeeDto {
  /**  */
  email?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  middleName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  gender?: Gender;

  /**  */
  roles?: string[];

  /**  */
  title?: string;

  /**  */
  profileText?: string;

  /**  */
  dateOfBirth?: DateTime;

  /**  */
  tint?: string;

  /**  */
  addressLine?: string;

  /**  */
  city?: string;

  /**  */
  postalNumber?: string;

  /**  */
  country?: string;

  /**  */
  gymId?: number;
}

export interface CreateEventDto {
  /**  */
  title?: string;

  /**  */
  begins?: DateTime;

  /**  */
  ends?: DateTime;

  /**  */
  openForSignUpAt?: DateTime;

  /**  */
  closedForSignUpAt?: DateTime;

  /**  */
  ticketTypes?: CreateTicketTypeDto[];

  /**  */
  optionTypes?: CreateOptionTypeDto[];

  /**  */
  description?: string;

  /**  */
  shortDescription?: string;

  /**  */
  confirmationEmailId?: number;

  /**  */
  availableInGyms?: number[];

  /**  */
  accountNumber?: number;
}

export interface CreateGuestDto {
  /**  */
  email?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  middleName?: string;

  /**  */
  comment?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  classId?: number;

  /**  */
  gender?: Gender;

  /**  */
  dateOfBirth?: DateTime;

  /**  */
  addressLine?: string;

  /**  */
  city?: string;

  /**  */
  postalNumber?: string;

  /**  */
  country?: string;
}

export interface CreateGymDto {
  /**  */
  name?: string;

  /**  */
  addressLine?: string;

  /**  */
  city?: string;

  /**  */
  postalNumber?: string;

  /**  */
  country?: string;

  /**  */
  description?: string;

  /**  */
  shortDescription?: string;

  /**  */
  openingHours?: OpeningHour[];

  /**  */
  externalLockIds?: string[];

  /**  */
  vatNo?: string;

  /**  */
  bankAccount?: string;

  /**  */
  bankAccountIban?: string;

  /**  */
  bankAccountSwift?: string;

  /**  */
  isDebtCollectionAgreementSigned?: boolean;

  /**  */
  paymentProviderApiKey?: string;
}

export interface CreateHourlyWageRateDto {
  /**  */
  name?: string;

  /**  */
  payrollSystem?: PayrollSystem;

  /**  */
  externalId?: string;

  /**  */
  amount?: number;
}

export interface CreateInvoiceDto {
  /**  */
  gymId?: number;

  /**  */
  comment?: string;

  /**  */
  createdDate?: DateTime;

  /**  */
  dueDate?: DateTime;

  /**  */
  invoiceTo?: InvoiceAddressDto;

  /**  */
  invoiceFrom?: InvoiceAddressDto;

  /**  */
  lines?: CreateInvoiceItemDto[];

  /**  */
  isPaid?: boolean;

  /**  */
  status?: InvoiceStatuses;

  /**  */
  paymentMethod?: PaymentMethods;
}

export interface CreateInvoiceItemDto {
  /**  */
  productId?: number;

  /**  */
  name?: string;

  /**  */
  quantity?: number;

  /**  */
  priceAmount?: number;

  /**  */
  discount?: number;

  /**  */
  taxRate?: number;

  /**  */
  description?: string;
}

export interface CreateLocationDto {
  /**  */
  name?: string;

  /**  */
  gymId?: number;
}

export interface CreateMemberDto {
  /**  */
  email?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  payerEmail?: string;

  /**  */
  middleName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  gender?: Gender;

  /**  */
  dateOfBirth?: DateTime;

  /**  */
  addressLine?: string;

  /**  */
  city?: string;

  /**  */
  postalNumber?: string;

  /**  */
  country?: string;

  /**  */
  comment?: string;

  /**  */
  vatNo?: string;
}

export interface CreateNewsDto {
  /**  */
  title?: string;

  /**  */
  subtitle?: string;

  /**  */
  teaser?: string;

  /**  */
  body?: string;

  /**  */
  json?: string;

  /**  */
  imageId?: number;

  /**  */
  publishedOn?: DateTime;

  /**  */
  tags?: string[];

  /**  */
  metaKeywords?: string[];

  /**  */
  metaTitle?: string;

  /**  */
  metaDescription?: string;

  /**  */
  availableInGyms?: number[];

  /**  */
  status?: NewsStatus;
}

export interface CreateNewsletterDto {
  /**  */
  html?: string;

  /**  */
  json?: string;

  /**  */
  css?: string;

  /**  */
  subject?: string;

  /**  */
  sendingTime?: DateTime;

  /**  */
  sendTo?: string[];

  /**  */
  tags?: string[];

  /**  */
  metaKeywords?: string[];

  /**  */
  metaTitle?: string;

  /**  */
  metaDescription?: string;

  /**  */
  teaser?: string;

  /**  */
  eventId?: number;

  /**  */
  membershipTypeId?: number;

  /**  */
  availableInGyms?: number[];
}

export interface CreateOptionTypeDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  type?: OptionTypeTypes;

  /**  */
  values?: string[];
}

export interface CreateOrderDto {
  /**  */
  customerId?: number;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  middleName?: string;

  /**  */
  email?: string;

  /**  */
  vatNo?: string;

  /**  */
  companyName?: string;

  /**  */
  city?: string;

  /**  */
  address?: string;

  /**  */
  addressLine?: string;

  /**  */
  postalNumber?: string;

  /**  */
  country?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  comment?: string;

  /**  */
  orderItems?: CreateOrderItemDto[];

  /**  */
  isPaid?: boolean;

  /**  */
  invoiceStatus?: InvoiceStatuses;

  /**  */
  paymentMethod?: PaymentMethods;
}

export interface CreateOrderItemDto {
  /**  */
  productId?: number;

  /**  */
  name?: string;

  /**  */
  quantity?: number;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  discount?: number;

  /**  */
  taxRateValue?: number;

  /**  */
  taxRateInclusive?: boolean;

  /**  */
  description?: string;
}

export interface CreatePersonalTrainingMembershipDto {
  /**  */
  validFrom?: DateTime;

  /**  */
  membershipTypeId?: number;

  /**  */
  memberId?: number;

  /**  */
  withFee?: boolean;

  /**  */
  isOneTimeDiscount?: boolean;

  /**  */
  discountAmount?: number;

  /**  */
  discountPercentage?: number;

  /**  */
  sendWelcomeMail?: boolean;

  /**  */
  discountCode?: string;

  /**  */
  useFirstOfMonthAsAnchor?: boolean;

  /**  */
  priceAmount?: number;
}

export interface CreatePersonalTrainingTypeDto {
  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  billable?: BillableType;

  /**  */
  taxRate?: number;

  /**  */
  taxRateInclusive?: boolean;

  /**  */
  numberOfSeats?: number;

  /**  */
  contractId?: number;

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  onlySoldInPerson?: boolean;

  /**  */
  welcomeEmailTemplateId?: number;

  /**  */
  cancellationEmailTemplateId?: number;

  /**  */
  onHoldEmailTemplateId?: number;

  /**  */
  hasSetupFee?: boolean;

  /**  */
  hasAccessToClassIds?: number[];

  /**  */
  automaticClassRegistrationForClassIds?: number[];

  /**  */
  isPublic?: boolean;

  /**  */
  numberOfAllowedTrainingsPerPeriod?: number;

  /**  */
  automaticRenewalDisabled?: boolean;

  /**  */
  accountNumber?: number;

  /**  */
  shortDescription?: string;

  /**  */
  hasAccessToDoorSystem?: boolean;

  /**  */
  setupFee?: number;

  /**  */
  openingHours?: OpeningHour[];

  /**  */
  availableInGyms?: number[];

  /**  */
  numberOfClips?: number;
}

export interface CreateProductDto {
  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  quantity?: number;

  /**  */
  taxRate?: number;

  /**  */
  taxRateInclusive?: boolean;

  /**  */
  priceAmount?: number;

  /**  */
  categoryId?: number;

  /**  */
  accountNumber?: number;

  /**  */
  code?: string;

  /**  */
  sku?: string;

  /**  */
  shortDescription?: string;

  /**  */
  saleLabel?: string;

  /**  */
  newLabel?: string;

  /**  */
  priceSale?: number;

  /**  */
  priceCost?: number;

  /**  */
  tags?: string[];

  /**  */
  availableInGyms?: number[];

  /**  */
  isAvailableInShop?: boolean;

  /**  */
  ordering?: number;
}

export interface CreateProgramDto {
  /**  */
  date?: DateTime;

  /**  */
  classIds?: number[];

  /**  */
  workouts?: CreateWorkoutDto[];

  /**  */
  externalProgrammingTrack?: string;

  /**  */
  coachesNotes?: string;
}

export interface CreateProgramFromTemplateDto {
  /**  */
  programTemplateId?: number;

  /**  */
  fromDate?: DateTime;

  /**  */
  daysOfWeek?: DayOfWeek[];

  /**  */
  classDescriptionIds?: number[];
}

export interface CreateProgramTemplateDto {
  /**  */
  name?: string;

  /**  */
  path?: string;

  /**  */
  programs?: CreateProgramDto[];

  /**  */
  memberId?: number;

  /**  */
  isForPersonalTraining?: boolean;

  /**  */
  externalProgrammingTrack?: string;
}

export interface CreatePushNotificationDto {
  /**  */
  body?: string;

  /**  */
  subject?: string;

  /**  */
  sendingTime?: DateTime;

  /**  */
  sendTo?: string[];

  /**  */
  availableInGyms?: number[];
}

export interface CreateQuickLinkDto {
  /**  */
  name?: string;

  /**  */
  url?: string;

  /**  */
  availableInGyms?: number[];
}

export interface CreateRegistrationDto {
  /**  */
  ticketTypeId?: number;

  /**  */
  selectedOptions?: object;

  /**  */
  memberId?: number;
}

export interface CreateShiftDto {
  /**  */
  staffMemberId?: number;

  /**  */
  employeeId?: number;

  /**  */
  begins?: DateTime;

  /**  */
  ends?: DateTime;

  /**  */
  description?: string;

  /**  */
  numberOfRepetitions?: number;

  /**  */
  gymId?: number;
}

export interface CreateSignUpDto {
  /**  */
  accountId?: number;

  /**  */
  classId?: number;
}

export interface CreateStripeSessionDto {
  /**  */
  successUrl?: string;

  /**  */
  cancelUrl?: string;
}

export interface CreateTicketTypeDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  priceAmount?: number;

  /**  */
  numberOfAvailableTickets?: number;
}

export interface CreateVoucherMembershipDto {
  /**  */
  validFrom?: DateTime;

  /**  */
  membershipTypeId?: number;

  /**  */
  memberId?: number;

  /**  */
  withFee?: boolean;

  /**  */
  isOneTimeDiscount?: boolean;

  /**  */
  discountAmount?: number;

  /**  */
  discountPercentage?: number;

  /**  */
  sendWelcomeMail?: boolean;

  /**  */
  discountCode?: string;

  /**  */
  useFirstOfMonthAsAnchor?: boolean;

  /**  */
  priceAmount?: number;

  /**  */
  hasAutomaticRenewal?: boolean;
}

export interface CreateVoucherTypeDto {
  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  numberOfClips?: number;

  /**  */
  validityInMonths?: number;

  /**  */
  taxRate?: number;

  /**  */
  taxRateInclusive?: boolean;

  /**  */
  welcomeEmailTemplateId?: number;

  /**  */
  contractId?: number;

  /**  */
  hasSetupFee?: boolean;

  /**  */
  isPublic?: boolean;

  /**  */
  hasAccessToClassIds?: number[];

  /**  */
  automaticClassRegistrationForClassIds?: number[];

  /**  */
  hasAutomaticRenewal?: boolean;

  /**  */
  accountNumber?: number;

  /**  */
  maximumNumberOfActiveClassRegistrations?: number;

  /**  */
  bookingInAdvanceAllowedForDays?: number;

  /**  */
  shortDescription?: string;

  /**  */
  hasAccessToDoorSystem?: boolean;

  /**  */
  setupFee?: number;

  /**  */
  openingHours?: OpeningHour[];

  /**  */
  availableInGyms?: number[];
}

export interface CreateWageDto {
  /**  */
  employeeId?: number;

  /**  */
  wageRateId?: number;

  /**  */
  payrollEmployeeId?: string;

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;
}

export interface CreateWorkoutDto {
  /**  */
  title?: string;

  /**  */
  clazzId?: number;

  /**  */
  text?: string;

  /**  */
  json?: string;

  /**  */
  workoutTypeId?: number;

  /**  */
  timecapInMinutes?: number;

  /**  */
  timecapInSeconds?: number;

  /**  */
  exercises?: CreateWorkoutExerciseDto[];
}

export interface CreateWorkoutExerciseDto {
  /**  */
  count?: number;

  /**  */
  numberOfSets?: number;

  /**  */
  unit?: string;

  /**  */
  exerciseTypeName?: string;

  /**  */
  womensWeight?: number;

  /**  */
  mensWeight?: number;

  /**  */
  percentage?: number;

  /**  */
  relationTo?: string;
}

export interface CredentialDto {
  /**  */
  id?: number;

  /**  */
  chainId?: number;

  /**  */
  accessToken?: string;

  /**  */
  paymentProvider?: ExternalSystemProviders;
}

export interface CustomerDto {
  /**  */
  id?: number;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  vatNo?: string;

  /**  */
  companyName?: string;

  /**  */
  addressLine?: string;

  /**  */
  postalNumber?: string;

  /**  */
  city?: string;

  /**  */
  country?: string;

  /**  */
  fullName?: string;
}

export interface DatasetDto {
  /**  */
  label?: string;

  /**  */
  data?: number[];

  /**  */
  backgroundColor?: string;

  /**  */
  borderColor?: string;

  /**  */
  color?: string;

  /**  */
  fill?: boolean;

  /**  */
  borderWidth?: number;
}

export interface DateOnlyAreaDataPointDto {
  /**  */
  x?: DateTime;

  /**  */
  y?: number;
}

export interface DateOnlyAreaDataPointDtoSeriesData {
  /**  */
  name?: string;

  /**  */
  data?: DateOnlyAreaDataPointDto[];
}

export interface DateOnlyAreaDto {
  /**  */
  average?: number;
name?:string;
  /**  */
  median?: number;

  /**  */
  series?: DateOnlyAreaDataPointDtoSeriesData[];
}

export interface DateTimeAreaDataPointDto {
  /**  */
  x?: DateTime;

  /**  */
  y?: number;
}

export interface DateTimeAreaDataPointDtoSeriesData {
  /**  */
  name?: string;

  /**  */
  data?: DateTimeAreaDataPointDto[];
}

export interface DateTimeAreaDto {
  /**  */
  average?: number;

  /**  */
  median?: number;

  /**  */
  series?: DateTimeAreaDataPointDtoSeriesData[];
}

export interface DiscountVoucherDto {
  /**  */
  id?: number;

  /**  */
  code?: string;

  /**  */
  clips?: number;

  /**  */
  usedClips?: number;

  /**  */
  name?: string;

  /**  */
  discountAmount?: number;

  /**  */
  discountCurrency?: string;

  /**  */
  discountPercentage?: number;

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  isOneTimeUse?: boolean;

  /**  */
  gymId?: number;

  /**  */
  availableForMembershipTypes?: number[];
}

export interface DocumentDto {
  /**  */
  id?: number;

  /**  */
  createdById?: string;

  /**  */
  createdDate?: DateTime;

  /**  */
  modifiedById?: string;

  /**  */
  lastModifiedDate?: DateTime;

  /**  */
  chainId?: number;

  /**  */
  ownerId?: number;

  /**  */
  fileId?: number;

  /**  */
  fileOriginalName?: string;

  /**  */
  fileSize?: number;
}

export interface DoorSystemIntegrationDto {
  /**  */
  authUrl?: string;
}

export interface DropInDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  remainingNumberOfClips?: number;

  /**  */
  numberOfClips?: number;

  /**  */
  usedNumberOfClips?: number;

  /**  */
  ownerId?: number;

  /**  */
  ownerName?: string;

  /**  */
  chainId?: number;

  /**  */
  chainName?: string;

  /**  */
  typeId?: number;

  /**  */
  typeName?: string;

  /**  */
  isGuestVoucher?: boolean;

  /**  */
  hasAutomaticRenewal?: boolean;
}

export interface DropInTypeDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  chainId?: number;

  /**  */
  numberOfClips?: number;

  /**  */
  validityInDays?: number;

  /**  */
  hasSetupFee?: boolean;

  /**  */
  setupFee?: number;

  /**  */
  taxRateId?: number;

  /**  */
  taxRate?: number;

  /**  */
  taxRateInclusive?: boolean;

  /**  */
  isActive?: boolean;

  /**  */
  createdAt?: DateTime;

  /**  */
  contractId?: number;

  /**  */
  welcomeEmailTemplateId?: number;

  /**  */
  isPublic?: boolean;

  /**  */
  hasAccessToClassIds?: number[];

  /**  */
  openingHours?: OpeningHour[];

  /**  */
  gymId?: number;

  /**  */
  accountNumber?: number;

  /**  */
  billable?: BillableType;

  /**  */
  maximumNumberOfActiveClassRegistrations?: number;

  /**  */
  bookingInAdvanceAllowedForDays?: number;

  /**  */
  createdDate?: DateTime;

  /**  */
  shortDescription?: string;

  /**  */
  hasAccessToDoorSystem?: boolean;
}

export interface EconomicsDto {
  /**  */
  monthlyRecurringRevenue?: number;

  /**  */
  monthlyRecurringRevenueLastPeriod?: number;

  /**  */
  subscriberLifetimeValue?: number;

  /**  */
  subscriberLifetimeValueLastPeriod?: number;

  /**  */
  averageRevenuePerAccount?: number;

  /**  */
  averageRevenuePerAccountLastPeriod?: number;

  /**  */
  merchandiseSales?: number;

  /**  */
  merchandiseSalesLastPeriod?: number;

  /**  */
  amountsDueFromCustomers?: number;

  /**  */
  amountsDueFromCustomersLastPeriod?: number;

  /**  */
  membershipRevenueLastPeriod?: number;

  /**  */
  membershipRevenue?: number;
}

export interface EditCampaignMembershipTypeDto {
  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  billable?: BillableType;

  /**  */
  hasAccessToDoorSystem?: boolean;

  /**  */
  maximumNumberOfActiveClassRegistrations?: number;

  /**  */
  bookingInAdvanceAllowedForDays?: number;

  /**  */
  taxRate?: number;

  /**  */
  taxRateInclusive?: boolean;

  /**  */
  numberOfSeats?: number;

  /**  */
  setupFee?: number;

  /**  */
  openingHours?: OpeningHour[];

  /**  */
  availableInGyms?: number[];

  /**  */
  membershipExpiresAfterNumberOfDays?: number;

  /**  */
  membershipExpiresAfterNumberOfMonths?: number;

  /**  */
  membershipExpiresAt?: DateTime;

  /**  */
  membershipUsableFrom?: DateTime;

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  convertsToMembershipTypeId?: number;

  /**  */
  contractId?: number;

  /**  */
  welcomeEmailTemplateId?: number;

  /**  */
  hasSetupFee?: boolean;

  /**  */
  hasAccessToClassIds?: number[];

  /**  */
  automaticClassRegistrationForClassIds?: number[];

  /**  */
  isPublic?: boolean;

  /**  */
  accountNumber?: number;

  /**  */
  shortDescription?: string;

  /**  */
  numberOfAllowedTrainingsPerPeriod?: number;

  /**  */
  id?: number;

  /**  */
  updatePriceOnExistingMemberships?: boolean;

  /**  */
  priceIncreaseValidFrom?: DateTime;
}

export interface EditChatDto {
  /**  */
  type?: string;

  /**  */
  name?: string;

  /**  */
  messages?: ChatMessageDto[];

  /**  */
  participants?: ChatParticipantDto[];

  /**  */
  id?: number;
}

export interface EditClassDto {
  /**  */
  descriptionId?: number;

  /**  */
  begins?: DateTime;

  /**  */
  ends?: DateTime;

  /**  */
  coaches?: number[];

  /**  */
  participantIds?: number[];

  /**  */
  gymId?: number;

  /**  */
  finesEnabled?: boolean;

  /**  */
  closedClass?: boolean;

  /**  */
  subtitle?: string;

  /**  */
  title?: string;

  /**  */
  isGuestBookingPossible?: boolean;

  /**  */
  maximumNumberOfParticipants?: number;

  /**  */
  releaseProgram?: boolean;

  /**  */
  hoursBeforeProgramRelease?: number;

  /**  */
  minutesBeforeProgramRelease?: number;

  /**  */
  isPersonalTrainingOnly?: boolean;

  /**  */
  numberOfRepetitions?: number;

  /**  */
  shiftDurationHours?: number;

  /**  */
  shiftDurationMinutes?: number;

  /**  */
  isOpenGym?: boolean;

  /**  */
  id?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deletionMessage?: string;
}

export interface EditClassTemplateDto {
  /**  */
  id?: number;

  /**  */
  descriptionId?: number;

  /**  */
  weekDays?: DayOfWeek[];

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  begins?: DateTime;

  /**  */
  gymId?: number;

  /**  */
  locationId?: number;

  /**  */
  gymName?: string;

  /**  */
  subtitle?: string;

  /**  */
  title?: string;

  /**  */
  optOutTimeHours?: number;

  /**  */
  optOutTimeMinutes?: number;

  /**  */
  classDurationHours?: number;

  /**  */
  classDurationMinutes?: number;

  /**  */
  shiftDurationHours?: number;

  /**  */
  shiftDurationMinutes?: number;

  /**  */
  finesEnabled?: boolean;

  /**  */
  specificDays?: SpecificDayPlanningType;

  /**  */
  normal?: NormalPlanningType;

  /**  */
  alternating?: AlternatingPlanningType;

  /**  */
  type?: ClassTemplateTypes;

  /**  */
  maximumNumberOfParticipants?: number;

  /**  */
  closedClass?: boolean;

  /**  */
  isGuestBookingPossible?: boolean;

  /**  */
  images?: FileDto[];

  /**  */
  releaseProgram?: boolean;

  /**  */
  isOpenGym?: boolean;

  /**  */
  automaticCancellingOfClasses?: boolean;

  /**  */
  isPersonalTrainingOnly?: boolean;

  /**  */
  hoursBeforeProgramRelease?: number;

  /**  */
  minutesBeforeProgramRelease?: number;

  /**  */
  frequency?: Frequency;

  /**  */
  autoSignUpParticipantIds?: number[];

  /**  */
  minimumNumberOfRegistrationsPerClass?: number;

  /**  */
  stopPlanning?: boolean;

  /**  */
  resumePlanning?: boolean;
}

export interface EditContingentTypeDto {
  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  billable?: BillableType;

  /**  */
  taxRate?: number;

  /**  */
  taxRateInclusive?: boolean;

  /**  */
  numberOfSeats?: number;

  /**  */
  contractId?: number;

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  onlySoldInPerson?: boolean;

  /**  */
  welcomeEmailTemplateId?: number;

  /**  */
  cancellationEmailTemplateId?: number;

  /**  */
  onHoldEmailTemplateId?: number;

  /**  */
  hasSetupFee?: boolean;

  /**  */
  hasAccessToClassIds?: number[];

  /**  */
  automaticClassRegistrationForClassIds?: number[];

  /**  */
  isPublic?: boolean;

  /**  */
  numberOfAllowedTrainingsPerPeriod?: number;

  /**  */
  automaticRenewalDisabled?: boolean;

  /**  */
  accountNumber?: number;

  /**  */
  maximumNumberOfActiveClassRegistrations?: number;

  /**  */
  bookingInAdvanceAllowedForDays?: number;

  /**  */
  hasAccessToDoorSystem?: boolean;

  /**  */
  setupFee?: number;

  /**  */
  openingHours?: OpeningHour[];

  /**  */
  availableInGyms?: number[];

  /**  */
  membershipExpiresAfterNumberOfDays?: number;

  /**  */
  shortDescription?: string;

  /**  */
  id?: number;

  /**  */
  updatePriceOnExistingMemberships?: boolean;

  /**  */
  priceIncreaseValidFrom?: DateTime;
}

export interface EditDiscountVoucherDto {
  /**  */
  code?: string;

  /**  */
  clips?: number;

  /**  */
  name?: string;

  /**  */
  discountAmount?: number;

  /**  */
  discountCurrency?: string;

  /**  */
  discountPercentage?: number;

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  isOneTimeUse?: boolean;

  /**  */
  availableInGyms?: number[];

  /**  */
  availableForMembershipTypes?: number[];

  /**  */
  id?: number;
}

export interface EditDropInTypeDto {
  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  numberOfClips?: number;

  /**  */
  validityInDays?: number;

  /**  */
  taxRate?: number;

  /**  */
  taxRateInclusive?: boolean;

  /**  */
  welcomeEmailTemplateId?: number;

  /**  */
  contractId?: number;

  /**  */
  hasSetupFee?: boolean;

  /**  */
  isPublic?: boolean;

  /**  */
  hasAccessToClassIds?: number[];

  /**  */
  automaticClassRegistrationForClassIds?: number[];

  /**  */
  accountNumber?: number;

  /**  */
  maximumNumberOfActiveClassRegistrations?: number;

  /**  */
  bookingInAdvanceAllowedForDays?: number;

  /**  */
  shortDescription?: string;

  /**  */
  hasAccessToDoorSystem?: boolean;

  /**  */
  setupFee?: number;

  /**  */
  openingHours?: OpeningHour[];

  /**  */
  availableInGyms?: number[];

  /**  */
  id?: number;
}

export interface EditEmployeeDto {
  /**  */
  email?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  middleName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  gender?: Gender;

  /**  */
  roles?: string[];

  /**  */
  title?: string;

  /**  */
  profileText?: string;

  /**  */
  dateOfBirth?: DateTime;

  /**  */
  tint?: string;

  /**  */
  addressLine?: string;

  /**  */
  city?: string;

  /**  */
  postalNumber?: string;

  /**  */
  country?: string;

  /**  */
  gymId?: number;

  /**  */
  id?: number;
}

export interface EditEventDto {
  /**  */
  title?: string;

  /**  */
  begins?: DateTime;

  /**  */
  ends?: DateTime;

  /**  */
  openForSignUpAt?: DateTime;

  /**  */
  closedForSignUpAt?: DateTime;

  /**  */
  ticketTypes?: CreateTicketTypeDto[];

  /**  */
  optionTypes?: CreateOptionTypeDto[];

  /**  */
  description?: string;

  /**  */
  shortDescription?: string;

  /**  */
  confirmationEmailId?: number;

  /**  */
  availableInGyms?: number[];

  /**  */
  accountNumber?: number;

  /**  */
  id?: number;
}

export interface EditGymDto {
  /**  */
  name?: string;

  /**  */
  addressLine?: string;

  /**  */
  city?: string;

  /**  */
  postalNumber?: string;

  /**  */
  country?: string;

  /**  */
  description?: string;

  /**  */
  shortDescription?: string;

  /**  */
  openingHours?: OpeningHour[];

  /**  */
  externalLockIds?: string[];

  /**  */
  vatNo?: string;

  /**  */
  bankAccount?: string;

  /**  */
  bankAccountIban?: string;

  /**  */
  bankAccountSwift?: string;

  /**  */
  isDebtCollectionAgreementSigned?: boolean;

  /**  */
  paymentProviderApiKey?: string;

  /**  */
  id?: number;
}

export interface EditHourlyWageRateDto {
  /**  */
  name?: string;

  /**  */
  payrollSystem?: PayrollSystem;

  /**  */
  externalId?: string;

  /**  */
  amount?: number;

  /**  */
  id?: number;
}

export interface EditLocationDto {
  /**  */
  name?: string;

  /**  */
  gymId?: number;

  /**  */
  id?: number;
}

export interface EditLockDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  address?: string;

  /**  */
  openingHours?: OpeningHour[];

  /**  */
  externalId?: string;

  /**  */
  useAutoLock?: boolean;

  /**  */
  automaticallyLockDoorsOutsideOpeningHours?: boolean;

  /**  */
  automaticallyUnlockDoorsInsideOpeningHours?: boolean;
}

export interface EditMemberDto {
  /**  */
  email?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  payerEmail?: string;

  /**  */
  middleName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  gender?: Gender;

  /**  */
  dateOfBirth?: DateTime;

  /**  */
  addressLine?: string;

  /**  */
  city?: string;

  /**  */
  postalNumber?: string;

  /**  */
  country?: string;

  /**  */
  comment?: string;

  /**  */
  vatNo?: string;

  /**  */
  id?: number;
}

export interface EditNewsDto {
  /**  */
  title?: string;

  /**  */
  subtitle?: string;

  /**  */
  teaser?: string;

  /**  */
  body?: string;

  /**  */
  json?: string;

  /**  */
  imageId?: number;

  /**  */
  publishedOn?: DateTime;

  /**  */
  tags?: string[];

  /**  */
  metaKeywords?: string[];

  /**  */
  metaTitle?: string;

  /**  */
  metaDescription?: string;

  /**  */
  availableInGyms?: number[];

  /**  */
  status?: NewsStatus;

  /**  */
  id?: number;
}

export interface EditNewsletterDto {
  /**  */
  html?: string;

  /**  */
  json?: string;

  /**  */
  css?: string;

  /**  */
  subject?: string;

  /**  */
  sendingTime?: DateTime;

  /**  */
  sendTo?: string[];

  /**  */
  tags?: string[];

  /**  */
  metaKeywords?: string[];

  /**  */
  metaTitle?: string;

  /**  */
  metaDescription?: string;

  /**  */
  teaser?: string;

  /**  */
  eventId?: number;

  /**  */
  membershipTypeId?: number;

  /**  */
  availableInGyms?: number[];

  /**  */
  id?: number;
}

export interface EditPersonalTrainingTypeDto {
  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  billable?: BillableType;

  /**  */
  taxRate?: number;

  /**  */
  taxRateInclusive?: boolean;

  /**  */
  numberOfSeats?: number;

  /**  */
  contractId?: number;

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  onlySoldInPerson?: boolean;

  /**  */
  welcomeEmailTemplateId?: number;

  /**  */
  cancellationEmailTemplateId?: number;

  /**  */
  onHoldEmailTemplateId?: number;

  /**  */
  hasSetupFee?: boolean;

  /**  */
  hasAccessToClassIds?: number[];

  /**  */
  automaticClassRegistrationForClassIds?: number[];

  /**  */
  isPublic?: boolean;

  /**  */
  numberOfAllowedTrainingsPerPeriod?: number;

  /**  */
  automaticRenewalDisabled?: boolean;

  /**  */
  accountNumber?: number;

  /**  */
  shortDescription?: string;

  /**  */
  hasAccessToDoorSystem?: boolean;

  /**  */
  setupFee?: number;

  /**  */
  openingHours?: OpeningHour[];

  /**  */
  availableInGyms?: number[];

  /**  */
  numberOfClips?: number;

  /**  */
  id?: number;

  /**  */
  updatePriceOnExistingMemberships?: boolean;

  /**  */
  priceIncreaseValidFrom?: DateTime;
}

export interface EditProductDto {
  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  quantity?: number;

  /**  */
  taxRate?: number;

  /**  */
  taxRateInclusive?: boolean;

  /**  */
  priceAmount?: number;

  /**  */
  categoryId?: number;

  /**  */
  accountNumber?: number;

  /**  */
  code?: string;

  /**  */
  sku?: string;

  /**  */
  shortDescription?: string;

  /**  */
  saleLabel?: string;

  /**  */
  newLabel?: string;

  /**  */
  priceSale?: number;

  /**  */
  priceCost?: number;

  /**  */
  tags?: string[];

  /**  */
  availableInGyms?: number[];

  /**  */
  isAvailableInShop?: boolean;

  /**  */
  ordering?: number;

  /**  */
  id?: number;
}

export interface EditProgramDto {
  /**  */
  id?: number;

  /**  */
  date?: DateTime;

  /**  */
  classIds?: number[];

  /**  */
  workouts?: EditWorkoutDto[];

  /**  */
  externalProgrammingTrack?: string;

  /**  */
  coachesNotes?: string;
}

export interface EditProgramTemplateDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  path?: string;

  /**  */
  programs?: EditProgramDto[];

  /**  */
  memberId?: number;

  /**  */
  isForPersonalTraining?: boolean;

  /**  */
  externalProgrammingTrack?: string;
}

export interface EditPushNotificationDto {
  /**  */
  body?: string;

  /**  */
  subject?: string;

  /**  */
  sendingTime?: DateTime;

  /**  */
  sendTo?: string[];

  /**  */
  availableInGyms?: number[];

  /**  */
  id?: number;
}

export interface EditQuickLinkDto {
  /**  */
  url?: string;

  /**  */
  name?: string;

  /**  */
  gym?: GymDto;

  /**  */
  id?: number;
}

export interface EditShiftDto {
  /**  */
  staffMemberId?: number;

  /**  */
  employeeId?: number;

  /**  */
  begins?: DateTime;

  /**  */
  ends?: DateTime;

  /**  */
  description?: string;

  /**  */
  numberOfRepetitions?: number;

  /**  */
  gymId?: number;

  /**  */
  id?: number;
}

export interface EditVoucherTypeDto {
  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  numberOfClips?: number;

  /**  */
  validityInMonths?: number;

  /**  */
  taxRate?: number;

  /**  */
  taxRateInclusive?: boolean;

  /**  */
  welcomeEmailTemplateId?: number;

  /**  */
  contractId?: number;

  /**  */
  hasSetupFee?: boolean;

  /**  */
  isPublic?: boolean;

  /**  */
  hasAccessToClassIds?: number[];

  /**  */
  automaticClassRegistrationForClassIds?: number[];

  /**  */
  hasAutomaticRenewal?: boolean;

  /**  */
  accountNumber?: number;

  /**  */
  maximumNumberOfActiveClassRegistrations?: number;

  /**  */
  bookingInAdvanceAllowedForDays?: number;

  /**  */
  shortDescription?: string;

  /**  */
  hasAccessToDoorSystem?: boolean;

  /**  */
  setupFee?: number;

  /**  */
  openingHours?: OpeningHour[];

  /**  */
  availableInGyms?: number[];

  /**  */
  id?: number;
}

export interface EditWorkoutDto {
  /**  */
  id?: number;

  /**  */
  title?: string;

  /**  */
  clazzId?: number;

  /**  */
  text?: string;

  /**  */
  json?: string;

  /**  */
  workoutTypeId?: number;

  /**  */
  timecapInMinutes?: number;

  /**  */
  timecapInSeconds?: number;

  /**  */
  exercises?: EditWorkoutExerciseDto[];
}

export interface EditWorkoutExerciseDto {
  /**  */
  id?: number;

  /**  */
  count?: number;

  /**  */
  numberOfSets?: number;

  /**  */
  unit?: string;

  /**  */
  exerciseTypeName?: string;

  /**  */
  womensWeight?: number;

  /**  */
  mensWeight?: number;

  /**  */
  percentage?: number;

  /**  */
  relationTo?: string;
}

export interface EmailDto {
  /**  */
  html?: string;

  /**  */
  css?: string;

  /**  */
  subject?: string;

  /**  */
  from?: string;

  /**  */
  json?: string;

  /**  */
  sendTo?: string[];

  /**  */
  chainId?: number;

  /**  */
  createdAt?: DateTime;

  /**  */
  modifiedAt?: DateTime;
}

export interface EmailLogEntryDto {
  /**  */
  subject?: string;

  /**  */
  body?: string;

  /**  */
  recipients?: MemberDto[];

  /**  */
  status?: EmailStatus;

  /**  */
  createdById?: string;

  /**  */
  createdDate?: DateTime;

  /**  */
  modifiedById?: string;

  /**  */
  lastModifiedDate?: DateTime;
}

export interface EmailTemplateDto {
  /**  */
  id?: number;

  /**  */
  type?: EmailTemplateType;

  /**  */
  html?: string;

  /**  */
  json?: string;

  /**  */
  css?: string;

  /**  */
  subject?: string;

  /**  */
  from?: string;

  /**  */
  chainId?: number;

  /**  */
  createdAt?: DateTime;

  /**  */
  modifiedAt?: DateTime;

  /**  */
  name?: string;

  /**  */
  attachments?: FileDto[];
}

export interface EmployeeDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  middleName?: string;

  /**  */
  profileImageUrl?: string;

  /**  */
  dateOfBirth?: DateTime;

  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  title?: string;

  /**  */
  gender?: Gender;

  /**  */
  roles?: string[];

  /**  */
  profileText?: string;
  receiveNotificationAboutPurchases?: boolean;

  /**  */
  tint?: string;

  /**  */
  wageRateId?: number;

  /**  */
  wageRateName?: string;

  /**  */
  payrollEmployeeId?: string;

  /**  */
  address?: AddressDto;

  /**  */
  isActive?: boolean;

  /**  */
  accountId?: number;

  /**  */
  gymId?: number;

  /**  */
  calendarLink?: string;
}

export interface EmployeeFeedbackStatsDto {
  /**  */
  overallRating?: number;

  /**  */
  coachPreparationRating?: number;

  /**  */
  coachObservationRating?: number;

  /**  */
  clarityOfInstructions?: number;

  /**  */
  logisticalRating?: number;

  /**  */
  feedbackHelpfulnessRating?: number;

  /**  */
  warmUpEffectivenessRating?: number;

  /**  */
  employeeOverallRating?: number;

  /**  */
  employeeCoachPreparationRating?: number;

  /**  */
  employeeCoachObservationRating?: number;

  /**  */
  employeeClarityOfInstructions?: number;

  /**  */
  employeeLogisticalRating?: number;

  /**  */
  employeeFeedbackHelpfulnessRating?: number;

  /**  */
  employeeWarmUpEffectivenessRating?: number;
}

export interface EmployeeShiftUpdateDto {
  /**  */
  employeeId?: number;

  /**  */
  shiftId?: number;

  /**  */
  isApproved?: boolean;
}

export interface EmployeeWageRateDto {
  /**  */
  id?: number;

  /**  */
  wageRateId?: number;

  /**  */
  wageRateName?: string;

  /**  */
  employeeId?: number;

  /**  */
  employeeName?: string;

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  payrollEmployeeId?: string;
}

export interface EventDetailsDto {
  /**  */
  id?: number;

  /**  */
  title?: string;

  /**  */
  description?: string;

  /**  */
  openForSignUpAt?: DateTime;

  /**  */
  closedForSignUpAt?: DateTime;

  /**  */
  begins?: DateTime;

  /**  */
  ends?: DateTime;

  /**  */
  numberOfParticipants?: number;

  /**  */
  confirmationEmailId?: number;

  /**  */
  types?: TicketTypeDto[];

  /**  */
  options?: TicketOptionTypeDto[];

  /**  */
  backgroundImageUrl?: string;

  /**  */
  images?: FileDto[];

  /**  */
  shortDescription?: string;

  /**  */
  gymId?: number;

  /**  */
  accountNumber?: number;
}

export interface EventDto {
  /**  */
  id?: number;

  /**  */
  title?: string;

  /**  */
  description?: string;

  /**  */
  shortDescription?: string;

  /**  */
  confirmationEmailId?: number;

  /**  */
  openForSignUpAt?: DateTime;

  /**  */
  closedForSignUpAt?: DateTime;

  /**  */
  begins?: DateTime;

  /**  */
  ends?: DateTime;

  /**  */
  numberOfParticipants?: number;

  /**  */
  createdDate?: DateTime;

  /**  */
  images?: FileDto[];

  /**  */
  gymId?: number;
}

export interface ExerciseDto {
  /**  */
  id?: number;

  /**  */
  exerciseTypeId?: number;

  /**  */
  exerciseTypeName?: string;

  /**  */
  relationTo?: string;

  /**  */
  count?: number;

  /**  */
  mensWeight?: number;

  /**  */
  womensWeight?: number;

  /**  */
  percentage?: number;

  /**  */
  unit?: string;

  /**  */
  workoutId?: number;

  /**  */
  workoutName?: string;

  /**  */
  numberOfSets?: number;
}

export interface ExerciseResultDto {
  /**  */
  exerciseName?: string;

  /**  */
  exerciseTypeName?: string;

  /**  */
  value?: number;

  /**  */
  count?: number;

  /**  */
  ownerId?: number;

  /**  */
  createdDate?: DateTime;
}

export interface ExerciseTypeDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  abbreviation?: string;

  /**  */
  chainId?: number;

  /**  */
  createdDate?: DateTime;

  /**  */
  lastModifiedDate?: DateTime;
}

export interface ExternalLockDto {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  address?: string;
}

export interface FileDto {
  /**  */
  id?: number;

  /**  */
  fileName?: string;

  /**  */
  data?: string;

  /**  */
  fileExt?: string;

  /**  */
  url?: string;

  /**  */
  size?: number;

  /**  */
  createdDate?: DateTime;

  /**  */
  lastModifiedDate?: DateTime;

  /**  */
  contentType?: string;
}

export interface FineCollectionDto {
  /**  */
  memberId?: number;
}

export interface FineDto {
  /**  */
  id?: number;

  /**  */
  memberId?: number;

  /**  */
  participationId?: number;

  /**  */
  chainId?: number;

  /**  */
  status?: FineStatus;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  createdAt?: DateTime;

  /**  */
  modifiedAt?: DateTime;

  /**  */
  when?: DateTime;

  /**  */
  description?: string;

  /**  */
  class?: string;

  /**  */
  reason?: ParticipationStatus;

  /**  */
  invoiceId?: number;
}

export interface GuestDto {
  /**  */
  accountId?: number;

  /**  */
  participationId?: number;

  /**  */
  fullName?: string;

  /**  */
  classId?: string;

  /**  */
  className?: string;

  /**  */
  classBegins?: DateTime;
}

export interface GymDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  address?: AddressDto;

  /**  */
  logoUrl?: string;

  /**  */
  checkInQrCode?: string;

  /**  */
  timeZone?: string;

  /**  */
  description?: string;

  /**  */
  shortDescription?: string;

  /**  */
  images?: FileDto[];

  /**  */
  openingHours?: OpeningHourDto[];

  /**  */
  locks?: LockDto[];

  /**  */
  externalId?: string;

  /**  */
  vatNo?: string;

  /**  */
  bankAccount?: string;

  /**  */
  bankAccountIban?: string;

  /**  */
  bankAccountSwift?: string;

  /**  */
  isDebtCollectionAgreementSigned?: boolean;

  /**  */
  paymentProviderApiKey?: string;

  /**  */
  externalLockIds?: string[];
}

export interface HourlyWageRateDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  externalId?: string;

  /**  */
  payrollSystem?: PayrollSystem;

  /**  */
  amount?: number;

  /**  */
  currency?: string;
}

export interface HoursDto {
  /**  */
  employeeId?: number;

  /**  */
  employeeName?: string;

  /**  */
  earnedHours?: number;

  /**  */
  remainingHours?: number;

  /**  */
  spentHours?: number;

  /**  */
  earnedMinutes?: number;

  /**  */
  remainingMinutes?: number;

  /**  */
  spentMinutes?: number;

  /**  */
  periodFrom?: DateTime;

  /**  */
  periodTo?: DateTime;
}

export interface InstructorDto {
  /**  */
  staffMemberId?: number;

  /**  */
  title?: string;

  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  profileImageUrl?: string;
}

export interface Int32Int32DayOfWeekNullableTuple {
  /**  */
  item1?: number;

  /**  */
  item2?: number;

  /**  */
  item3?: DayOfWeek;
}

export interface InvoiceAddressDto {
  /**  */
  customerId?: number;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  middleName?: string;

  /**  */
  email?: string;

  /**  */
  vatNo?: string;

  /**  */
  companyName?: string;

  /**  */
  city?: string;

  /**  */
  addressLine?: string;

  /**  */
  postalNumber?: string;

  /**  */
  country?: string;

  /**  */
  phoneNumber?: string;
}

export interface InvoiceDto {
  /**  */
  id?: number;

  /**  */
  chainId?: number;

  /**  */
  createdDate?: DateTime;

  /**  */
  modifiedAt?: DateTime;

  /**  */
  dueDate?: DateTime;

  /**  */
  paidAt?: DateTime;

  /**  */
  status?: InvoiceStatuses;

  /**  */
  paymentMethod?: PaymentMethods;

  /**  */
  orderId?: number;

  /**  */
  orderNumber?: string;

  /**  */
  membershipId?: number;

  /**  */
  membershipName?: string;

  /**  */
  debtId?: number;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  totalAmount?: number;

  /**  */
  totalRefund?: number;

  /**  */
  refunds?: RefundDto[];

  /**  */
  lines?: InvoiceLineDto[];

  /**  */
  customerName?: string;

  /**  */
  customerId?: number;

  /**  */
  customerProfileImageUrl?: string;

  /**  */
  customerFirstName?: string;

  /**  */
  customerLastName?: string;

  /**  */
  customerEmailAddress?: string;

  /**  */
  customerPhoneNumber?: string;

  /**  */
  customerExternalLink?: string;

  /**  */
  customerAddress?: AddressDto;

  /**  */
  companyName?: string;

  /**  */
  companyEmailAddress?: string;

  /**  */
  companyAddress?: AddressDto;

  /**  */
  companyVatNo?: string;

  /**  */
  companyLogoUrl?: string;

  /**  */
  invoiceTo?: InvoiceAddressDto;

  /**  */
  invoiceFrom?: InvoiceAddressDto;
}

export interface InvoiceLineDto {
  /**  */
  orderItemId?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  taxRate?: number;

  /**  */
  taxRateInclusive?: boolean;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  refunds?: RefundDto[];

  /**  */
  totalAmount?: number;

  /**  */
  totalRefund?: number;

  /**  */
  quantity?: number;

  /**  */
  taxAmount?: number;

  /**  */
  unitPriceAmount?: number;
}

export interface LeadDto {
  /**  */
  id?: number;

  /**  */
  memberId?: number;

  /**  */
  memberName?: string;

  /**  */
  memberEmail?: string;

  /**  */
  memberProfileImageUrl?: string;

  /**  */
  signedUpAt?: DateTime;

  /**  */
  participatedInClassName?: string;

  /**  */
  participatedInClassId?: number;

  /**  */
  participatedOnDate?: DateTime;

  /**  */
  participationStatus?: ParticipationStatus;

  /**  */
  convertedToMembershipId?: number;

  /**  */
  convertedToMembershipName?: string;

  /**  */
  status?: LeadStatuses;

  /**  */
  referredById?: number;

  /**  */
  referredByName?: string;

  /**  */
  referredByEmail?: string;

  /**  */
  referredByProfileImageUrl?: string;
}

export interface LedgerEntryDto {
  /**  */
  documentNumber?: string;

  /**  */
  date?: DateTime;

  /**  */
  description?: string;

  /**  */
  accountNumber?: string;

  /**  */
  againstAccountNumber?: string;

  /**  */
  posted?: boolean;

  /**  */
  isPaid?: boolean;

  /**  */
  orderItemId?: number;

  /**  */
  amount?: number;

  /**  */
  reconciled?: boolean;

  /**  */
  invoiceId?: number;
}

export interface LocationDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  gymId?: number;
}

export interface LockDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  address?: string;

  /**  */
  externalId?: string;

  /**  */
  openingHours?: OpeningHourDto[];

  /**  */
  useAutoLock?: boolean;

  /**  */
  automaticallyLockDoorsOutsideOpeningHours?: boolean;

  /**  */
  automaticallyUnlockDoorsInsideOpeningHours?: boolean;
}

export interface LogsDto {
  /**  */
  auditLog?: AuditLogEntryDto[];

  /**  */
  smsLog?: SmsLogEntryDto[];

  /**  */
  emailLog?: EmailLogEntryDto[];
}

export interface MailgunClientInfoDto {
  /**  */
  'client-name'?: string;

  /**  */
  'client-type'?: string;

  /**  */
  'user-agent'?: string;

  /**  */
  'device-type'?: string;

  /**  */
  'client-os'?: string;

  /**  */
  bot?: string;
}

export interface MailgunEventDataDto {
  /**  */
  event?: string;

  /**  */
  timestamp?: number;

  /**  */
  id?: string;

  /**  */
  'client-info'?: MailgunClientInfoDto;

  /**  */
  message?: MailgunMessageDto;
}

export interface MailgunHeadersDto {
  /**  */
  'message-id'?: string;
}

export interface MailgunMessageDto {
  /**  */
  headers?: MailgunHeadersDto;
}

export interface MailgunSignatureDto {
  /**  */
  timestamp?: string;

  /**  */
  token?: string;

  /**  */
  signature?: string;
}

export interface MailgunWebhookEventDto {
  /**  */
  signature?: MailgunSignatureDto;

  /**  */
  'event-data'?: MailgunEventDataDto;
}

export interface MemberChangeDto {
  /**  */
  id?: number;

  /**  */
  membershipTypeId?: number;

  /**  */
  chainName?: string;

  /**  */
  chainId?: number;

  /**  */
  name?: string;

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  wasMemberForInDays?: number;

  /**  */
  ownerId?: number;

  /**  */
  ownerName?: string;

  /**  */
  ownerEmail?: string;

  /**  */
  ownerPhoneNumber?: string;

  /**  */
  ownerProfileImageUrl?: string;

  /**  */
  type?: MembershipTypeTypes;

  /**  */
  typeOfMembership?: string;
}

export interface MemberChangeReportDto {
  /**  */
  numberOfActiveMembersAtStart?: number;

  /**  */
  numberOfNewMembers?: number;

  /**  */
  numberOfLostMembers?: number;

  /**  */
  numberOfActiveMembersAtEnd?: number;

  /**  */
  newMembers?: MemberChangeDto[];

  /**  */
  lostMembers?: MemberChangeDto[];
}

export interface MemberChurnReportDto {
  /**  */
  averageChurnRateForPeriod?: number;

  /**  */
  medianChurnRateForPeriod?: number;

  /**  */
  churnRateForCurrentMonth?: number;

  /**  */
  churnRates?: MemberChurnStatsDto[];
}

export interface MemberChurnStatsDto {
  /**  */
  month?: string;

  /**  */
  count?: number;
}

export interface MemberDto {
  /**  */
  id?: number;

  /**  */
  firstName?: string;

  /**  */
  middleName?: string;

  /**  */
  lastName?: string;

  /**  */
  fullName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  gender?: string;

  /**  */
  dateOfBirth?: DateTime;

  /**  */
  address?: string;

  /**  */
  profileImageUrl?: string;

  /**  */
  isActive?: boolean;

  /**  */
  hasPaymentMethod?: boolean;

  /**  */
  numberOfAttendances?: number;

  /**  */
  createdDate?: DateTime;
}

export interface MemberEventDto {
  /**  */
  id?: number;

  /**  */
  title?: string;

  /**  */
  content?: string;

  /**  */
  timestamp?: DateTime;

  /**  */
  createdBy?: string;

  /**  */
  createdByProfileImage?: string;

  /**  */
  createdById?: number;

  /**  */
  accountId?: number;

  /**  */
  type?: MemberEventType;
  status?: string;
}

export interface MemberLengthOfEngagementDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  profileImageUrl?: string;

  /**  */
  lengthOfEngagement?: number;
}

export interface MemberLengthOfEngagementReportDto {
  /**  */
  lengthOfEngagement?: number;

  /**  */
  members?: MemberLengthOfEngagementDto[];
}

export interface MemberStatisticsDto {
  /**  */
  history?: StatsDto[];

  /**  */
  count?: number;

  /**  */
  percentageChange?: number;
}

export interface MemberStatsDto {
  /**  */
  numberOfClassRegistrations?: number;

  /**  */
  numberOfTrainings?: number;
}

export interface MembersWithRetentionIssuesDto {
  /**  */
  id?: number;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  membershipType?: string;

  /**  */
  profileImageUrl?: string;

  /**  */
  numberOfAttendances?: number;
}

export interface MembershipDto {
  /**  */
  id?: number;

  /**  */
  membershipTypeId?: number;

  /**  */
  chainName?: string;

  /**  */
  chainId?: number;

  /**  */
  name?: string;

  /**  */
  status?: MembershipStatuses;

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  renewalDate?: DateTime;

  /**  */
  onHoldUntil?: DateTime;

  /**  */
  ownerId?: number;

  /**  */
  ownerName?: string;

  /**  */
  ownerEmail?: string;

  /**  */
  ownerPhoneNumber?: string;

  /**  */
  ownerProfileImageUrl?: string;

  /**  */
  billable?: BillableType;

  /**  */
  type?: MembershipTypeTypes;

  /**  */
  priceAmount?: number;

  /**  */
  priceWithoutDiscountAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  needsPayment?: boolean;

  /**  */
  needsPaymentMethod?: boolean;

  /**  */
  hasPaymentMethod?: boolean;

  /**  */
  invoiceId?: number;

  /**  */
  purchaseDate?: DateTime;

  /**  */
  discountPercentage?: number;

  /**  */
  discountAmount?: number;

  /**  */
  isPersonalTrainingMembership?: boolean;

  /**  */
  personalTrainerId?: number;

  /**  */
  personalTrainerFullName?: string;

  /**  */
  numberOfClips?: number;

  /**  */
  remainingNumberOfClips?: number;

  /**  */
  hasAutomaticRenewal?: boolean;

  /**  */
  typeOfMembership?: string;
}

export interface MembershipTypePriceDto {
  /**  */
  id?: number;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  taxRate?: number;

  /**  */
  taxRateInclusive?: boolean;

  /**  */
  updatePriceOnExistingMemberships?: boolean;
}

export interface MessageDto {
  /**  */
  id?: number;

  /**  */
  body?: string;

  /**  */
  subject?: string;

  /**  */
  sendTo?: string[];

  /**  */
  sendAsEmail?: boolean;

  /**  */
  sendAsPushNotification?: boolean;

  /**  */
  createdDate?: DateTime;

  /**  */
  posterName?: string;

  /**  */
  posterProfileImageUrl?: string;
}

export interface Money {
  /**  */
  currency?: string;

  /**  */
  amount?: number;

  /**  */
  amountAtomic?: number;
}

export interface MonthlyOptOutTimesDto {
  /**  */
  month?: string;

  /**  */
  numberOfOptOuts?: number;
}

export interface NewsDto {
  /**  */
  id?: number;

  /**  */
  title?: string;

  /**  */
  subtitle?: string;

  /**  */
  body?: string;

  /**  */
  json?: string;

  /**  */
  teaser?: string;

  /**  */
  imageId?: number;

  /**  */
  imageUrl?: string;

  /**  */
  authorId?: number;

  /**  */
  authorProfileImageUrl?: string;

  /**  */
  authorName?: string;

  /**  */
  status?: NewsStatus;

  /**  */
  publishedOn?: DateTime;

  /**  */
  lastModifiedDate?: DateTime;

  /**  */
  tags?: string[];

  /**  */
  totalFavorites?: number;

  /**  */
  totalViews?: number;

  /**  */
  totalShares?: number;

  /**  */
  totalComments?: number;

  /**  */
  comments?: CommentDto[];

  /**  */
  favoritePersons?: AccountDto[];

  /**  */
  metaKeywords?: string[];

  /**  */
  metaTitle?: string;

  /**  */
  metaDescription?: string;

  /**  */
  availableInGyms?: number[];
}

export interface NewsletterDto {
  /**  */
  id?: number;

  /**  */
  html?: string;

  /**  */
  json?: string;

  /**  */
  css?: string;

  /**  */
  subject?: string;

  /**  */
  from?: string;

  /**  */
  authorId?: number;

  /**  */
  authorProfileImageUrl?: string;

  /**  */
  authorName?: string;

  /**  */
  sendingTime?: DateTime;

  /**  */
  chainId?: number;

  /**  */
  createdDate?: DateTime;

  /**  */
  createdById?: string;

  /**  */
  lastModifiedDate?: DateTime;

  /**  */
  modifiedById?: string;

  /**  */
  status?: NewsletterStatus;

  /**  */
  numberOfRecipients?: number;

  /**  */
  deliveredTo?: number;

  /**  */
  failures?: number;

  /**  */
  tags?: string[];

  /**  */
  totalFavorites?: number;

  /**  */
  totalViews?: number;

  /**  */
  totalClicked?: number;

  /**  */
  comments?: CommentDto[];

  /**  */
  favoritePersons?: AccountDto[];

  /**  */
  metaKeywords?: string[];

  /**  */
  metaTitle?: string;

  /**  */
  metaDescription?: string;

  /**  */
  teaser?: string;

  /**  */
  attachments?: FileDto[];

  /**  */
  availableInGyms?: number[];
}

export interface NormalPlanningType {
  /**  */
  coaches?: number[];
}

export interface OpeningHour {
  /**  */
  from?: DateTime;

  /**  */
  to?: DateTime;

  /**  */
  dayOfWeek?: DayOfWeek;

  /**  */
  externalId?: string;
}

export interface OpeningHourDto {
  /**  */
  from?: DateTime;

  /**  */
  to?: DateTime;

  /**  */
  dayOfWeek?: DayOfWeek;

  /**  */
  externalId?: string;
}

export interface OptOutTimesDto {
  /**  */
  interval?: string;

  /**  */
  monthlyOptOuts?: MonthlyOptOutTimesDto[];
}

export interface OrderDetailsDto {
  /**  */
  id?: number;

  /**  */
  customerName?: string;

  /**  */
  customerId?: number;

  /**  */
  customerProfileImageUrl?: string;

  /**  */
  customerFirstName?: string;

  /**  */
  customerLastName?: string;

  /**  */
  customerEmailAddress?: string;

  /**  */
  customerPhoneNumber?: string;

  /**  */
  customerExternalLink?: string;

  /**  */
  vatNo?: string;

  /**  */
  status?: OrderStatuses;

  /**  */
  companyName?: string;

  /**  */
  city?: string;

  /**  */
  addressLine?: string;

  /**  */
  postalNumber?: string;

  /**  */
  country?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  comment?: string;

  /**  */
  items?: OrderItemDto[];

  /**  */
  events?: OrderEventDto[];

  /**  */
  discount?: number;

  /**  */
  isPaid?: boolean;

  /**  */
  orderNumber?: string;

  /**  */
  debtCollectionLink?: string;

  /**  */
  invoiceId?: number;

  /**  */
  createdDate?: DateTime;

  /**  */
  shippingAddress?: AddressDto;

  /**  */
  paymentMethod?: PaymentMethodDto;

  /**  */
  usedPaymentMethod?: string;

  /**  */
  totalAmount?: number;

  /**  */
  totalRefund?: number;

  /**  */
  totalVat?: number;
}

export interface OrderDto {
  /**  */
  id?: number;

  /**  */
  customerName?: string;

  /**  */
  customerId?: number;

  /**  */
  customerProfileImageUrl?: string;

  /**  */
  customerEmailAddress?: string;

  /**  */
  orderNumber?: string;

  /**  */
  createdDate?: DateTime;

  /**  */
  status?: OrderStatuses;

  /**  */
  totalAmount?: number;

  /**  */
  totalCurrency?: string;

  /**  */
  invoiceId?: number;

  /**  */
  events?: OrderEventDto[];
}

export interface OrderEventDto {
  /**  */
  orderId?: number;

  /**  */
  status?: OrderEventStatuses;

  /**  */
  createdDate?: DateTime;
}

export interface OrderItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  quantity?: number;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  discount?: number;

  /**  */
  taxRate?: TaxRateDto;

  /**  */
  description?: string;

  /**  */
  order?: OrderDto;

  /**  */
  sku?: string;

  /**  */
  refunds?: RefundDto[];
}

export interface PasswordDto {
  /**  */
  value?: string;
}

export interface PaymentDto {
  /**  */
  id?: number;

  /**  */
  status?: InvoiceStatuses;

  /**  */
  paymentMethod?: PaymentMethods;
}

export interface PaymentMethodDto {
  /**  */
  id?: number;

  /**  */
  state?: string;

  /**  */
  customer?: string;

  /**  */
  created?: DateTime;

  /**  */
  cardType?: string;

  /**  */
  expDate?: string;

  /**  */
  maskedCard?: string;

  /**  */
  cardCountry?: string;

  /**  */
  primary?: boolean;

  /**  */
  gateway?: ExternalSystemProviders;

  /**  */
  externalId?: string;
}

export interface PersonalTrainingTypeDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  shortDescription?: string;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  contractId?: number;

  /**  */
  taxRateId?: number;

  /**  */
  taxRate?: number;

  /**  */
  taxRateInclusive?: boolean;

  /**  */
  setupFee?: number;

  /**  */
  price?: Money;

  /**  */
  billable?: BillableType;

  /**  */
  chainId?: number;

  /**  */
  hasSetupFee?: boolean;

  /**  */
  numberOfSeats?: number;

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  welcomeEmailTemplateId?: number;

  /**  */
  cancellationEmailTemplateId?: number;

  /**  */
  onHoldEmailTemplateId?: number;

  /**  */
  hasAccessToClassIds?: number[];

  /**  */
  automaticClassRegistrationForClassIds?: number[];

  /**  */
  prices?: MembershipTypePriceDto[];

  /**  */
  openingHours?: OpeningHour[];

  /**  */
  gymId?: number;

  /**  */
  isPublic?: boolean;

  /**  */
  numberOfAllowedTrainingsPerPeriod?: number;

  /**  */
  automaticRenewalDisabled?: boolean;

  /**  */
  accountNumber?: number;

  /**  */
  createdDate?: DateTime;

  /**  */
  numberOfClips?: number;

  /**  */
  hasAccessToDoorSystem?: boolean;

  /**  */
  updatePriceOnExistingMemberships?: boolean;
}

export interface PieChartDto {
  /**  */
  name?: string;

  /**  */
  labels?: string[];

  /**  */
  series?: number[];
}

export interface PlaceDto {
  /**  */
  longitude?: number;

  /**  */
  latitude?: number;

  /**  */
  type?: LocationType;

  /**  */
  name?: string;
}

export interface ProblemDetails {
  /**  */
  type?: string;

  /**  */
  title?: string;

  /**  */
  status?: number;

  /**  */
  detail?: string;

  /**  */
  instance?: string;
}

export interface ProductCategoryDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  chainId?: number;

  /**  */
  createdDate?: DateTime;
}

export interface ProductDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  quantity?: number;

  /**  */
  remainingQuantity?: number;

  /**  */
  taxRateId?: number;

  /**  */
  accountNumber?: number;

  /**  */
  taxRate?: number;

  /**  */
  taxRateInclusive?: boolean;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  categoryId?: number;

  /**  */
  categoryName?: string;

  /**  */
  chainId?: number;

  /**  */
  imageUrl?: string;

  /**  */
  images?: FileDto[];

  /**  */
  createdDate?: DateTime;

  /**  */
  available?: number;

  /**  */
  code?: string;

  /**  */
  sku?: string;

  /**  */
  shortDescription?: string;

  /**  */
  saleLabel?: string;

  /**  */
  newLabel?: string;

  /**  */
  priceSale?: number;

  /**  */
  priceCost?: number;

  /**  */
  tags?: string[];

  /**  */
  gymId?: number;

  /**  */
  isAvailableInShop?: boolean;

  /**  */
  ordering?: number;
}

export interface ProgramDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  classes?: ClazzDto[];

  /**  */
  workouts?: WorkoutDto[];

  /**  */
  chainId?: number;

  /**  */
  date?: DateTime;

  /**  */
  createdDate?: DateTime;

  /**  */
  lastModifiedDate?: DateTime;

  /**  */
  externalProgrammingTrack?: string;

  /**  */
  coachesNotes?: string;
}

export interface ProgramTemplateDto {
  /**  */
  id?: number;

  /**  */
  programs?: ProgramDto[];

  /**  */
  path?: string;

  /**  */
  name?: string;

  /**  */
  daysOfWeek?: DayOfWeek[];

  /**  */
  classDescriptions?: ClassDescriptionDto[];

  /**  */
  isForPersonalTraining?: boolean;

  /**  */
  createdDate?: DateTime;

  /**  */
  lastModifiedDate?: DateTime;

  /**  */
  externalProgrammingTrack?: string;
}

export interface PurchaseLinkDto {
  /**  */
  id?: number;

  /**  */
  code?: string;

  /**  */
  link?: string;

  /**  */
  chainId?: number;
}

export interface PushNotificationDto {
  /**  */
  id?: number;

  /**  */
  body?: string;

  /**  */
  subject?: string;

  /**  */
  sendingTime?: DateTime;

  /**  */
  chainId?: number;

  /**  */
  createdDate?: DateTime;

  /**  */
  createdById?: string;

  /**  */
  lastModifiedDate?: DateTime;

  /**  */
  modifiedById?: string;

  /**  */
  status?: PushNotificationStatus;

  /**  */
  numberOfRecipients?: number;

  /**  */
  deliveredTo?: number;

  /**  */
  failures?: number;

  /**  */
  authorId?: number;

  /**  */
  authorProfileImageUrl?: string;

  /**  */
  authorName?: string;

  /**  */
  totalViews?: number;

  /**  */
  availableInGyms?: number[];
}

export interface PutMembershipOnHoldDto {
  /**  */
  id?: number;

  /**  */
  onHoldPeriodBegins?: DateTime;

  /**  */
  onHoldPeriodEnds?: DateTime;

  /**  */
  enableEmailSending?: boolean;

  /**  */
  withOnHoldFee?: boolean;

  /**  */
  saveExcessPayment?: boolean;
}

export interface QuickLinkDto {
  /**  */
  id?: number;

  /**  */
  url?: string;

  /**  */
  name?: string;

  /**  */
  gym?: GymDto;
}

export interface RefundDto {
  /**  */
  id?: number;

  /**  */
  invoiceId?: number;

  /**  */
  orderId?: number;

  /**  */
  orderItemId?: number;

  /**  */
  orderItemDescription?: string;

  /**  */
  amount?: number;

  /**  */
  comment?: string;

  /**  */
  currency?: string;

  /**  */
  createdDate?: DateTime;

  /**  */
  createdByName?: string;
}

export interface RoleDto {
  /**  */
  text?: string;

  /**  */
  value?: string;
}

export interface SearchResultDto {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  type?: SearchResultType;

  /**  */
  meta?: any;
}

export interface SendReminderDto {
  /**  */
  orderId?: number;

  /**  */
  membershipId?: number;

  /**  */
  type?: ReminderTypes;
}

export interface ShiftDto {
  /**  */
  id?: number;

  /**  */
  employeeId?: number;

  /**  */
  employeeName?: string;

  /**  */
  employeeEmailAddress?: string;

  /**  */
  tint?: string;

  /**  */
  employeeWageRateId?: number;

  /**  */
  employeeWageRateName?: string;

  /**  */
  employeeExternalId?: string;

  /**  */
  employeeProfileImageUrl?: string;

  /**  */
  begins?: DateTime;

  /**  */
  ends?: DateTime;

  /**  */
  description?: string;

  /**  */
  isApproved?: boolean;

  /**  */
  classId?: number;

  /**  */
  type?: ShiftType;
}

export interface SignUpDto {
  /**  */
  id?: number;

  /**  */
  accountId?: number;

  /**  */
  accountEmailAddress?: string;

  /**  */
  classId?: number;

  /**  */
  className?: string;

  /**  */
  name?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  onWaitingList?: boolean;

  /**  */
  profileImageUrl?: string;

  /**  */
  coverImageUrl?: string;

  /**  */
  status?: ParticipationStatus;

  /**  */
  classBegins?: DateTime;

  /**  */
  checkedInAt?: DateTime;

  /**  */
  signedUpAt?: DateTime;

  /**  */
  comment?: string;

  /**  */
  isGuest?: boolean;

  /**  */
  optedOutAt?: DateTime;
}

export interface SmsLogEntryDto {
  /**  */
  createdById?: string;

  /**  */
  createdDate?: DateTime;

  /**  */
  modifiedById?: string;

  /**  */
  lastModifiedDate?: DateTime;

  /**  */
  numberOfSegments?: number;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;
}

export interface SpecificDayPlanningType {
  /**  */
  weekDays?: WeekDaysType[];
}

export interface StatsDto {
  /**  */
  month?: string;

  /**  */
  count?: number;
}

export interface StripeConnectLinkDto {
  /**  */
  link?: string;
}

export interface StripeWebhookEventDto {
  /**  */
  scope?: string;

  /**  */
  state?: string;

  /**  */
  code?: string;

  /**  */
  error?: string;

  /**  */
  errorDescription?: string;
}

export interface TaxRateDto {
  /**  */
  value?: number;

  /**  */
  inclusive?: boolean;
}

export interface TicketDetailsDto {
  /**  */
  id?: number;

  /**  */
  participantName?: string;

  /**  */
  participantId?: number;

  /**  */
  participantEmail?: string;

  /**  */
  participantPhoneNumber?: string;

  /**  */
  participantProfileImageUrl?: string;

  /**  */
  ticketTypeName?: string;

  /**  */
  invoiceLink?: string;

  /**  */
  createdAt?: DateTime;

  /**  */
  paymentStatus?: InvoiceStatuses;

  /**  */
  options?: TicketOptionDto[];
}

export interface TicketDto {
  /**  */
  id?: number;

  /**  */
  eventId?: number;
}

export interface TicketOptionDto {
  /**  */
  name?: string;

  /**  */
  value?: string;
}

export interface TicketOptionTypeDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  type?: OptionTypeTypes;

  /**  */
  values?: string[];
}

export interface TicketTypeDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  numberOfAvailableTickets?: number;

  /**  */
  remainingNumberOfTickets?: number;

  /**  */
  numberOfSoldTickets?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  priceAmount?: number;

  /**  */
  options?: TicketOptionTypeDto[];
}

export interface TimeSpan {
  /**  */
  ticks?: number;

  /**  */
  days?: number;

  /**  */
  hours?: number;

  /**  */
  milliseconds?: number;

  /**  */
  microseconds?: number;

  /**  */
  nanoseconds?: number;

  /**  */
  minutes?: number;

  /**  */
  seconds?: number;

  /**  */
  totalDays?: number;

  /**  */
  totalHours?: number;

  /**  */
  totalMilliseconds?: number;

  /**  */
  totalMicroseconds?: number;

  /**  */
  totalNanoseconds?: number;

  /**  */
  totalMinutes?: number;

  /**  */
  totalSeconds?: number;
}

export interface TopSalesmanDto {
  /**  */
  employeeName?: string;

  /**  */
  employeeProfileImageUrl?: string;

  /**  */
  bestProductName?: string;

  /**  */
  salesAmount?: number;

  /**  */
  currency?: string;

  /**  */
  rank?: number;
}

export interface TransactionDto {
  /**  */
  id?: string;

  /**  */
  state?: string;

  /**  */
  invoice?: string;

  /**  */
  type?: string;

  /**  */
  amount?: number;

  /**  */
  authorized?: string;

  /**  */
  settled?: DateTime;

  /**  */
  failed?: DateTime;

  /**  */
  refunded?: DateTime;

  /**  */
  created?: DateTime;

  /**  */
  paymentType?: string;

  /**  */
  error?: string;

  /**  */
  acquirerMessage?: string;
}

export interface UpdateMembershipDto {
  /**  */
  id?: number;

  /**  */
  validTo?: DateTime;

  /**  */
  validFrom?: DateTime;

  /**  */
  renewalDate?: DateTime;

  /**  */
  onHoldPeriodBegins?: DateTime;

  /**  */
  onHoldPeriodEnds?: DateTime;

  /**  */
  type?: MembershipUpdateTypes;

  /**  */
  enableEmailSending?: boolean;

  /**  */
  withOnHoldFee?: boolean;

  /**  */
  discountCode?: string;

  /**  */
  saveExcessPayment?: boolean;

  /**  */
  numberOfClips?: number;

  /**  */
  discountPercentage?: number;

  /**  */
  discountAmount?: number;

  /**  */
  priceAmount?: number;

  /**  */
  hasAutomaticRenewal?: boolean;
}

export interface UpdateSignUpDto {
  /**  */
  id?: number;

  /**  */
  status?: ParticipationStatus;
}

export interface UpdateWageDto {
  /**  */
  employeeId?: number;

  /**  */
  wageRateId?: number;

  /**  */
  payrollEmployeeId?: string;

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  id?: number;
}

export interface VoucherDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  remainingNumberOfClips?: number;

  /**  */
  numberOfClips?: number;

  /**  */
  usedNumberOfClips?: number;

  /**  */
  ownerId?: number;

  /**  */
  ownerName?: string;

  /**  */
  chainId?: number;

  /**  */
  chainName?: string;

  /**  */
  typeId?: number;

  /**  */
  typeName?: string;

  /**  */
  isGuestVoucher?: boolean;

  /**  */
  hasAutomaticRenewal?: boolean;
}

export interface VoucherTypeDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  chainId?: number;

  /**  */
  numberOfClips?: number;

  /**  */
  validityInMonths?: number;

  /**  */
  hasSetupFee?: boolean;

  /**  */
  setupFee?: number;

  /**  */
  taxRateId?: number;

  /**  */
  taxRate?: number;

  /**  */
  taxRateInclusive?: boolean;

  /**  */
  isActive?: boolean;

  /**  */
  createdAt?: DateTime;

  /**  */
  contractId?: number;

  /**  */
  welcomeEmailTemplateId?: number;

  /**  */
  isPublic?: boolean;

  /**  */
  hasAccessToClassIds?: number[];

  /**  */
  automaticClassRegistrationForClassIds?: number[];

  /**  */
  openingHours?: OpeningHour[];

  /**  */
  gymId?: number;

  /**  */
  hasAutomaticRenewal?: boolean;

  /**  */
  accountNumber?: number;

  /**  */
  billable?: BillableType;

  /**  */
  maximumNumberOfActiveClassRegistrations?: number;

  /**  */
  bookingInAdvanceAllowedForDays?: number;

  /**  */
  createdDate?: DateTime;

  /**  */
  shortDescription?: string;

  /**  */
  hasAccessToDoorSystem?: boolean;
}

export interface WeekDaysType {
  /**  */
  dayOfWeek?: DayOfWeek;

  /**  */
  coaches?: number[];
}

export interface WeekType {
  /**  */
  weekNumber?: number;

  /**  */
  coachId?: number;
}

export interface WidgetEntryDto {
  /**  */
  pointInTime?: string;

  /**  */
  value?: number;

  /**  */
  unit?: string;
}

export interface WidgetStatisticsDto {
  /**  */
  entries?: WidgetEntryDto[];

  /**  */
  value?: number;

  /**  */
  unit?: string;
}

export interface WorkoutDto {
  /**  */
  id?: number;

  /**  */
  title?: string;

  /**  */
  text?: string;

  /**  */
  json?: string;

  /**  */
  comment?: string;

  /**  */
  timecapInMinutes?: number;

  /**  */
  timecapInSeconds?: number;

  /**  */
  workoutTypeId?: number;

  /**  */
  workoutTypeName?: string;

  /**  */
  workoutTypeAbbreviation?: string;

  /**  */
  exercises?: ExerciseDto[];

  /**  */
  description?: string;

  /**  */
  coachesNotes?: string;
}

export interface WorkoutResultDto {
  /**  */
  workoutTitle?: string;

  /**  */
  workoutTypeName?: string;

  /**  */
  count?: number;

  /**  */
  time?: DateTime;

  /**  */
  createdDate?: DateTime;

  /**  */
  scaling?: WorkoutScaling;
}

export interface WorkoutTypeDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  abbreviation?: string;

  /**  */
  chainId?: number;

  /**  */
  createdDate?: DateTime;

  /**  */
  lastModifiedDate?: DateTime;
}

export interface YearlySalesDto {
  /**  */
  totalIncome?: WidgetEntryDto[];

  /**  */
  totalExpense?: WidgetEntryDto[];

  /**  */
  totalProfit?: WidgetEntryDto[];
}

export enum AccessSystemProviders {
  'OutScale' = 'OutScale',
  'Exivo' = 'Exivo',
  'JustFace' = 'JustFace',
  'Danalock' = 'Danalock'
}

export enum AdjustmentType {
  'Debit' = 'Debit',
  'Credit' = 'Credit'
}

export enum BillableType {
  'OneTime' = 'OneTime',
  'Monthly' = 'Monthly',
  'Quarterly' = 'Quarterly',
  'HalfYearly' = 'HalfYearly',
  'Yearly' = 'Yearly',
  'Voucher' = 'Voucher',
  'Never' = 'Never',
  'Weekly' = 'Weekly',
  'BiWeekly' = 'BiWeekly',
  'TriWeekly' = 'TriWeekly',
  'EveryFourthWeek' = 'EveryFourthWeek',
  'EveryTwelfthWeek' = 'EveryTwelfthWeek',
  'DropIn' = 'DropIn'
}

export enum BookkeepingProviders {
  'Billy' = 'Billy',
  'Dinero' = 'Dinero',
  'Economics' = 'Economics'
}

export enum ChartPeriodGroups {
  'Day' = 'Day',
  'Week' = 'Week',
  'Month' = 'Month',
  'Year' = 'Year'
}

export enum ChartSegments {
  'All' = 'All',
  'Gender' = 'Gender',
  'Age' = 'Age'
}

export enum ClassTemplateTypes {
  'Normal' = 'Normal',
  'Alternating' = 'Alternating',
  'SpecificDays' = 'SpecificDays'
}

export enum DayOfWeek {
  'Sunday' = 'Sunday',
  'Monday' = 'Monday',
  'Tuesday' = 'Tuesday',
  'Wednesday' = 'Wednesday',
  'Thursday' = 'Thursday',
  'Friday' = 'Friday',
  'Saturday' = 'Saturday'
}

export enum Direction {
  'Asc' = 'Asc',
  'Desc' = 'Desc'
}

export enum EmailStatus {
  'Accepted' = 'Accepted',
  'Rejected' = 'Rejected',
  'Delivered' = 'Delivered',
  'Failed' = 'Failed'
}

export enum EmailTemplateType {
  'WelcomeEmail' = 'WelcomeEmail',
  'WelcomeEmailExistingMember' = 'WelcomeEmailExistingMember',
  'FineWhenTooLate' = 'FineWhenTooLate',
  'FineWhenNeverMet' = 'FineWhenNeverMet',
  'Custom' = 'Custom',
  'Event' = 'Event',
  'PutOnHold' = 'PutOnHold',
  'Cancellation' = 'Cancellation',
  'Guest' = 'Guest',
  'Referral' = 'Referral',
  'UpdatedContract' = 'UpdatedContract',
  'MissingPayment' = 'MissingPayment',
  'TicketConfirmation' = 'TicketConfirmation',
  'DropIn' = 'DropIn'
}

export enum EntityStatuses {
  'Created' = 'Created',
  'Deleted' = 'Deleted',
  'Available' = 'Available'
}

export enum ExternalProgramProviders {
  'SugarWod' = 'SugarWod',
  'IceFit' = 'IceFit'
}

export enum ExternalSystemProviders {
  'Reepay' = 'Reepay',
  'Stripe' = 'Stripe',
  'Dibs' = 'Dibs',
  'Danalock' = 'Danalock',
  'Nets' = 'Nets',
  'Clearhaus' = 'Clearhaus',
  'Collectia' = 'Collectia'
}

export enum FineStatus {
  'Collected' = 'Collected',
  'New' = 'New'
}

export enum Frequency {
  'Weekly' = 'Weekly',
  'BiWeekly' = 'BiWeekly',
  'TriWeekly' = 'TriWeekly',
  'EveryFourthWeek' = 'EveryFourthWeek'
}

export enum Gender {
  'Male' = 'Male',
  'Female' = 'Female',
  'Unspecified' = 'Unspecified'
}

export enum InvoiceStatuses {
  'Draft' = 'Draft',
  'Open' = 'Open',
  'Paid' = 'Paid',
  'Uncollectible' = 'Uncollectible',
  'Void' = 'Void',
  'Inkasso' = 'Inkasso',
  'Expired' = 'Expired',
  'Authorized' = 'Authorized',
  'Failed' = 'Failed'
}

export enum LeadStatuses {
  'Won' = 'Won',
  'Lost' = 'Lost',
  'Potential' = 'Potential'
}

export enum LocationType {
  'Member' = 'Member',
  'Gym' = 'Gym',
  'Employee' = 'Employee',
  'Other' = 'Other'
}

export enum MemberEventType {
  'Comment' = 'Comment',
  'Email' = 'Email',
  'Notification' = 'Notification',
  'DoorAccess' = 'DoorAccess',
  'ClassRegistration' = 'ClassRegistration'
}

export enum MembershipStatuses {
  'InActive' = 'InActive',
  'Active' = 'Active',
  'MissingPayment' = 'MissingPayment',
  'OnHold' = 'OnHold',
  'Cancelled' = 'Cancelled'
}

export enum MembershipTypeTypes {
  'System' = 'System',
  'Normal' = 'Normal'
}

export enum MembershipUpdateTypes {
  'Cancel' = 'Cancel',
  'OnHold' = 'OnHold',
  'Resume' = 'Resume',
  'CancelOnHold' = 'CancelOnHold',
  'CancelCancellation' = 'CancelCancellation',
  'Edit' = 'Edit'
}

export enum MessageType {
  'Audio' = 'Audio',
  'Custom' = 'Custom',
  'File' = 'File',
  'Image' = 'Image',
  'System' = 'System',
  'Text' = 'Text',
  'Unsupported' = 'Unsupported',
  'Video' = 'Video'
}

export enum NewsStatus {
  'Draft' = 'Draft',
  'Published' = 'Published',
  'Planned' = 'Planned'
}

export enum NewsletterStatus {
  'Pending' = 'Pending',
  'Delivered' = 'Delivered',
  'Planned' = 'Planned',
  'Failure' = 'Failure'
}

export enum OptionTypeTypes {
  'Radio' = 'Radio',
  'Checkbox' = 'Checkbox',
  'Select' = 'Select',
  'Text' = 'Text'
}

export enum OrderEventStatuses {
  'Created' = 'Created',
  'Paid' = 'Paid',
  'PaymentFailed' = 'PaymentFailed',
  'SentToDebtCollection' = 'SentToDebtCollection',
  'ReminderSent' = 'ReminderSent',
  'Cancelled' = 'Cancelled'
}

export enum OrderStatuses {
  'Open' = 'Open',
  'Paid' = 'Paid',
  'PendingPayment' = 'PendingPayment',
  'Closed' = 'Closed',
  'Cancelled' = 'Cancelled',
  'MissingPayment' = 'MissingPayment',
  'SentToInkasso' = 'SentToInkasso',
  'Refunded' = 'Refunded'
}

export enum ParticipationStatus {
  'OnTime' = 'OnTime',
  'TooLate' = 'TooLate',
  'OptedOut' = 'OptedOut',
  'OptedOutTooLate' = 'OptedOutTooLate',
  'OnWaitingList' = 'OnWaitingList',
  'SignedUp' = 'SignedUp',
  'NeverMet' = 'NeverMet'
}

export enum PaymentMethods {
  'Cash' = 'Cash',
  'BankTransfer' = 'BankTransfer',
  'Check' = 'Check',
  'Other' = 'Other',
  'MobilePay' = 'MobilePay',
  'Card' = 'Card'
}

export enum PayrollSystem {
  'Danloen' = 'Danloen',
  'Bluegarden' = 'Bluegarden',
  'Generic' = 'Generic'
}
export type ProblemDetailsWithAdditionalProperties = ProblemDetails & { [additionalProperties: string]: any | null };
export enum PushNotificationStatus {
  'Pending' = 'Pending',
  'Delivered' = 'Delivered',
  'Planned' = 'Planned',
  'Failure' = 'Failure'
}

export enum ReminderTypes {
  'SMS' = 'SMS',
  'Email' = 'Email'
}

export enum SearchResultType {
  'Member' = 'Member',
'Invoice' = 'Invoice',
'Clazz' = 'Clazz',
}

export enum ShiftType {
  'PersonalTraining' = 'PersonalTraining',
  'Clazz' = 'Clazz',
  'Unspecified' = 'Unspecified'
}

export enum SubscriptionType {
  'Standard' = 'Standard',
  'Premium' = 'Premium',
  'Platinum' = 'Platinum'
}

export enum TrackingProviders {
  'Facebook' = 'Facebook'
}

export enum WorkoutScaling {
  'Rx' = 'Rx',
  'Scaled' = 'Scaled'
}
