import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// routes
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSnackbar } from 'src/components/snackbar';
import { useRouter } from 'src/routes/hook';
import FormProvider, { RHFSwitch, RHFTextField } from 'src/components/hook-form';
// types
import { ClassesService, ClassTemplateTypes, ClazzDto } from '../../api';
import { useLocales } from '../../locales';
import { useDispatch, useSelector } from '../../redux/store';
import { getStaff } from '../../redux/slices/employees';
import { getClassDescriptions } from '../../redux/slices/class-description';
import { getGyms } from '../../redux/slices/gym';

// ----------------------------------------------------------------------

type Props = {
  currentClazz?: ClazzDto;
};

export default function ClazzNewEditForm({ currentClazz }: Props) {
  const router = useRouter();

  const { t } = useLocales();

  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const instructors = useSelector((state) => state.employee.employees);

  const clazzDescriptions = useSelector((state) => state.classDescription.descriptions);

  const gyms = useSelector((state) => state.gym.gyms);

  useEffect(() => {
    dispatch(
      getStaff({
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
        isActive: true,
      })
    );
    dispatch(
      getClassDescriptions({
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
      })
    );
    dispatch(
      getGyms({
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
      })
    );
  }, [dispatch]);

  const [alertMessage, setAlertMessage] = useState('');

  const newSchema = Yup.object().shape({
    title: Yup.string().required(t('Title is required')),
    descriptionId: Yup.number().min(1, t('Class description is required')),
    gymId: Yup.number().min(1, t('Gym is required')),
    begins: Yup.date().required(t('Begins is required')),
    ends: Yup.date().test(
      'date-min',
      t('Ends must be later than begins'),
      (value, { parent }) => !value || value > parent.begins
    ),
    // not required
    subtitle: Yup.string(),
    isOpenGym: Yup.boolean(),
    closedClass: Yup.boolean(),
    finesEnabled: Yup.boolean(),
    isGuestBookingPossible: Yup.boolean(),
    releaseProgram: Yup.boolean(),
    coaches: Yup.array(),
    minutesBeforeProgramRelease: Yup.number()
      .default(undefined)
      .min(0, t('Has to greater than or equal to 0')),
    hoursBeforeProgramRelease: Yup.number()
      .default(undefined)
      .min(0, t('Has to greater than or equal to 0')),
    shiftDurationHours: Yup.number()
      .default(undefined)
      .min(0, t('Has to greater than or equal to 0')),
    shiftDurationMinutes: Yup.number()
      .default(undefined)
      .min(0, t('Has to greater than or equal to 0')),
    optOutTimeHours: Yup.number().default(undefined).min(0, t('Has to greater than or equal to 0')),
    optOutTimeMinutes: Yup.number()
      .default(undefined)
      .min(0, t('Has to greater than or equal to 0')),
    changeOptOutTime: Yup.boolean(),
    maximumNumberOfParticipants: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(0)
    ),
  });

  const defaultValues = useMemo(
    () =>
      ({
        title: currentClazz?.title || '',
        subtitle: currentClazz?.subtitle || '',
        descriptionId: currentClazz?.descriptionId || -1,
        gymId: currentClazz?.gymId || -1,
        maximumNumberOfParticipants: currentClazz?.capacity || '',
        begins: currentClazz?.begins || null,
        ends: currentClazz?.ends || null,
        isOpenGym: currentClazz?.isOpenGym ?? false,
        closedClass: currentClazz?.closedClass ?? false,
        finesEnabled: currentClazz?.finesEnabled ?? false,
        isGuestBookingPossible: currentClazz?.isGuestBookingPossible ?? false,
        releaseProgram: currentClazz?.releaseProgram ?? false,
        minutesBeforeProgramRelease: currentClazz?.minutesBeforeProgramRelease,
        hoursBeforeProgramRelease: currentClazz?.hoursBeforeProgramRelease,
        shiftDurationHours: currentClazz?.shiftDurationHours,
        shiftDurationMinutes: currentClazz?.shiftDurationMinutes,
        optOutTimeHours: currentClazz?.optOutTimeHours,
        optOutTimeMinutes: currentClazz?.optOutTimeMinutes,
        classDurationHours: currentClazz?.classDurationHours,
        classDurationMinutes: currentClazz?.classDurationMinutes,
        changeOptOutTime: false,
        coaches: currentClazz?.instructors?.map((x) => x.staffMemberId!) || [],
      }) as any,
    [currentClazz]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (currentClazz) {
      reset(defaultValues);
    }
  }, [currentClazz, defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      // Check if is edit mode
      if (currentClazz) {
        await ClassesService.edit({
          id: currentClazz!.id!,
          body: {
            id: currentClazz!.id!,
            ...data,
          } as any,
        });
      } else {
        await ClassesService.create({
          body: {
            ...data,
          } as any,
        });
      }

      reset();
      enqueueSnackbar(currentClazz ? t('Update success!') : t('Create success!'));
      router.back();
    } catch (error) {
      setAlertMessage(`${error.response?.data?.detail}`);
    }
  });

  const handleInstructorChange = (event: SelectChangeEvent<any[]>) => {
    const result =
      typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
    setValue('coaches', result, { shouldValidate: true });
  };

  const handleDescriptionChange = (event: SelectChangeEvent<any>) => {
    setValue('descriptionId', event.target.value, { shouldValidate: true });
  };

  const handleGymChange = (event: SelectChangeEvent<any>) => {
    setValue('gymId', event.target.value, { shouldValidate: true });
  };

  const planningTypes = [
    {
      value: ClassTemplateTypes.Normal,
      label: t(ClassTemplateTypes.Normal),
    },
    {
      value: ClassTemplateTypes.Alternating,
      label: t(ClassTemplateTypes.Alternating),
    },
    {
      value: ClassTemplateTypes.SpecificDays,
      label: t(ClassTemplateTypes.SpecificDays),
    },
  ];

  const renderAlert = (
    <>
      {alertMessage && (
        <Grid xs={12}>
          <Alert severity="error">{alertMessage}</Alert>
        </Grid>
      )}
    </>
  );

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Details')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Title, short description, image...')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Details')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Title')}</Typography>
              <RHFTextField name="title" placeholder={t('Ex: Endurance WOD')} />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Type')}</Typography>
              <Controller
                name="descriptionId"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <Select
                      value={values.descriptionId}
                      onChange={handleDescriptionChange}
                      autoWidth={false}
                    >
                      {clazzDescriptions.map((option) => (
                        <MenuItem key={`desc-${option.id}`} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Gym')}</Typography>
              <Controller
                name="gymId"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <Select value={values.gymId} onChange={handleGymChange} autoWidth={false}>
                      {gyms.map((option) => (
                        <MenuItem key={`gym-${option.id}`} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Maximum number of participants')}</Typography>
              <RHFTextField name="maximumNumberOfParticipants" />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderProperties = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Properties')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Sign up, held on...')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Properties')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Box
              columnGap={2}
              rowGap={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
              }}
            >
              <RHFSwitch name="isOpenGym" label={t('Is open gym')} />
              <RHFSwitch name="closedClass" label={t('Is a closed class')} />
              <RHFSwitch name="isPersonalTrainingOnly" label={t('Is personal training')} />
              <RHFSwitch name="finesEnabled" label={t('Enable fines')} />
              <RHFSwitch name="isGuestBookingPossible" label={t('Is guests allowed')} />
              <RHFSwitch name="releaseProgram" label={t('Release training program')} />
              <RHFSwitch name="changeOptOutTime" label={t('Change opt out time')} />
            </Box>
          </Stack>
        </Card>
      </Grid>

      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('When')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Price, number of tickets ...')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('When')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Box
              columnGap={2}
              rowGap={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
              }}
            >
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Class begins')}</Typography>
                <Controller
                  name="begins"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DateTimePicker
                      {...field}
                      value={values.begins}
                      ampm={false}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  )}
                />
              </Stack>
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Class ends')}</Typography>
                <Controller
                  name="ends"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DateTimePicker
                      {...field}
                      value={values.ends}
                      ampm={false}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  )}
                />
              </Stack>
            </Box>
            <Box
              columnGap={2}
              rowGap={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
              }}
            >
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Shift duration in hours')}</Typography>
                <RHFTextField name="shiftDurationHours" />
              </Stack>
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Shift duration in minutes')}</Typography>
                <RHFTextField name="shiftDurationMinutes" />
              </Stack>
              {values.releaseProgram && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('Hours before program release')}</Typography>
                  <RHFTextField name="hoursBeforeProgramRelease" />
                </Stack>
              )}
              {values.releaseProgram && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('Minutes before program release')}</Typography>
                  <RHFTextField name="minutesBeforeProgramRelease" />
                </Stack>
              )}
              {values.changeOptOutTime && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('Earliest opt out time in hours')}</Typography>
                  <RHFTextField name="optOutTimeHours" />
                </Stack>
              )}
              {values.changeOptOutTime && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">
                    {t('Earliest opt out time in minutes')}
                  </Typography>
                  <RHFTextField name="optOutTimeMinutes" />
                </Stack>
              )}
            </Box>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderInstructors = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Instructors')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Title, short description, image...')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Instructors" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Coaches')}</Typography>
              <Controller
                name="coaches"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <Select
                      multiple
                      value={values.coaches}
                      onChange={handleInstructorChange}
                      autoWidth={false}
                    >
                      {instructors.map((option) => (
                        <MenuItem key={`in-${option.id}`} value={option.id!}>
                          <Checkbox
                            disableRipple
                            size="small"
                            checked={values.coaches!.includes(option.id ?? -1)}
                          />
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, pl: 3 }} />

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          sx={{ ml: 2 }}
        >
          {!currentClazz ? t('Create') : t('Save Changes')}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderAlert}

        {renderDetails}

        {renderProperties}

        {renderInstructors}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
