import * as Yup from 'yup';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
// utils
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// types
// assets
// components
import { DateTime } from 'luxon';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { setError } from 'src/redux/slices/error';
import { Gender, GuestDto, GuestService } from '../../api';
import { useLocales } from '../../locales';
import { useDispatch, useSelector } from '../../redux/store';
import { getClasses } from '../../redux/slices/classes';
import { fDateTime } from '../../utils/format-time';

// ----------------------------------------------------------------------

type Props = {
  currentMember?: GuestDto;
};

export default function GuestNewEditForm({ currentMember }: Props) {
  const router = useRouter();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const classes = useSelector((state) => state.clazz.classes);

  const sortedClasses = [...classes];
  sortedClasses.sort((a, b) => a.begins!.toMillis() - b.begins!.toMillis());

  useEffect(() => {
    dispatch(
      getClasses({
        from: DateTime.now().startOf('day'),
        to: DateTime.now().startOf('day').plus({ day: 28 }),
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
      })
    );
  }, [dispatch]);

  const { enqueueSnackbar } = useSnackbar();

  const newSchema = Yup.object().shape({
    firstName: Yup.string().required(t('First name is required')),
    lastName: Yup.string().required(t('Last name is required')),
    middleName: Yup.string(),
    dateOfBirth: Yup.date().nullable(),
    phoneNumber: Yup.string().required(t('Phone number is required')),
    email: Yup.string()
      .required(t('Email is required'))
      .email(t('Email must be a valid email address')),
    gender: Yup.string().required(t('Gender is required')),
    addressLine: Yup.string(),
    city: Yup.string(),
    postalNumber: Yup.string(),
    country: Yup.string(),
    classId: Yup.number().min(1, t('Please select a class')),
  });

  const defaultValues = useMemo(
    () =>
      ({
        firstName: '',
        lastName: '',
        middleName: '',
        dateOfBirth: null,
        phoneNumber: '',
        email: '',
        gender: Gender.Female,
        addressLine: '',
        city: '',
        postalNumber: '',
        country: '',
        classId: -1,
      }) as any,
    []
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (currentMember) {
      reset(defaultValues);
    }
  }, [currentMember, defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const result = await GuestService.create({
        body: data as any,
      });

      reset();
      enqueueSnackbar(currentMember ? t('Update success!') : t('Guest is booked!'));
      router.push(paths.members.view(result.accountId!));
    } catch (error) {
      dispatch(setError(error));
    }
  });

  const handleGenderChange = (event: SelectChangeEvent<any>) => {
    setValue('gender', event.target.value, { shouldValidate: true });
  };

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
              }}
            >
              <RHFTextField name="firstName" label={t('First Name')} />
              <RHFTextField name="middleName" label={t('Middle Name')} />
              <RHFTextField name="lastName" label={t('Last Name')} />
            </Box>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              sx={{
                mt: 3,
              }}
            >
              <RHFTextField name="email" label={t('Email address')} />
              <RHFTextField name="phoneNumber" label={t('Phone Number')} />
              <Controller
                name="dateOfBirth"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    label={t('Date of birth')}
                    {...field}
                    value={field.value}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
              <Controller
                name="gender"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <InputLabel>{t('Gender')}</InputLabel>
                    <Select
                      label={t('Gender')}
                      value={values.gender}
                      onChange={handleGenderChange}
                      autoWidth={false}
                    >
                      <MenuItem key="Male" value="Male">
                        {t('Male')}
                      </MenuItem>
                      <MenuItem key="Female" value="Female">
                        {t('Female')}
                      </MenuItem>
                      <MenuItem key="Unspecified" value="Unspecified">
                        {t('Unspecified')}
                      </MenuItem>
                    </Select>
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />

              <RHFTextField name="addressLine" label={t('Address line')} />
              <RHFTextField name="postalNumber" label={t('Postal number')} />
              <RHFTextField name="city" label={t('City')} />
              <RHFTextField name="country" label={t('Country')} />
              <TextField
                select
                label={t('Class')}
                value={values.classId}
                onChange={(e) => setValue('classId', e.target.value, { shouldValidate: true })}
              >
                {sortedClasses.map((option) => (
                  <MenuItem key={`desc-${option.id}`} value={option.id}>
                    {`${option.title} (${fDateTime(option.begins)})`}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!currentMember ? t('Book guest') : t('Save Changes')}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
