import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useState } from 'react';
// @mui
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
import { RouterLink } from 'src/routes/components';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// _mock
// api
// components
import { DateTime } from 'luxon';
import { useSettingsContext } from 'src/components/settings';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  TableSkeleton,
  useTable,
} from 'src/components/table';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { saveAs } from 'file-saver';
// types
//
import PersonalTrainingTableRow from '../personal-training-table-row';
import PersonalTrainingTableToolbar from '../personal-training-table-toolbar';
import PersonalTrainingTableFiltersResult from '../personal-training-table-filters-result';
import { useDispatch, useSelector } from '../../../../redux/store';
import { Direction, getConfigs, serviceOptions } from '../../../../api';
import { useLocales } from '../../../../locales';
import { getPersonalTrainings } from '../../../../redux/slices/membership-type';
import {
  IMembershipTypeTableFilters,
  IMembershipTypeTableFilterValue,
} from '../../../../types/membership-type';

// ----------------------------------------------------------------------

const defaultFilters: IMembershipTypeTableFilters = {
  name: '',
  public: ['All'],
  stock: [],
  status: ['Active'],
  validOn: undefined,
};

// ----------------------------------------------------------------------

export default function PersonalTrainingListView() {
  const router = useRouter();

  const { t } = useLocales();

  const TABLE_HEAD = [
    { id: 'name', label: t('Name') },
    { id: 'publiclyAvailable', label: t('Publicly available') },
    { id: 'createdAt', label: t('Created at') },
    { id: 'inventoryType', label: t('Available seats') },
    { id: 'billable', label: t('Frequency') },
    { id: 'price', label: t('Price') },
    { id: '', width: 88 },
  ];

  const PUBLIC_OPTIONS = [
    { value: 'Yes', label: t('Yes') },
    { value: 'No', label: t('No') },
    { value: 'All', label: t('All') },
  ];

  const STATUS_OPTIONS = [
    { value: 'None', label: t('None') },
    { value: 'Active', label: t('Active') },
    { value: 'Inactive', label: t('Inactive') },
  ];

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onResetPage,
    setRowsPerPage,
    setOrderBy,
    setOrder,
    //
    selected,
    onSelectAllRows,
    onSelectRow,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: 'name',
  });

  const settings = useSettingsContext();

  const dispatch = useDispatch();

  const tableData = useSelector((state) => state.membershipType.personalTrainings);

  const totalNumberOfProducts = useSelector(
    (state) => state.membershipType.totalNumberOfPersonalTrainings
  );

  const productsLoading = useSelector((state) => state.membershipType.isLoading);

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const storedRowsPerPage = queryParams.get('rowsPerPage');
    const storedPage = queryParams.get('page');
    const storedOrderBy = queryParams.get('orderBy');
    const storedOrder = queryParams.get('order');
    const storedStatus = queryParams.get('status');
    const storedPublic = queryParams.get('public');
    const storedSearch = queryParams.get('search');
    const storedValidOnDate = queryParams.get('validOn')
      ? decodeURIComponent(queryParams.get('validOn')!)
      : null;

    setRowsPerPage(parseInt(storedRowsPerPage ?? '10', 10));
    setPage(parseInt(storedPage ?? '0', 10));
    setOrderBy(storedOrderBy ?? 'name');
    setOrder(storedOrder === 'asc' ? 'asc' : 'desc');

    setFilters({
      public: [storedPublic ?? 'All'],
      status: [storedStatus ?? 'Active'],
      stock: [],
      name: storedSearch ?? '',
      validOn:
        storedValidOnDate !== null
          ? DateTime.fromISO(storedValidOnDate, {
              zone: 'local',
            })
          : undefined,
    });
  }, [setOrder, setOrderBy, setPage, setRowsPerPage]);

  useEffect(() => {
    const newurl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?rowsPerPage=${rowsPerPage}&page=${page}&orderBy=${orderBy}&order=${order}&status=${
      filters.status
    }&name=${filters.name ?? ''}&status=${filters.status ?? ''}&public=${filters.public ?? ''}&validOn=${
      filters.validOn ? encodeURIComponent(filters.validOn.toISO({ includeOffset: false })!) : ''
    }`;
    window.history.replaceState({ path: newurl }, '', newurl);
  }, [rowsPerPage, page, filters, orderBy, order]);

  useEffect(() => {
    let isPublic = null;
    if (filters.public.length) {
      if (filters.public[0] === 'Yes') {
        isPublic = true;
      } else if (filters.public[0] === 'No') {
        isPublic = false;
      }
    }

    dispatch(
      getPersonalTrainings({
        pageSize: rowsPerPage,
        pageNumber: page,
        sortBy: [orderBy],
        direction: order === 'asc' ? Direction.Asc : Direction.Desc,
        isActive: getActiveSetting(filters),
        isPublic,
        validOn: filters.validOn,
        search: filters.name,
      })
    );
  }, [dispatch, rowsPerPage, page, filters, orderBy, order]);

  const confirm = useBoolean();

  const denseHeight = dense ? 60 : 80;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = !tableData.length && canReset;

  const handleFilters = useCallback(
    (name: string, value: IMembershipTypeTableFilterValue) => {
      onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [onResetPage]
  );

  const handleDeleteRow = useCallback(
    (id: number) => {
      const deleteRow = tableData.filter((row) => row.id !== id);
    },
    [tableData]
  );

  const handleEditRow = useCallback(
    (id: number) => {
      router.push(paths.personalTrainingMembershipType.edit(id));
    },
    [router]
  );

  const handleExport = useCallback(() => {
    const action = async () => {
      const url = '/api/personal-training-types';

      const configs = getConfigs('get', 'application/json', url, {
        headers: {
          Accept: 'text/csv',
        },
      });

      let isPublic = null;
      if (filters.public.length) {
        if (filters.public[0] === 'Yes') {
          isPublic = true;
        } else if (filters.public[0] === 'No') {
          isPublic = false;
        }
      }

      configs.params = {
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        isActive: filters.status.length ? filters.status[0] === 'Active' : undefined,
        isPublic,
        validOn: filters.validOn,
        search: filters.name,
      };
      const resp = await serviceOptions.axios!.request(configs);

      // Ignore type, because API can return multiple types
      const blob = new Blob([resp.data as any], {
        type: 'text/csv',
      });
      saveAs(blob, `result.csv`);
    };
    action();
  }, [filters]);

  const handleViewRow = useCallback(
    (id: number) => {
      router.push(paths.personalTrainingMembershipType.details(id));
    },
    [router]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading={t('Personal training type List')}
          links={[
            { name: t('Dashboard'), href: paths.dashboard.root },
            {
              name: t('Personal training type'),
              href: paths.personalTrainingMembershipType.root,
            },
            { name: t('List') },
          ]}
          action={
            <Button
              component={RouterLink}
              href={paths.personalTrainingMembershipType.new}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              {t('New personal training')}
            </Button>
          }
          sx={{ mb: { xs: 3, md: 5 } }}
        />

        <Card>
          <PersonalTrainingTableToolbar
            filters={filters}
            onFilters={handleFilters}
            //
            publicOptions={PUBLIC_OPTIONS}
            statusOptions={STATUS_OPTIONS}
            onExport={handleExport}
          />

          {canReset && (
            <PersonalTrainingTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              onResetFilters={handleResetFilters}
              //
              results={totalNumberOfProducts}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) => {}}
              action={
                <Tooltip title={t('Delete')}>
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                />

                <TableBody>
                  {productsLoading ? (
                    [...Array(rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: denseHeight }} />
                    ))
                  ) : (
                    <>
                      {tableData?.map((row) => (
                        <PersonalTrainingTableRow
                          key={row.id}
                          row={row}
                          onDeleteRow={() => handleDeleteRow(row.id!)}
                          onEditRow={() => handleEditRow(row.id!)}
                          onViewRow={() => handleViewRow(row.id!)}
                        />
                      ))}
                    </>
                  )}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalNumberOfProducts}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Delete')}
        content={
          <>
            Are you sure want to delete <strong> {selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              confirm.onFalse();
            }}
          >
            {t('Delete')}
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

const getActiveSetting = (filters: IMembershipTypeTableFilters) => {
  if (filters.status.length) {
    if (filters.status[0] === 'Active') {
      return true;
    }
    if (filters.status[0] === 'Inactive') {
      return false;
    }
  }
  return undefined;
};
