import { useMemo, useRef } from 'react';
// @mui
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import LoadingButton from '@mui/lab/LoadingButton';
// utils
import { fNumber } from 'src/utils/format-number';
// types
// components
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { enqueueSnackbar } from 'notistack';
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
//
import { setError } from 'src/redux/slices/error';
import EventItem from './event-item';
import { AccountDto, CommentDto, MemberEventDto, MembersService, MemberStatsDto } from '../../api';
import { useLocales } from '../../locales';
import { getMemberComments, getMemberEvents } from '../../redux/slices/members';
import { useDispatch } from '../../redux/store';
import { toBase64 } from '../../utils/files';
import { fDate } from '../../utils/format-time';
import { paths } from '../../routes/paths';
import ProfileCommentItem from './profile-comment-item';

// ----------------------------------------------------------------------

type Props = {
  member: AccountDto;
  events: MemberEventDto[];
  comments: CommentDto[];
  stats: MemberStatsDto;
};

export default function ProfileHome({ member, events, stats, comments }: Props) {
  const fileRef = useRef<HTMLInputElement>(null);

  const { t } = useLocales();

  const NewCommentSchema = Yup.object().shape({
    body: Yup.string().required(t('Body is required')),
    file: Yup.mixed(),
  });

  const defaultValues = useMemo(
    () => ({
      body: '',
      file: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewCommentSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleAttach = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const dispatch = useDispatch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      if ((fileRef.current?.files?.length ?? 0) > 0) {
        const file = fileRef.current!.files![0];

        const fileData = await toBase64(file);

        await MembersService.createComment({
          id: member.id!,
          body: {
            ...data,
            fileData: fileData?.toString(),
          },
        });
      } else {
        await MembersService.createComment({
          id: member.id!,
          body: data as any,
        });
      }

      reset();
      enqueueSnackbar(t('Comment created!'));

      dispatch(getMemberEvents(member.id!));
      dispatch(getMemberComments(member.id!));
    } catch (error) {
      dispatch(setError(error));
    }
  });

  const sortedEvents = [...events];
  sortedEvents.sort((a, b) => b.timestamp!.toMillis() - a.timestamp!.toMillis());

  const sortedComments = [...comments];
  sortedComments.sort((a, b) => b.createdDate!.toMillis() - a.createdDate!.toMillis());

  const renderStats = (
    <Card sx={{ py: 3, textAlign: 'center', typography: 'h4' }}>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
      >
        <Stack width={1}>
          {fNumber(stats.numberOfClassRegistrations ?? 0)}
          <Box component="span" sx={{ color: 'text.secondary', typography: 'body2' }}>
            {t('Participations')}
          </Box>
        </Stack>

        <Stack width={1}>
          {fNumber(stats.numberOfTrainings ?? 0)}
          <Box component="span" sx={{ color: 'text.secondary', typography: 'body2' }}>
            {t('Trainings')}
          </Box>
        </Stack>
      </Stack>
    </Card>
  );

  const renderAbout = (
    <Card>
      <CardHeader title={t('About')} />

      <Stack spacing={2} sx={{ p: 3 }}>
        {member.address != null ? (
          <Stack direction="row" spacing={2}>
            <Iconify icon="mingcute:location-fill" width={24} />

            <Box sx={{ typography: 'body2' }}>
              {t('Live at ')}
              <Link variant="subtitle2" color="inherit">
                {`${member.address?.addressLine}, ${member.address?.postalNumber} ${member.address?.city}, ${member.address?.country}`}
              </Link>
            </Box>
          </Stack>
        ) : null}

        <Stack direction="row" sx={{ typography: 'body2' }}>
          <Iconify icon="fluent:mail-24-filled" width={24} sx={{ mr: 2 }} />
          {member.email}
        </Stack>

        <Stack direction="row" sx={{ typography: 'body2' }}>
          <Iconify icon="fluent:phone-24-filled" width={24} sx={{ mr: 2 }} />
          {member.phoneNumber}
        </Stack>

        {member.dateOfBirth && (
          <Stack direction="row" sx={{ typography: 'body2' }}>
            <Iconify icon="fluent-emoji-high-contrast:birthday-cake" width={24} sx={{ mr: 2 }} />
            {fDate(member.dateOfBirth!, 'PPP')}
          </Stack>
        )}

        {member.primaryAccount && (
          <Stack direction="row" sx={{ typography: 'body2' }}>
            <Link href={paths.members.view(member.primaryAccount!.id!)}>
              <Stack direction="row" sx={{ typography: 'body2' }}>
                <Iconify icon="raphael:parent" width={24} sx={{ mr: 2 }} />
                {member.primaryAccount?.fullName}
              </Stack>
            </Link>
          </Stack>
        )}
      </Stack>
    </Card>
  );

  const renderCommentInput = (
    <Card sx={{ p: 3 }}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <RHFTextField
          name="body"
          multiline
          fullWidth
          rows={4}
          placeholder={t('Share what you are thinking here...')}
          sx={{
            mb: 3,
          }}
        />

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={1} alignItems="center" sx={{ color: 'text.secondary' }}>
            <Fab size="small" color="inherit" variant="softExtended" onClick={handleAttach}>
              <Iconify icon="solar:gallery-wide-bold" width={24} sx={{ color: 'success.main' }} />
              {t('File')}
            </Fab>
          </Stack>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {t('Comment')}
          </LoadingButton>
        </Stack>

        <input ref={fileRef} type="file" style={{ display: 'none' }} />
      </FormProvider>
    </Card>
  );

  return (
    <Grid container spacing={3}>
      <Grid xs={12} md={4}>
        <Stack spacing={3}>
          {renderStats}

          {renderAbout}

          {sortedComments.map((event) => (
            <ProfileCommentItem
              key={`${event.title}-${event.createdDate}`}
              comment={event}
              member={member}
            />
          ))}
        </Stack>
      </Grid>

      <Grid xs={12} md={8}>
        <Stack spacing={3}>
          {renderCommentInput}

          {sortedEvents.map((event) => (
            <EventItem
              key={`${event.title}-${event.timestamp}`}
              event={event}
              member={member}
            />
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
}
