import React from 'react';
// @mui
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import CardHeader from '@mui/material/CardHeader';
// routes
// utils
import { fDate } from 'src/utils/format-time';
import { fCurrency } from 'src/utils/format-number';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import { AccountCreditBalanceEntryDto, AccountDto, AdjustmentType } from '../../api';
import { useLocales } from '../../locales';
import { getMemberBalance } from '../../redux/slices/members';
import { useDispatch, useSelector } from '../../redux/store';
import AdjustBalanceForm from './adjust-balance-form';

// ----------------------------------------------------------------------

type Props = {
  member: AccountDto;
  entries: AccountCreditBalanceEntryDto[];
};

export default function AccountBalanceHistory({ member, entries }: Props) {
  const showMore = useBoolean();

  const { t } = useLocales();

  const settings = useSelector((state) => state.chain.currentSettings);

  const orderedEntries = [...entries];
  orderedEntries.sort((x, y) => {
    const first = x.createdDate!.toJSDate();
    const second = y.createdDate!.toJSDate();
    return second.getTime() - first.getTime();
  });

  const dispatch = useDispatch();

  const addMembershipForm = useBoolean();

  const totalBalance = entries
    .map((entry) =>
      entry.adjustmentType === AdjustmentType.Credit ? -(entry.amount ?? 0) : (entry.amount ?? 0)
    )
    .reduce((x, y) => x + y, 0);

  const handleAdjustBalance = () => {
    dispatch(getMemberBalance(member.id!));
  };

  return (
    <>
      <Card>
        <CardHeader
          title={t('Credit balance')}
          action={
            <Button size="small" color="primary" onClick={addMembershipForm.onTrue}>
              {t('Edit')}
            </Button>
          }
        />
        <Stack spacing={1.5} sx={{ px: 3, pt: 3 }}>
          <Typography variant="h3">{fCurrency(totalBalance, settings.defaultCurrency)}</Typography>
        </Stack>

        <Stack spacing={1.5} sx={{ px: 3, pt: 3 }}>
          {(showMore.value ? orderedEntries : orderedEntries.slice(0, 8)).map((entry) => (
            <Stack key={entry.id} direction="row">
              <ListItemText
                primary={`${fCurrency(
                  entry.adjustmentType === AdjustmentType.Credit ? -entry.amount! : entry.amount,
                  entry.currency
                )}`}
                secondary={entry.note}
                primaryTypographyProps={{
                  typography: 'body2',
                }}
                secondaryTypographyProps={{
                  mt: 0.5,
                  component: 'span',
                  typography: 'caption',
                  color: 'text.disabled',
                }}
              />
              <Typography
                variant="body2"
                color="text.disabled"
                sx={{
                  fontSize: '0.75rem',
                }}
              >
                {fDate(entry.createdDate)}
              </Typography>
            </Stack>
          ))}

          <Divider sx={{ borderStyle: 'dashed' }} />
        </Stack>

        <Stack alignItems="flex-start" sx={{ p: 2 }}>
          <Button
            size="small"
            color="inherit"
            startIcon={
              <Iconify
                icon={showMore.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
              />
            }
            onClick={showMore.onToggle}
          >
            {showMore.value ? t(`Show Less`) : t(`Show More`)}
          </Button>
        </Stack>
      </Card>
      <AdjustBalanceForm
        open={addMembershipForm.value}
        onClose={addMembershipForm.onFalse}
        onCreate={handleAdjustBalance}
        member={member}
      />
    </>
  );
}
