import * as Yup from 'yup';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// components
import { DateTime } from 'luxon';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFMultiSelect,
  RHFSwitch,
  RHFTextField,
  RHFUpload,
} from 'src/components/hook-form';
// types
import {
  AlternatingPlanningType,
  BillableType,
  ClassTemplateDto,
  ClassTemplatesService,
  ClassTemplateTypes,
  DayOfWeek,
  Direction,
  EventService,
  Frequency,
  NormalPlanningType,
  SpecificDayPlanningType,
  WeekDaysType,
  WeekType,
} from '../../api';
import { useLocales } from '../../locales';
import { useDispatch, useSelector } from '../../redux/store';
import { getStaff } from '../../redux/slices/employees';
import { getClassDescriptions } from '../../redux/slices/class-description';
import { getGyms, getLocations } from '../../redux/slices/gym';
import { getMembers } from '../../redux/slices/members';
import { getChainSettings } from '../../redux/slices/chain';

// ----------------------------------------------------------------------

type Props = {
  currentTemplate?: ClassTemplateDto;
};

export default function ClassTemplateNewEditForm({ currentTemplate }: Props) {
  const router = useRouter();

  const { t } = useLocales();

  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const instructors = useSelector((state) => state.employee.employees);

  const clazzDescriptions = useSelector((state) => state.classDescription.descriptions);

  const gyms = useSelector((state) => state.gym.gyms);

  const chainSettings = useSelector((state) => state.chain.currentSettings);

  const locations = useSelector((state) => state.gym.locations);

  const members = useSelector((state) => state.member.members);

  useEffect(() => {
    dispatch(
      getStaff({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        isActive: true,
      })
    );
    dispatch(
      getClassDescriptions({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
      })
    );
    dispatch(getChainSettings());
    dispatch(
      getGyms({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
      })
    );
    dispatch(
      getLocations({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
      })
    );
    dispatch(
      getMembers({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        sortBy: ['firstName'],
        direction: Direction.Asc,
        isActive: true,
      })
    );
  }, [dispatch]);

  const [alertMessage, setAlertMessage] = useState('');

  const weekDays = [
    {
      value: DayOfWeek.Monday,
      label: t(DayOfWeek.Monday),
    },
    {
      value: DayOfWeek.Tuesday,
      label: t(DayOfWeek.Tuesday),
    },
    {
      value: DayOfWeek.Wednesday,
      label: t(DayOfWeek.Wednesday),
    },
    {
      value: DayOfWeek.Thursday,
      label: t(DayOfWeek.Thursday),
    },
    {
      value: DayOfWeek.Friday,
      label: t(DayOfWeek.Friday),
    },
    {
      value: DayOfWeek.Saturday,
      label: t(DayOfWeek.Saturday),
    },
    {
      value: DayOfWeek.Sunday,
      label: t(DayOfWeek.Sunday),
    },
  ];

  const newSchema = Yup.object().shape({
    title: Yup.string().required(t('Title is required')),
    descriptionId: Yup.number().min(1, t('Class description is required')),
    gymId: Yup.number().min(1, t('Gym is required')),
    locationId: Yup.number(),
    weekDays: Yup.array().min(1, t('Day of weeks is required')),
    autoSignUpParticipantIds: Yup.array(),
    type: Yup.mixed<ClassTemplateTypes>().required(t('Planning type is required')),
    images: Yup.array().min(0, t('Images is required')),
    begins: Yup.mixed<any>()
      .nullable()
      .required(t('Begins is required'))
      .test('is-set', t('Begins is required'), (value, { parent }) => value),
    classDurationHours: Yup.number()
      .default(undefined)
      .min(0, t('Has to greater than or equal to 0'))
      .test('is-set', t('Duration in hours is required'), (value, { parent }) =>
        Number.isInteger(value)
      ),
    classDurationMinutes: Yup.number()
      .default(undefined)
      .min(0, t('Has to greater than or equal to 0'))
      .test('is-set', t('Duration in minutes is required'), (value, { parent }) =>
        Number.isInteger(value)
      ),
    validFrom: Yup.mixed<any>()
      .required(t('Planning starts is required'))
      .test('is-set', t('Planning starts is required'), (value, { parent }) => value),
    validTo: Yup.mixed<any>()
      .nullable()
      .test(
        'date-min',
        t('Planning ends must be later than valid from'),
        (value, { parent }) => !value || value > new Date(parent.validFrom).getTime()
      ),
    // not required
    minimumNumberOfRegistrationsPerClass: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(1)
    ),
    subtitle: Yup.string(),
    isOpenGym: Yup.boolean(),
    closedClass: Yup.boolean(),
    isPersonalTrainingOnly: Yup.boolean(),
    finesEnabled: Yup.boolean(),
    isGuestBookingPossible: Yup.boolean(),
    automaticCancellingOfClasses: Yup.boolean(),
    releaseProgram: Yup.boolean(),
    normal: Yup.mixed<NormalPlanningType>(),
    frequency: Yup.mixed<Frequency>(),
    minutesBeforeProgramRelease: Yup.number()
      .default(undefined)
      .min(0, t('Has to greater than or equal to 0')),
    hoursBeforeProgramRelease: Yup.number()
      .default(undefined)
      .min(0, t('Has to greater than or equal to 0')),
    shiftDurationHours: Yup.lazy((value) =>
      value === ''
        ? Yup.string().default('')
        : Yup.number().default(undefined).min(0, t('Has to greater than or equal to 0'))
    ),
    shiftDurationMinutes: Yup.lazy((value) =>
      value === ''
        ? Yup.string().default('')
        : Yup.number().default(undefined).min(0, t('Has to greater than or equal to 0'))
    ),
    optOutTimeHours: Yup.lazy((value) =>
      value === ''
        ? Yup.string().default('')
        : Yup.number().default(undefined).min(0, t('Has to greater than or equal to 0'))
    ),
    optOutTimeMinutes: Yup.lazy((value) =>
      value === ''
        ? Yup.string().default('')
        : Yup.number().default(undefined).min(0, t('Has to greater than or equal to 0'))
    ),
    changeOptOutTime: Yup.boolean(),
    alternating: Yup.mixed<AlternatingPlanningType>(),
    specificDays: Yup.mixed<SpecificDayPlanningType>(),
    maximumNumberOfParticipants: Yup.number().min(0),
  });

  const defaultValues = useMemo(
    () => ({
      title: currentTemplate?.title || '',
      subtitle: currentTemplate?.subtitle || '',
      descriptionId:
        currentTemplate?.descriptionId ||
        (clazzDescriptions.length > 0 ? clazzDescriptions[0].id : -1),
      gymId: currentTemplate?.gymId || (gyms.length > 0 ? gyms[0].id : -1),
      locationId: currentTemplate?.locationId || (locations.length > 0 ? locations[0].id : -1),
      begins: currentTemplate?.begins !== undefined ? currentTemplate?.begins : null,
      validFrom: DateTime.now(),
      validTo: currentTemplate?.validTo || null,
      isOpenGym: currentTemplate?.isOpenGym ?? false,
      closedClass: currentTemplate?.closedClass ?? false,
      isPersonalTrainingOnly: currentTemplate?.isPersonalTrainingOnly ?? false,
      finesEnabled: currentTemplate?.finesEnabled ?? false,
      isGuestBookingPossible: currentTemplate?.isGuestBookingPossible ?? false,
      releaseProgram: currentTemplate?.releaseProgram ?? false,
      images: currentTemplate?.images?.map((e) => e.url) ?? [],
      weekDays: currentTemplate?.weekDays || [],
      type: currentTemplate?.type || ClassTemplateTypes.Normal,
      normal: currentTemplate?.normal || ({ coaches: [] } as NormalPlanningType),
      autoSignUpParticipantIds: currentTemplate?.autoSignUpParticipantIds || [],
      minutesBeforeProgramRelease: currentTemplate?.minutesBeforeProgramRelease,
      hoursBeforeProgramRelease: currentTemplate?.hoursBeforeProgramRelease,
      shiftDurationHours: currentTemplate?.shiftDurationHours,
      shiftDurationMinutes: currentTemplate?.shiftDurationMinutes,
      optOutTimeHours: currentTemplate?.optOutTimeHours,
      optOutTimeMinutes: currentTemplate?.optOutTimeMinutes,
      classDurationHours: currentTemplate?.classDurationHours ?? 1,
      classDurationMinutes: currentTemplate?.classDurationMinutes ?? 0,
      automaticCancellingOfClasses: currentTemplate?.automaticCancellingOfClasses ?? false,
      frequency: currentTemplate?.frequency ?? Frequency.Weekly,
      changeOptOutTime:
        currentTemplate?.optOutTimeHours !== undefined ||
        currentTemplate?.optOutTimeMinutes !== undefined,
      maximumNumberOfParticipants: currentTemplate?.maximumNumberOfParticipants || 16,
      minimumNumberOfRegistrationsPerClass:
        currentTemplate?.minimumNumberOfRegistrationsPerClass ||
        chainSettings?.minimumNumberOfRegistrationsPerClass ||
        '',
      alternating:
        currentTemplate?.alternating ||
        ({
          weeks: [
            {
              weekNumber: 1,
              coachId: -1,
            },
          ],
        } as AlternatingPlanningType),
      specificDays:
        currentTemplate?.specificDays ||
        ({
          weekDays: [],
        } as SpecificDayPlanningType),
    }),
    [currentTemplate, gyms, locations, clazzDescriptions, chainSettings]
  );

  const [numberOfWeeks, setNumberOfWeeks] = useState(
    currentTemplate?.alternating?.weeks?.length || 1
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (currentTemplate) {
      reset(defaultValues);
    }
    if (gyms.length > 0) {
      reset(defaultValues);
    }
    if (clazzDescriptions.length > 0) {
      reset(defaultValues);
    }
    if (members.length > 0) {
      reset(defaultValues);
    }
  }, [currentTemplate, gyms, clazzDescriptions, members, defaultValues, reset]);

  useEffect(() => {
    dispatch(
      getMembers({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        sortBy: ['firstName'],
        direction: Direction.Asc,
        isActive: true,
      })
    );
  }, [dispatch]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      // Check if is edit mode
      if (currentTemplate) {
        await ClassTemplatesService.edit({
          id: currentTemplate!.id!,
          body: {
            id: currentTemplate!.id!,
            ...data,
            images: null,
            begins: data.begins!,
          } as any,
        });
        await ClassTemplatesService.setBackgroundImage({
          id: currentTemplate!.id!,
          files: data.images?.filter((x) => typeof x !== 'string') ?? [],
        });
      } else {
        const newEvent = await ClassTemplatesService.create({
          body: {
            ...data,
            images: null,
            begins: data.begins!,
          } as any,
        });
        await ClassTemplatesService.setBackgroundImage({
          id: newEvent.id!,
          files: data.images?.filter((x) => typeof x !== 'string') ?? [],
        });
      }

      reset();
      enqueueSnackbar(currentTemplate ? t('Update success!') : t('Create success!'));
      router.push(paths.classTemplates.root);
    } catch (error) {
      setAlertMessage(`${error.response?.data?.detail}`);
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const files = values.images || [];

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setValue('images', [...files, ...newFiles], { shouldValidate: true });
    },
    [setValue, values.images]
  );

  const handleRemoveFile = useCallback(
    (inputFile: File | string) => {
      const deleted = currentTemplate?.images?.filter((file) => file.url === inputFile) ?? [];
      for (let i = 0; i < deleted.length; i += 1) {
        const image = deleted[i];
        if (image) {
          EventService.removeImage({ eventId: currentTemplate!.id!, imageId: image?.id! });
        }
      }

      const filtered = values.images?.filter((file) => file !== inputFile) ?? [];
      setValue('images', filtered);
    },
    [setValue, currentTemplate, values.images]
  );

  const handleRemoveAllFiles = useCallback(() => {
    const deleted = currentTemplate?.images ?? [];
    for (let i = 0; i < deleted.length; i += 1) {
      const image = deleted[i];
      if (image) {
        EventService.removeImage({ eventId: currentTemplate!.id!, imageId: image?.id! });
      }
    }
    setValue('images', []);
  }, [setValue, currentTemplate]);

  const handleWeekDayChange = (event: SelectChangeEvent<any[]>) => {
    const result =
      typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
    setValue('weekDays', result, { shouldValidate: true });

    values.specificDays!.weekDays = result.map((e) => ({
      dayOfWeek: e,
      coaches: [],
    }));
    setValue('specificDays', { weekDays: values.specificDays!.weekDays }, { shouldValidate: true });
  };

  const handleAutoSignUpParticipantIdsChange = (event: SelectChangeEvent<any[]>) => {
    const result =
      typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
    setValue('autoSignUpParticipantIds', result, { shouldValidate: true });
  };

  const handleInstructorChange = (event: SelectChangeEvent<any[]>) => {
    const result =
      typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
    setValue('normal', { coaches: result }, { shouldValidate: true });
  };

  const handleAlternatingInstructorChange = (event: SelectChangeEvent<any>, week: WeekType) => {
    const currentWeek = values.alternating!.weeks!.find((e) => e.weekNumber === week.weekNumber);
    if (currentWeek) {
      currentWeek!.coachId = parseInt(event.target.value, 10);
    }

    setValue('alternating', { weeks: values.alternating?.weeks }, { shouldValidate: true });
  };

  const handleSpecificDayInstructorChange = (
    event: SelectChangeEvent<any[]>,
    day: WeekDaysType
  ) => {
    const result =
      typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;

    let currentDay = values.specificDays!.weekDays!.find((e) => e.dayOfWeek === day.dayOfWeek);
    if (currentDay) {
      currentDay!.coaches = result;
    } else {
      currentDay = {
        dayOfWeek: day.dayOfWeek,
        coaches: result,
      };
      values.specificDays!.weekDays!.push(currentDay);
    }

    setValue('specificDays', { weekDays: values.specificDays!.weekDays }, { shouldValidate: true });
  };

  const handleClassDurationHoursChange = (event: ChangeEvent<any>) => {
    setValue('classDurationHours', event.target.value, { shouldValidate: true });
  };

  const handleClassDurationMinutesChange = (event: ChangeEvent<any>) => {
    setValue('classDurationMinutes', event.target.value, { shouldValidate: true });
  };

  const handleTypeChange = (event: SelectChangeEvent<any>) => {
    setValue('type', event.target.value, { shouldValidate: true });
  };

  const handleFrequencyChange = (event: SelectChangeEvent<any>) => {
    setValue('frequency', event.target.value, { shouldValidate: true });
  };

  const handleDescriptionChange = (event: SelectChangeEvent<any>) => {
    setValue('descriptionId', event.target.value, { shouldValidate: true });
  };

  const handleGymChange = (event: SelectChangeEvent<any>) => {
    setValue('gymId', event.target.value, { shouldValidate: true });
  };

  const handleLocationChange = (event: SelectChangeEvent<any>) => {
    setValue('locationId', event.target.value, { shouldValidate: true });
  };

  const handleNumberOfWeekChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentLength = values.alternating?.weeks?.length ?? 0;
    let newNumberOfWeeks = parseInt(event.target.value, 10);

    if (Number.isNaN(newNumberOfWeeks)) {
      newNumberOfWeeks = 0;
    }

    setNumberOfWeeks(newNumberOfWeeks);

    if (newNumberOfWeeks > currentLength) {
      for (let i = currentLength; i < newNumberOfWeeks; i += 1)
        values.alternating?.weeks?.push({
          weekNumber: i + 1,
          coachId: -1,
        });
    } else {
      values.alternating!.weeks = values.alternating?.weeks?.splice(0, newNumberOfWeeks);
    }
  };

  const frequencyTypes = [
    {
      value: Frequency.Weekly,
      label: t(Frequency.Weekly),
    },
    {
      value: Frequency.BiWeekly,
      label: t(Frequency.BiWeekly),
    },
    {
      value: Frequency.TriWeekly,
      label: t(Frequency.TriWeekly),
    },
    {
      value: Frequency.EveryFourthWeek,
      label: t(Frequency.EveryFourthWeek),
    },
  ];

  const planningTypes = [
    {
      value: ClassTemplateTypes.Normal,
      label: t(ClassTemplateTypes.Normal),
    },
    {
      value: ClassTemplateTypes.Alternating,
      label: t(ClassTemplateTypes.Alternating),
    },
    {
      value: ClassTemplateTypes.SpecificDays,
      label: t(ClassTemplateTypes.SpecificDays),
    },
  ];

  const renderAlert = (
    <>
      {alertMessage && (
        <Grid xs={12}>
          <Alert severity="error">{alertMessage}</Alert>
        </Grid>
      )}
    </>
  );

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Details')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t(
              'Here you fill out title, type, gym, and select images. Images are used for backgrounds in the mobile app and other places.'
            )}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Details')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Title')}</Typography>
              <RHFTextField name="title" placeholder={t('Ex: Endurance WOD')} />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Subtitle')}</Typography>
              <RHFTextField name="subtitle" placeholder={t('Ex: Hardest ever')} />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Type')}</Typography>
              <Controller
                name="descriptionId"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <Select
                      value={values.descriptionId}
                      onChange={handleDescriptionChange}
                      autoWidth={false}
                    >
                      {clazzDescriptions.map((option) => (
                        <MenuItem key={`desc-${option.id}`} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Gym')}</Typography>
              <Controller
                name="gymId"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <Select value={values.gymId} onChange={handleGymChange} autoWidth={false}>
                      {gyms.map((option) => (
                        <MenuItem key={`gym-${option.id}`} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Location')}</Typography>
              <Controller
                name="locationId"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <Select
                      value={values.locationId}
                      onChange={handleLocationChange}
                      autoWidth={false}
                    >
                      {locations.map((option) => (
                        <MenuItem key={`location-${option.id}`} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Maximum number of participants')}</Typography>
              <RHFTextField name="maximumNumberOfParticipants" />
            </Stack>

            {values.automaticCancellingOfClasses && (
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">
                  {t('Minimum number of participants per class')}
                </Typography>
                <RHFTextField name="minimumNumberOfRegistrationsPerClass" />
              </Stack>
            )}

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Images')}</Typography>
              <RHFUpload
                multiple
                thumbnail
                name="images"
                maxSize={3145728}
                onDrop={handleDrop}
                onRemove={handleRemoveFile}
                onRemoveAll={handleRemoveAllFiles}
              />
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('Please use af format of 4:3. Recommended size 2048 × 1536 pixels.')}
              </Typography>
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderProperties = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Settings')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t(
              'Here you can change the default settings. When enabling settings like Change opt out time, the time settings appear in the When section.'
            )}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Settings')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Box
              columnGap={2}
              rowGap={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
              }}
            >
              <RHFSwitch name="isOpenGym" label={t('Is open gym')} />
              <RHFSwitch name="closedClass" label={t('Is a closed class')} />
              <RHFSwitch name="isPersonalTrainingOnly" label={t('Is personal training')} />
              <RHFSwitch name="finesEnabled" label={t('Enable fines')} />
              <RHFSwitch name="isGuestBookingPossible" label={t('Is guests allowed')} />
              <RHFSwitch name="releaseProgram" label={t('Release training program')} />
              <RHFSwitch name="changeOptOutTime" label={t('Change opt out time')} />
              <RHFSwitch
                name="automaticCancellingOfClasses"
                label={t('Automatic cancelling of classes')}
              />
            </Box>
          </Stack>
        </Card>
      </Grid>

      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('When')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t(
              'Here you select which days of the week the planning is for, when the class begins, and how long the class is. You also have the possibility to change how long the shift is, and what the opt time and program release times are.'
            )}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('When')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Box
              columnGap={2}
              rowGap={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
              }}
            >
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Days of week')}</Typography>
                <Controller
                  name="weekDays"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth error={!!error}>
                      <Select
                        multiple
                        value={values.weekDays}
                        renderValue={(selected) => selected.map((value) => t(value)).join(', ')}
                        onChange={handleWeekDayChange}
                        autoWidth={false}
                      >
                        {weekDays.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            <Checkbox
                              disableRipple
                              size="small"
                              checked={values.weekDays!.includes(option.value)}
                            />
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Stack>
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Frequency')}</Typography>
                <Controller
                  name="frequency"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth error={!!error}>
                      <Select
                        value={values.frequency}
                        onChange={handleFrequencyChange}
                        autoWidth={false}
                      >
                        {frequencyTypes.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Stack>
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Class begins')}</Typography>
                <Controller
                  name="begins"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TimePicker
                      {...field}
                      value={values.begins}
                      ampm={false}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  )}
                />
              </Stack>
            </Box>
            <Box
              columnGap={2}
              rowGap={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
              }}
            >
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Class duration in hours')}</Typography>
                <RHFTextField name="classDurationHours" onChange={handleClassDurationHoursChange} />
              </Stack>
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Class duration in minutes')}</Typography>
                <RHFTextField
                  name="classDurationMinutes"
                  onChange={handleClassDurationMinutesChange}
                />
              </Stack>
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Shift duration in hours')}</Typography>
                <RHFTextField name="shiftDurationHours" />
              </Stack>
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Shift duration in minutes')}</Typography>
                <RHFTextField name="shiftDurationMinutes" />
              </Stack>
              {values.releaseProgram && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('Hours before program release')}</Typography>
                  <RHFTextField name="hoursBeforeProgramRelease" />
                </Stack>
              )}
              {values.releaseProgram && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('Minutes before program release')}</Typography>
                  <RHFTextField name="minutesBeforeProgramRelease" />
                </Stack>
              )}
              {values.changeOptOutTime && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('Earliest opt out time in hours')}</Typography>
                  <RHFTextField name="optOutTimeHours" />
                </Stack>
              )}
              {values.changeOptOutTime && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">
                    {t('Earliest opt out time in minutes')}
                  </Typography>
                  <RHFTextField name="optOutTimeMinutes" />
                </Stack>
              )}
            </Box>
          </Stack>
        </Card>
      </Grid>

      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Planning period')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t(
              'Here you decide what the planning period is. Like when should the planning start and when it ends.'
            )}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Planning period')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Box
              columnGap={2}
              rowGap={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
              }}
            >
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Planning starts')}</Typography>
                <Controller
                  name="validFrom"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      {...field}
                      value={field.value}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  )}
                />
              </Stack>
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Planning ends')}</Typography>
                <Controller
                  name="validTo"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      {...field}
                      value={field.value}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  )}
                />
              </Stack>
            </Box>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderInstructors = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Instructors')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t(
              'Here you select who the instructors are, and how the distribution of the classes are. When select Planning type Normal, then the instructors are the same for each class. When select Planning type Alternating, then the instructors are shifted around for each week. When select Planning type Specific days, then the instructors are distributed based on what day they are selected for.'
            )}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Instructors" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Planning type')}</Typography>
              <Controller
                name="type"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <Select value={values.type} onChange={handleTypeChange} autoWidth={false}>
                      {planningTypes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Stack>
            {values.type === ClassTemplateTypes.Normal && (
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Coaches')}</Typography>
                <Controller
                  name="normal"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth error={!!error}>
                      <Select
                        multiple
                        value={values.normal?.coaches ?? []}
                        onChange={handleInstructorChange}
                        autoWidth={false}
                      >
                        {instructors.map((option) => (
                          <MenuItem key={`in-${option.id}`} value={option.id!}>
                            <Checkbox
                              disableRipple
                              size="small"
                              checked={values.normal!.coaches!.includes(option.id ?? -1)}
                            />
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Stack>
            )}
            {values.type === ClassTemplateTypes.Alternating && (
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Number of weeks')}</Typography>
                <TextField value={numberOfWeeks} onChange={handleNumberOfWeekChange} />
              </Stack>
            )}
            {values.type === ClassTemplateTypes.Alternating && (
              <Box
                columnGap={2}
                rowGap={3}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)',
                }}
              >
                {values.alternating?.weeks?.map((week) => (
                  <Stack key={`alt-${week.weekNumber}`} spacing={1.5}>
                    <Typography variant="subtitle2">
                      {t('Week {{weekNumber}}', { weekNumber: week.weekNumber ?? 1 })}
                    </Typography>
                    <Select
                      value={week.coachId}
                      onChange={(e) => handleAlternatingInstructorChange(e, week)}
                    >
                      {instructors.map((option) => (
                        <MenuItem key={option.id} value={option.id!}>
                          <Checkbox
                            disableRipple
                            size="small"
                            checked={week.coachId === option.id}
                          />
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                ))}
              </Box>
            )}

            {values.type === ClassTemplateTypes.SpecificDays && (
              <Box
                columnGap={2}
                rowGap={3}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)',
                }}
              >
                {values.specificDays?.weekDays?.map((day) => (
                  <Stack key={`spe-${day.dayOfWeek}`} spacing={1.5}>
                    <Typography variant="subtitle2">{t(`${day.dayOfWeek}`)}</Typography>
                    <Select
                      value={day.coaches}
                      multiple
                      onChange={(e) => handleSpecificDayInstructorChange(e, day)}
                    >
                      {instructors.map((option) => (
                        <MenuItem key={option.id} value={option.id!}>
                          <Checkbox
                            disableRipple
                            size="small"
                            checked={day.coaches!.includes(option.id!)}
                          />
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                ))}
              </Box>
            )}
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderParticipants = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Participants')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t(
              'Here you select which members that should be automatically signed up for this class.'
            )}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Participants" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Members')}</Typography>
              <Controller
                name="autoSignUpParticipantIds"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <Select
                      multiple
                      value={values.autoSignUpParticipantIds ?? []}
                      onChange={handleAutoSignUpParticipantIdsChange}
                      autoWidth={false}
                    >
                      {members.map((option) => (
                        <MenuItem key={`mem-in-${option.id}`} value={option.id!}>
                          <Checkbox
                            disableRipple
                            size="small"
                            checked={values.autoSignUpParticipantIds!.includes(option.id ?? -1)}
                          />
                          {option.fullName}
                        </MenuItem>
                      ))}
                    </Select>
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, pl: 3 }} />

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          sx={{ ml: 2 }}
        >
          {!currentTemplate ? t('Create') : t('Save Changes')}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderAlert}

        {renderDetails}

        {renderProperties}

        {renderInstructors}

        {renderParticipants}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
