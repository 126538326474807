import { useState } from 'react';
// @mui
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { Stack } from '@mui/material';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
// types
// components
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover from 'src/components/custom-popover';
import { EmployeeDto } from '../../api';
import { useLocales } from '../../locales';
import { paths } from '../../routes/paths';
import { useRouter } from '../../routes/hook';

// ----------------------------------------------------------------------

type Props = {
  row: EmployeeDto;
  onSelectRow: VoidFunction;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function EmployeeTableRow({ row, onEditRow, onSelectRow, onDeleteRow }: Props) {
  const { id, name, phoneNumber, roles, profileImageUrl, email } = row;

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const { t } = useLocales();

  const router = useRouter();

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover>
        <TableCell onClick={() => router.push(paths.employee.details(id!))}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={name} src={profileImageUrl} />
            <ListItemText
              primary={name}
              secondary={email}
              primaryTypographyProps={{ typography: 'body2' }}
              secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
            />
          </Stack>
        </TableCell>

        <TableCell align="left">{phoneNumber}</TableCell>
        <TableCell align="left">
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {roles?.map((role) => <Chip key={`${id}-${role}`} size="small" label={t(role)} />)}
          </Box>
        </TableCell>

        <TableCell align="right">
          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            onSelectRow();
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:eye-fill" />
          {t('View')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:edit-fill" />

          {t('Edit')}
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={t('Delete')}
        content={t('Are you sure want to delete?')}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleCloseConfirm();
              onDeleteRow();
            }}
          >
            {t('Delete')}
          </Button>
        }
      />
    </>
  );
}
