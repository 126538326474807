import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import { SubscriptionType } from '../../api';
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // TRAINING
      // ----------------------------------------------------------------------
      {
        subheader: t('Training'),
        items: [
          {
            title: t('Overview'),
            path: paths.general.training,
            icon: <Iconify icon="carbon:dashboard" />,
            roles: ['Owner', 'Box_manager'],
          },
          {
            title: t('Classes'),
            path: paths.classes.root,
            icon: <Iconify icon="guidance:group-training" />,
            children: [
              { title: t('Scheduled classes'), path: paths.classes.root },
              {
                title: t('Planning'),
                path: paths.classTemplates.root,
                roles: ['Owner', 'Box_manager'],
              },
              {
                title: t('Class types'),
                path: paths.classDescriptions.root,
                roles: ['Owner', 'Box_manager'],
              },
              {
                title: t('Feedback'),
                path: paths.classFeedback.root,
                roles: ['Owner', 'Box_manager'],
              },
              {
                title: t('Location'),
                path: paths.locations.root,
                roles: ['Owner', 'Box_manager'],
              },
              {
                title: t('Class registrations'),
                path: paths.classRegistration.root,
              },
            ],
          },
          {
            title: t('Programming'),
            path: paths.programs.root,
            icon: <Iconify icon="streamline:class-lesson" />,
            children: [
              { title: t('Calendar'), path: paths.programs.root },

              {
                title: t('Templates'),
                path: paths.programTemplates.root,
                roles: ['Owner', 'Box_manager'],
              },
              {
                title: t('Personal training'),
                path: paths.personalPrograms.root,
                subscriptionPlan: SubscriptionType.Premium,
                roles: ['Owner', 'Box_manager', 'Personal_trainer'],
              },
            ],
          },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t('Management'),
        items: [
          {
            title: t('Overview'),
            path: paths.general.management,
            icon: <Iconify icon="iconoir:stats-report" />,
            roles: ['Owner', 'Box_manager'],
          },
          {
            title: t('Members'),
            path: paths.members.root,
            icon: <Iconify icon="solar:user-id-linear" />,
            children: [
              {
                title: t('Memberships'),
                path: paths.memberships.root,
                roles: ['Owner', 'Box_manager', 'Floor_manager'],
              },
              {
                title: t('Member List'),
                path: paths.members.root,
              },
              {
                title: t('Leads'),
                path: paths.leads.root,
                roles: ['Owner', 'Box_manager'],
                subscriptionPlan: SubscriptionType.Premium,
              },
              {
                title: t('Retention'),
                path: paths.retention.root,
                roles: ['Owner', 'Box_manager'],
                subscriptionPlan: SubscriptionType.Premium,
              },
            ],
          },
          {
            title: t('Employees'),
            path: paths.employee.root,
            icon: <Iconify icon="clarity:employee-group-line" />,
            children: [
              {
                title: t('Employee List'),
                path: paths.employee.root,
                roles: ['Owner', 'Box_manager'],
              },
              { title: t('Duty Schedule'), path: paths.dutySchedule.root },
              {
                title: t('Wage Rates'),
                path: paths.wageRates.root,
                roles: ['Owner', 'Box_manager'],
                subscriptionPlan: SubscriptionType.Premium,
              },
              {
                title: t('Salary Settlement'),
                path: paths.salarySettlement.root,
                roles: ['Owner', 'Box_manager'],
                subscriptionPlan: SubscriptionType.Premium,
              },
              {
                title: t('Hour Bank'),
                path: paths.hourBank.root,
                roles: ['Owner', 'Box_manager'],
                subscriptionPlan: SubscriptionType.Premium,
              },
            ],
          },
          {
            title: t('Gyms'),
            path: paths.gyms.root,
            icon: <Iconify icon="ph:warehouse" />,
            roles: ['Owner', 'Box_manager'],
          },
          {
            title: t('Contracts'),
            path: paths.contracts.root,
            icon: <Iconify icon="clarity:contract-line" />,
            roles: ['Owner', 'Box_manager'],
          },
          {
            title: t('Reports'),
            path: paths.report.root,
            icon: <Iconify icon="carbon:report" />,
            roles: ['Owner', 'Box_manager'],
          },
          {
            title: t('Insights'),
            path: paths.insight.memberships,
            icon: <Iconify icon="carbon:chart-multitype" />,
            roles: ['Owner'],
          },
        ],
      },

      // SALE
      // ----------------------------------------------------------------------
      {
        subheader: t('Sale'),
        roles: ['Owner', 'Box_manager', 'Floor_manager'],
        items: [
          {
            title: t('Vouchers'),
            icon: <Iconify icon="mdi:voucher-outline" />,
            path: paths.vouchers.root,

            roles: ['Owner', 'Box_manager'],
          },
          {
            title: t('Products'),
            path: paths.product.root,
            icon: <Iconify icon="fluent-mdl2:product-list" />,
            roles: ['Owner', 'Box_manager'],
            subscriptionPlan: SubscriptionType.Premium,
          },
          {
            title: t('Quick links'),
            path: paths.quickLinks.root,
            icon: <Iconify icon="mdi:vector-link" />,
            roles: ['Owner', 'Box_manager'],
            subscriptionPlan: SubscriptionType.Premium,
          },
          {
            title: t('Events'),
            path: paths.events.root,
            icon: <Iconify icon="icon-park-outline:journey" />,
            roles: ['Owner', 'Box_manager'],
          },
          {
            title: t('Memberships'),
            path: '',
            icon: <Iconify icon="tdesign:cardmembership" />,
            roles: ['Owner', 'Box_manager', 'Floor_manager'],
            children: [
              {
                title: t('Contingents'),
                path: paths.contingentType.root,
                roles: ['Owner', 'Box_manager', 'Floor_manager'],
              },
              {
                title: t('Punch cards'),
                path: paths.punchCardType.root,
                roles: ['Owner', 'Box_manager', 'Floor_manager'],
              },
              {
                title: t('Campaigns'),
                path: paths.campaignType.root,
                roles: ['Owner', 'Box_manager', 'Floor_manager'],
              },
              {
                title: t('Personal training'),
                path: paths.personalTrainingMembershipType.root,
                roles: ['Owner', 'Box_manager', 'Floor_manager'],
                subscriptionPlan: SubscriptionType.Premium,
              },
              {
                title: t('Drop In'),
                path: paths.dropInType.root,
                roles: ['Owner', 'Box_manager', 'Floor_manager'],
              },
            ],
          },
          {
            title: t('Insights'),
            path: paths.insight.sales,
            icon: <Iconify icon="carbon:chart-multitype" />,
            roles: ['Owner'],
          },
        ],
      },

      // ECONOMICS
      // ----------------------------------------------------------------------
      {
        subheader: t('Economics'),
        roles: ['Owner', 'Box_manager', 'Bookkeeper', 'Personal_trainer', 'Receptionist'],
        items: [
          {
            title: t('Invoices'),
            path: paths.invoice.list,
            icon: <Iconify icon="iconamoon:invoice" />,
            roles: ['Owner', 'Box_manager', 'Bookkeeper', 'Personal_trainer', 'Receptionist'],
          },
          {
            title: t('Accounting'),
            icon: <Iconify icon="map:accounting" />,
            path: paths.bookkeeping.root,
            roles: ['Owner', 'Bookkeeper'],
            subscriptionPlan: SubscriptionType.Premium,
          },
        ],
      },

      // COMMUNICATION
      // ----------------------------------------------------------------------
      {
        subheader: t('Communication'),
        roles: ['Owner', 'Box_manager', 'Support'],
        items: [
          {
            title: t('Chat'),
            icon: <Iconify icon="material-symbols:chat" />,
            path: paths.chat.root,
            roles: ['Owner', 'Support'],
            subscriptionPlan: SubscriptionType.Platinum,
          },
          {
            title: t('News'),
            icon: <Iconify icon="material-symbols:newspaper" />,
            path: paths.news.root,
            roles: ['Owner', 'Box_manager'],
          },
          {
            title: t('Newsletters'),
            icon: <Iconify icon="mdi:email-newsletter" />,
            path: paths.newsletter.root,
            roles: ['Owner', 'Box_manager'],
          },
          {
            title: t('Notifications'),
            icon: <Iconify icon="material-symbols:notifications-active-outline" />,
            path: paths.notification.root,
            roles: ['Owner', 'Box_manager'],
          },
          {
            title: t('Email templates'),
            icon: <Iconify icon="fluent:mail-template-16-regular" />,
            path: paths.emailTemplates.root,
            roles: ['Owner', 'Box_manager'],
          },
        ],
      },

      // SYSTEM
      {
        subheader: t('System'),
        items: [
          {
            title: t('FAQ'),
            icon: <Iconify icon="mdi:faq" />,
            path: paths.faqs,
          },
          {
            title: t('Settings'),
            icon: <Iconify icon="fluent:settings-20-regular" />,
            path: paths.chains.edit(1),
            roles: ['Owner'],
          },
        ],
      },
    ],
    [t],
  );

  return data;
}
