import React, { useCallback, useState } from 'react';
import { DateTime } from 'luxon';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { useLocales } from '../../../../locales';
import { useSettingsContext } from '../../../../components/settings';
import { paths } from '../../../../routes/paths';
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
import CustomPopover, { usePopover } from '../../../../components/custom-popover';
import { IReportTableFilters } from '../../../../types/report';
import Iconify from '../../../../components/iconify';
import MemberLifetimeValueView from '../member-lifetime-value-view';
import MemberMonthlyRecurringRevenueView from '../member-monthly-recurring-revenue-view';
import MemberMonthlyAverageRevenueView from '../member-monthly-average-revenue-view';
import RevenueContingentView from '../revenue-contingent-view';
import RevenueCampaignView from '../revenue-campaign-view';
import RevenuePunchCardsView from '../revenue-punch-cards-view';
import RevenueDropInView from '../revenue-drop-in-view';
import RevenuePersonalTrainingView from '../revenue-personal-training-view';
import ProductMonthlyRevenueView from '../product-monthly-revenue-view';
import RevenueFinesView from '../revenue-fines-view';
import { IInsightTableFilters } from '../../../../types/insight';
import ProductMonthlyRevenuePerCategoryView from '../product-monthly-revenue-per-category-view';
import MonthlyRecurringRevenueView from '../monthly-recurring-revenue-view';
import MemberMonthlyRevenueView from '../member-monthly-revenue-view';

const defaultFilters: IInsightTableFilters = {
  fromDate: DateTime.now().startOf('year'),
  toDate: DateTime.now().endOf('year'),
};

export default function SaleInsightsView() {
  const { t } = useLocales();

  const settings = useSettingsContext();

  const popover = usePopover();

  const [filters, setFilters] = useState(defaultFilters);

  const [startYear] = useState(DateTime.now().year - 5);

  const [selectableYears] = useState([1, 2, 3, 4, 5, 6].map((i) => startYear + i));

  const handleYearChange = useCallback(
    (newValue: number) => {
      popover.onClose();
      const now = DateTime.now().set({
        year: newValue,
      });
      setFilters({
        fromDate: now.startOf('year'),
        toDate: now.endOf('year'),
      });
    },
    [popover],
  );

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading={t('Insights')}
          links={[
            { name: t('Dashboard'), href: paths.dashboard.root },
            {
              name: t('Insights'),
            },
            { name: t('Sales') },
          ]}
          action={
            <ButtonBase
              onClick={popover.onOpen}
              sx={{
                pl: 1,
                py: 0.5,
                pr: 0.5,
                borderRadius: 1,
                typography: 'subtitle2',
                bgcolor: 'background.neutral',
              }}
            >
              {filters.fromDate?.year}

              <Iconify
                width={16}
                icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                sx={{ ml: 0.5 }}
              />
            </ButtonBase>
          }
          sx={{ mb: 3 }}
        />
        <Typography variant="body2" sx={{ mb: 3 }}>
          {t("Here you can get insights about your sales. You can see how much revenue you've made, how much revenue you've made from members, how much revenue you've made from products, and more. All amounts are in your default currency and excluding VAT.")}
        </Typography>
        <Grid container spacing={3}>
          <Grid xs={12} xl={6}>
            <MemberMonthlyRevenueView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
          <Grid xs={12} xl={6}>
            <MonthlyRecurringRevenueView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
          <Grid xs={12} xl={6}>
            <MemberMonthlyRecurringRevenueView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
          <Grid xs={12} xl={6}>
            <MemberLifetimeValueView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
          <Grid xs={12} xl={6}>
            <MemberMonthlyAverageRevenueView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{
          mt: 2,
        }}>
          <Grid xs={12} md={12} lg={6} xl={4}>
            <RevenueContingentView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
          <Grid xs={12} md={12} lg={6} xl={4}>
            <RevenueCampaignView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
          <Grid xs={12} md={12} lg={6} xl={4}>
            <RevenuePunchCardsView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
          <Grid xs={12} md={12} lg={6} xl={4}>
            <RevenueDropInView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
          <Grid xs={12} md={12} lg={6} xl={4}>
            <RevenuePersonalTrainingView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
          <Grid xs={12} md={12} lg={6} xl={4}>
            <RevenueFinesView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{
          mt: 2,
        }}>
          <Grid xs={12} xl={6}>
            <ProductMonthlyRevenueView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
          <Grid xs={12} xl={6}>
            <ProductMonthlyRevenuePerCategoryView fromDate={filters.fromDate} toDate={filters.toDate} />
          </Grid>
        </Grid>
      </Container>
      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 140 }}>
        {selectableYears.map((year) => (
          <MenuItem
            key={year}
            selected={year === filters.fromDate?.year}
            onClick={() => handleYearChange(year)}
          >
            {year}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}