import { memo, useCallback, useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useEventListener } from 'src/hooks/use-event-listener';
// components
import { DateTime } from 'luxon';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useRouter } from 'src/routes/hook';
import SearchNotFound from 'src/components/search-not-found';
//
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { SearchResultDto, SearchResultType } from '../../../api';
import { paths } from '../../../routes/paths';
import { search } from '../../../redux/slices/search';
import { fDate, fDateTime, fTime } from '../../../utils/format-time';

// ----------------------------------------------------------------------

function Searchbar() {
  const theme = useTheme();

  const { t } = useLocales();

  const router = useRouter();

  const searching = useBoolean();

  const mdUp = useResponsive('up', 'md');

  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState('');

  const dataFiltered = useSelector((state) => state.search.entries);

  useEffect(() => {
    dispatch(
      search({
        searchString: searchQuery,
      }),
    );
  }, [dispatch, searchQuery]);

  const handleClose = useCallback(() => {
    searching.onFalse();
    setSearchQuery('');
  }, [searching]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'k' && event.metaKey) {
      searching.onToggle();
      setSearchQuery('');
    }
  };

  useEventListener('keydown', handleKeyDown);

  const handleSearch = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSearchQuery(event.target.value);
  }, []);

  const notFound = searchQuery && !dataFiltered.length;

  const renderInvoiceItem = (item: SearchResultDto, index: number) => (
    <List key={index} onClick={() => {
      router.push(paths.invoice.details(parseInt(item.id!, 10)));
      handleClose();
    }}>
      <Stack direction="row">
        <Avatar sx={{ mr: 2 }}>
          <Iconify icon="iconamoon:invoice" />
        </Avatar>
        <ListItemText
          primary={item.name}
          secondary={item.meta.customerName}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
        />
      </Stack>
    </List>
  );

  const renderClazzItem = (item: SearchResultDto, index: number) => (
    <List key={index} onClick={() => {
      router.push(paths.classes.view(parseInt(item.id!, 10)));
      handleClose();
    }}>
      <Stack direction="row">
        <Avatar sx={{ mr: 2 }}>
          <Iconify icon="guidance:group-training" />
        </Avatar>
        <ListItemText
          primary={item.name}
          secondary={`${fDate(DateTime.fromISO(item.meta.begins))}, ${fTime(DateTime.fromISO(item.meta.begins))} - ${fTime(DateTime.fromISO(item.meta.ends))}`}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
        />
      </Stack>
    </List>
  );

  const renderMemberItem = (item: SearchResultDto, index: number) => (
    <List key={index} onClick={() => {
      router.push(paths.members.view(parseInt(item.id!, 10)));
      handleClose();
    }}>
      <Stack direction="row">
        <Avatar alt={item.meta.fullName} src={item.meta.profileImageUrl} sx={{ mr: 2 }} />

        <ListItemText
          primary={item.meta.fullName}
          secondary={item.meta.emailAddress}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
        />
      </Stack>
    </List>
  );

  const renderItem = (item: SearchResultDto, index: number) => {
    if (item.type === SearchResultType.Invoice) {
      return renderInvoiceItem(item, index);
    }

    if (item.type === SearchResultType.Clazz) {
      return renderClazzItem(item, index);
    }
    return renderMemberItem(item, index);
  };

  const renderItems = () => dataFiltered.map((item, index) => (renderItem(item, index)));

  const renderButton = (
    <Stack direction="row" alignItems="center">
      <IconButton onClick={searching.onTrue}>
        <Iconify icon="eva:search-fill" />
      </IconButton>

      {mdUp && <Label sx={{ px: 0.75, fontSize: 12, color: 'text.secondary', ml: 0.5 }}>⌘K</Label>}
    </Stack>
  );

  return (
    <>
      {renderButton}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={searching.value}
        onClose={handleClose}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: 0,
        }}
        PaperProps={{
          sx: {
            mt: 15,
            overflow: 'unset',
          },
        }}
        sx={{
          [`& .${dialogClasses.container}`]: {
            alignItems: 'flex-start',
          },
        }}
      >
        <Box sx={{ p: 3, borderBottom: `solid 1px ${theme.palette.divider}` }}>
          <InputBase
            fullWidth
            autoFocus
            placeholder={t('Search...')}
            value={searchQuery}
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" width={24} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
            endAdornment={<Label sx={{ letterSpacing: 1, color: 'text.secondary' }}>esc</Label>}
            inputProps={{
              sx: { typography: 'h6' },
            }}
          />
        </Box>

        <Scrollbar sx={{ p: 3, pt: 2, height: 400 }}>
          {notFound ? <SearchNotFound query={searchQuery} sx={{ py: 10 }} /> : renderItems()}
        </Scrollbar>
      </Dialog>
    </>
  );
}

export default memo(Searchbar);
