import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';
import Alert from '@mui/material/Alert';
import InputLabel from '@mui/material/InputLabel';
// components
import { DateTime } from 'luxon';
import FormProvider, { RHFSwitch, RHFTextField } from 'src/components/hook-form';
import { useLocales } from '../../../locales';
import { useSelector } from '../../../redux/store';
import { fCurrencySymbol } from '../../../utils/format-number';
import {
  AccountDto,
  CampaignMembershipTypeDto,
  CampaignService,
  ContingentService,
  ContingentTypeDto,
  DropInsService,
  DropInTypeDto,
  PersonalTrainingService,
  PersonalTrainingTypeDto,
  VouchersService,
  VoucherTypeDto,
} from '../../../api';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onCreate: VoidFunction;
  member: AccountDto;
  campaigns: CampaignMembershipTypeDto[];
  dropIns: DropInTypeDto[];
  contingents: ContingentTypeDto[];
  punchCards: VoucherTypeDto[];
  personalTrainings: PersonalTrainingTypeDto[];
};

export default function MembershipNewForm({
  open,
  onClose,
  onCreate,
  member,
  campaigns,
  contingents,
  punchCards,
  personalTrainings,
  dropIns,
}: Props) {
  const { t } = useLocales();

  const settings = useSelector((state) => state.chain.currentSettings);

  const [errorMessage, setErrorMessage] = useState('');

  const newSchema = Yup.object().shape({
    membershipTypeId: Yup.number()
      .min(1, 'Membership is required')
      .required('Membership is required'),
    validFrom: Yup.date().required('Valid from is required'),
    withFee: Yup.boolean().required('With fee is required'),
    isOneTimeDiscount: Yup.boolean().required('One time discount is required'),
    sendWelcomeMail: Yup.boolean().required('Send welcome mail is required'),
    // not required
    hasDiscount: Yup.boolean(),
    discountAmount: Yup.mixed(),
    discountPercentage: Yup.mixed(),
    priceAmount: Yup.number().nullable(),
    taxRate: Yup.number().nullable(),
    hasAutomaticRenewal: Yup.boolean(),
    useFirstOfMonthAsAnchor: Yup.boolean(),
  });

  const defaultValues = useMemo(
    () =>
      ({
        membershipTypeId: -1,
        validFrom: DateTime.now(),
        withFee: true,
        isOneTimeDiscount: false,
        sendWelcomeMail: true,
        discountAmount: '',
        discountPercentage: '',
        hasDiscount: false,
        discountCurrency: settings?.defaultCurrency || 'EUR',
        memberId: member.id!,
        hasAutomaticRenewal: false,
        useFirstOfMonthAsAnchor: true,
        priceAmount: null,
        priceCurrency: settings?.defaultCurrency || 'EUR',
      }) as any,
    [settings, member]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting },
    control,
    reset,
    setValue,
  } = methods;

  const values = watch();

  const handleMembershipChange = (event: SelectChangeEvent<number>) => {
    const result = parseInt(`${event.target.value}`, 10);
    const membershipType = [...campaigns, ...contingents, ...punchCards, ...personalTrainings].find(
      (x) => x.id === result
    );

    if (membershipType) {
      setValue('priceAmount', membershipType.priceAmount, { shouldValidate: true });
      setValue('taxRate', membershipType.taxRate, { shouldValidate: true });
    }
    setValue('membershipTypeId', result, { shouldValidate: true });
  };

  const onSubmit = handleSubmit(async (data) => {
    setErrorMessage('');
    try {
      if (contingents.filter((x) => x.id === data.membershipTypeId).length > 0) {
        await ContingentService.create({
          body: {
            ...data,
          } as any,
        });
      }

      if (dropIns.filter((x) => x.id === data.membershipTypeId).length > 0) {
        await DropInsService.create({
          body: {
            ...data,
          } as any,
        });
      }

      if (campaigns.filter((x) => x.id === data.membershipTypeId).length > 0) {
        await CampaignService.create({
          body: {
            ...data,
          } as any,
        });
      }

      if (punchCards.filter((x) => x.id === data.membershipTypeId).length > 0) {
        await VouchersService.create({
          body: {
            ...data,
          } as any,
        });
      }

      if (personalTrainings.filter((x) => x.id === data.membershipTypeId).length > 0) {
        await PersonalTrainingService.create({
          body: {
            ...data,
          } as any,
        });
      }
      reset();
      onCreate();
      onClose();
    } catch (error) {
      setErrorMessage(error.response.data.detail);
    }
  });

  useEffect(() => {
    if (member) {
      reset(defaultValues);
    }
  }, [member, reset, defaultValues]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{t('New membership')}</DialogTitle>

        <DialogContent dividers>
          <Stack spacing={3}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              sx={{ paddingTop: '6px' }}
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <Controller
                name="membershipTypeId"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <InputLabel>{t('Type')}</InputLabel>
                    <Select
                      label={t('Type')}
                      value={values.membershipTypeId}
                      onChange={handleMembershipChange}
                      autoWidth={false}
                    >
                      {campaigns.map((option) => (
                        <MenuItem
                          key={`membership-type-${option.id}`}
                          value={option.id}
                          sx={{
                            justifyContent: 'space-between',
                          }}
                        >
                          {option.name}
                          <Chip
                            size="small"
                            variant="outlined"
                            label={t('Campaign')}
                            color="success"
                            sx={{ ml: 1 }}
                          />
                        </MenuItem>
                      ))}
                      {contingents.map((option) => (
                        <MenuItem
                          key={`membership-type-${option.id}`}
                          value={option.id}
                          sx={{
                            justifyContent: 'space-between',
                          }}
                        >
                          {option.name}
                          <Chip
                            size="small"
                            variant="outlined"
                            label={t('Contingent')}
                            color="info"
                            sx={{ ml: 1 }}
                          />
                        </MenuItem>
                      ))}
                      {personalTrainings.map((option) => (
                        <MenuItem
                          key={`membership-type-${option.id}`}
                          value={option.id}
                          sx={{
                            justifyContent: 'space-between',
                          }}
                        >
                          {option.name}
                          <Chip
                            size="small"
                            variant="outlined"
                            label={t('Personal training')}
                            color="secondary"
                            sx={{ ml: 1 }}
                          />
                        </MenuItem>
                      ))}
                      {punchCards.map((option) => (
                        <MenuItem
                          key={`membership-type-${option.id}`}
                          value={option.id}
                          sx={{
                            justifyContent: 'space-between',
                          }}
                        >
                          {option.name}
                          <Chip
                            size="small"
                            variant="outlined"
                            label={t('Punch card')}
                            color="warning"
                            sx={{ ml: 1 }}
                          />
                        </MenuItem>
                      ))}
                      {dropIns.map((option) => (
                        <MenuItem
                          key={`membership-type-${option.id}`}
                          value={option.id}
                          sx={{
                            justifyContent: 'space-between',
                          }}
                        >
                          {option.name}
                          <Chip
                            size="small"
                            variant="outlined"
                            label={t('Drop In')}
                            color="default"
                            sx={{ ml: 1 }}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />
              <Controller
                name="validFrom"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    value={field.value}
                    label={t('Valid from')}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
              <RHFTextField
                name="priceAmount"
                label={t('Price')}
                placeholder="0.00"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box component="span" sx={{ color: 'text.disabled' }}>
                        {fCurrencySymbol(defaultValues.priceCurrency)}
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFSwitch name="hasDiscount" label={t('With discount')} />

              {values.hasDiscount && (
                <RHFSwitch name="isOneTimeDiscount" label={t('One time discount')} />
              )}
              {values.hasDiscount && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('Discount amount')}</Typography>
                  <RHFTextField
                    name="discountAmount"
                    placeholder="0.00"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box component="span" sx={{ color: 'text.disabled' }}>
                            {fCurrencySymbol(defaultValues.discountCurrency)}
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              )}
              {values.hasDiscount && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('Discount percentage')}</Typography>
                  <RHFTextField name="discountPercentage" placeholder="0.00" type="number" />
                </Stack>
              )}

              {punchCards.filter((x) => x.id === values.membershipTypeId).length > 0 && (
                <RHFSwitch name="hasAutomaticRenewal" label={t('Has automatic renewal')} />
              )}

              <RHFSwitch name="useFirstOfMonthAsAnchor" label={t('Use first of month as anchor')} />
              <RHFSwitch name="sendWelcomeMail" label={t('Send welcome email')} />
              <RHFSwitch name="withFee" label={t('Use setup fee')} />
            </Box>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button color="inherit" variant="outlined" onClick={onClose}>
            {t('Cancel')}
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {t('Add')}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
